import { IconButton,ListSubheader, TableRow, TableCell, ListItemText, ListItem, TableBody, Table } from "@material-ui/core"
import * as React from "react"
import { useFlagUserModal } from '../../../components/FlagUserModal/FlagUserModalContext';
import { Field } from "formik"
import { getStyles,renderOrdersCell } from "./NotesDrawerContent.style";

import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import Modal from '@material-ui/core/Modal';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

}
const OrderFlags = (props: any) => {
    const classes = getStyles();
   const { open: openModal } = useFlagUserModal();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p>
      
    </div>
  );
    if(props.orderFlags.length === 0){ 
        return (
            <>
            </>
        
            );
      }

   
      const filterItemAndCallModal = () => {
        let userid = props.orders.approvaluser.filter( function (user:any) {
          return (user.orderId === props.orders.orderId)
        });
        //debugger;
        openModal({
          userid: userid[0].userId,
          requestid:props.orders.requestId,
          orderid:props.orders.orderId,
          ndc:''
        });
      }
     
    return (
        
        <>
        
            <ListSubheader><b>Order Based Flags</b> : {!props.cleared ? props.orderFlags.length : 0}</ListSubheader>

            {props.orderFlags.map((reason: any, index: number) => (
                <ListItem key={reason.algorithm} className={renderOrdersCell(props)}>
                    
                    <ListItemText secondary={reason.algorithm+` (Average Value : ${Number(reason.total).toFixed(2)}  |   Threshold : ${reason.threshold ?? '--'})`} />
					
                </ListItem>
            ))} 
             {(props.flaggedorder.length > 0 && !props.cleared) && 
            <><br/><b className={classes.markassusordercls}>(Marked as Suspicious)</b></>
            }
            {((!props.cleared && props.orders.suspiciousorder  === 'false') || (props.orders.suspiciousorder  === 'true' && props.flaggedorder.length <= 0) ) && 
            <Table>
            <TableBody>
            <TableRow key={`orderItemcomment`} >
            <TableCell colSpan={2} className={classes.orderflagclsnew}>
                <label>
                    <Field type="checkbox" name="checkflagorder" /> 
                   {(props.orders.approvalcountOrders[0].total!='0') && <>
             <span className="post__content" dangerouslySetInnerHTML={{__html: (props.orders.suspiciousorder  === 'true') ? `Mark As Suspicious<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(<b>${props.orders.approvalcountOrders[0].total}</b> Approval(s) Required)` : `Clear Flag <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(<b>${props.orders.approvalcountOrders[0].total}</b> Approval(s) Required)`}}></span>
             </>
            } 
            {(props.orders.approvalcountOrders[0].total == '1') && 
<>
<IconButton onClick={() => filterItemAndCallModal()}>
                          <EmailIcon />
                        </IconButton>
            </>
  
            }
            {(props.orders.approvalcountOrders[0].total == '0') && <>
             <span className="post__content" dangerouslySetInnerHTML={{__html: (props.orders.suspiciousorder  === 'true') ? `Mark As Suspicious` : `Clear Flag`}}></span>
             </>
            }
                </label>
                </TableCell>
                <TableCell colSpan={4}> 
				
                <Field type="text" name="messageorder" placeholder={(props.orders.suspiciousorder  === 'true') ? 'Enter Reason' : 'Clear Flag Comment'} className="commentboxcls"/>
                <div className={classes.errorcls}>
                {props.errors.messageorder && (props.errors.messageorder  !== "") ? props.errors.messageorder : null}
                </div>
                </TableCell>
               

                
            </TableRow>
            </TableBody>
            </Table>
            }
   
            {props.cleared && 
            <b className={classes.flagcls}>(Flag Cleared)</b>
            }
           
        </>
          
    )
}

export default OrderFlags;