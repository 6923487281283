import { DateTime } from 'luxon';
import * as React from 'react';
import { Line, Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { colors } from '../../../colors';
import PanelV2 from '../../../components/Panel/PanelV2';
import { useState, useEffect } from 'react';
import {
  IDosageFormAnalytics,
  IDrugFormAnalytics,
  IPackageAnalytics,
  useGetNdcAnalyticsQuery,
  IDashboardSetting,
  useGetDashboardSettingsQuery
} from '../../../generated/graphql';
import NDCTable from './NDCTable';

function NDCs() {

 const response1 = useGetDashboardSettingsQuery({ fetchPolicy: 'no-cache' });
  
  //console.log(response1.data);
  
  const settingval = response1.data?.dashboardSettings?.map((ds: IDashboardSetting) => ds.defaultfiltersettings!) ?? ["ALL"];
  const filtersetting = settingval[0].trim();
  const [selectedOption, setSelectedOption] = useState(filtersetting);
    
  useEffect(function persistForm() {
    if( filtersetting !== undefined ){
        setSelectedOption(localStorage.getItem('filterInLocalStorage') || filtersetting);
      }
  },[filtersetting]);
  
  const query = useGetNdcAnalyticsQuery();
  const { loading, error, data } = query;
 // const { loading, error, data } = useGetNdcAnalyticsQuery({ fetchPolicy: 'no-cache' });
 
const filtersettings = [
      {
        "key": "7",
        "value": "Last 7 Days"
      },
      {
        "key": "30",
        "value": "Last 30 Days"
      },
      {
        "key": "90",
        "value": "Last 90 Days"
      },
      {
        "key": "183",
        "value": "Last 6 Months"
      },
      {
        "key": "365",
        "value": "Last Year"
      }
  ];
   if( filtersetting !== "ALL" ){
     const optionVal = localStorage.getItem('filterInLocalStorage') || selectedOption;
    algorithmByDate(optionVal);
   
  }
  const onChange = async(event: React.ChangeEvent<HTMLSelectElement>) => {
     setSelectedOption(event.target.value);
      localStorage.setItem('filterInLocalStorage', event.target.value);
     try {
      algorithmByDate(event.target.value);
    } catch (err) {
      console.error(err);
    }
  };
   function algorithmByDate(val: any){
    if( val !== "ALL" ){
        var d = new Date();
        var endDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);
        
        d.setDate(d.getDate()-val);

        var startDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);

        //console.log(startDate);
        //console.log(endDate);

        query.refetch({
              start: startDate,
              end: endDate,
        });
    }else{
  
        query.refetch({
              start: undefined,
              end: undefined,
        });
    }
  }
  return (
    <Layout className={'relativeclass'}>
      <div className={'filterbox'}>
        <label>Filter</label>
        <select onChange={onChange} value={selectedOption} >
          <option value="ALL">ALL</option>
          {filtersettings.map((e, key) => {
            return <option key={key} value={e.key}>{e.value}</option>;
          })}
        </select>
      </div>
      <PanelV2
        style={{
          gridArea: 'orders',
        }}
        title="Orders"
        loading={loading}
        error={error}
      >
        <Line
          data={{
            datasets: [
              {
                label: 'Count',
                type: 'line',
                data: data?.monthlyOrderAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.count,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Total',
                type: 'line',
                data: data?.monthlyOrderAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.totalOrderTotal,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Average Total',
                type: 'line',
                data: data?.monthlyOrderAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.averageOrderTotal,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Quantity',
                type: 'line',
                data: data?.monthlyOrderAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.totalOrderQuantity,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Average Quantity',
                type: 'line',
                data: data?.monthlyOrderAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.averageOrderQuantity,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'ME',
                type: 'line',
                data: data?.monthlyOrderAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.totalOrderMe,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Average ME',
                type: 'line',
                data: data?.monthlyOrderAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.averageOrderMe,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
            ],
          }}
          options={{
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'month',
                  },
                },
              ],
            },
          }}
        />
      </PanelV2>

      <PanelV2
        style={{
          gridArea: 'ndcs',
        }}
        title="NDCs Break Down"
        loading={loading}
        error={error}
      >
        <NDCTable data={data?.ndcAnalytics ?? []} />
      </PanelV2>

      <PanelV2
        style={{
          gridArea: 'dosage',
        }}
        title="Dosage Form"
        loading={loading}
        error={error}
      >
        <Pie
          data={{
            labels: data?.dosageFormAnalytics?.map((dfa: IDosageFormAnalytics) => dfa.dosageForm!) ?? [],
            datasets: [
              {
                data: data?.dosageFormAnalytics?.map((dfa: IDosageFormAnalytics) => dfa.count!) ?? [],
                backgroundColor: colors,
              },
            ],
          }}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </PanelV2>

      <PanelV2
        style={{
          gridArea: 'package',
        }}
        title="Package"
        loading={loading}
        error={error}
      >
        <Pie
          data={{
            labels: data?.packageAnalytics?.map((pa: IPackageAnalytics) => pa.packageDescription!) ?? [],
            datasets: [
              {
                data: data?.packageAnalytics?.map((pa: IPackageAnalytics) => pa.count!) ?? [],
                backgroundColor: colors,
              },
            ]
          }}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </PanelV2>

      <PanelV2
        style={{
          gridArea: 'size',
        }}
        title="Size"
        loading={loading}
        error={error}
      >
        <Pie
          data={{
            labels: data?.drugFormAnalytics?.map((dfa: IDrugFormAnalytics) => dfa.dosageForm!) ?? [],
            datasets: [
              {
                data: data?.drugFormAnalytics?.map((dfa: IDrugFormAnalytics) => dfa.count!) ?? [],
                backgroundColor: colors,
              },
            ],
          }}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </PanelV2>
    </Layout>
  );
}

const Layout = styled('section')`
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
  grid-template-areas: 
    'analytics analytics analytics'
    'orders orders orders'
    'ndcs ndcs ndcs'
    'package dosage size';
`;

export default NDCs;


