import * as React from 'react';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { colors } from '../../../colors';
import PanelV2 from '../../../components/Panel/PanelV2';
import { useState, useEffect } from 'react';

import {
  IControlledFlaggedAnalytic,
  IDrugFamilyAnalytics,
  IControlledNdcFlaggedAnalytic,
  IDrugFamilyNdcAnalytics,
  IDashboardSetting,
  IWhatsNewData,
  IGetbuildversion,
  useGetOverviewAnalyticsQuery,
  useGetDashboardSettingsQuery,
  useGetTenantConfigurationsQuery,
  ITenantConfiguration,
} from '../../../generated/graphql';
import AlgorithmTable from './AlgorithmTable';
import { useWhatsNewModal } from '../../../components/WhatsNewModal/WhatsNewModalContext';  
function Overview() {
  const response1 = useGetDashboardSettingsQuery();
  
  const tenConfigs = useGetTenantConfigurationsQuery();
  const configData = tenConfigs.data?.tenantConfigurations?.map((ds: ITenantConfiguration) => ds!) ?? [];
  
  const settingval = response1.data?.dashboardSettings?.map((ds: IDashboardSetting) => ds.defaultfiltersettings!) ?? ["ALL"];
  const filtersetting = settingval[0].trim();
  const [selectedOption, setSelectedOption] = useState(filtersetting);
 
  useEffect(function persistForm() {
    if( filtersetting !== undefined ){
      localStorage.setItem('filterInLocalStorage', filtersetting);
        setSelectedOption(localStorage.getItem('filterInLocalStorage') || filtersetting);
    }
  },[filtersetting]);
  
  
  
  const query = useGetOverviewAnalyticsQuery();
  const { loading, error, data } = query;
   const { open: openModal } = useWhatsNewModal();
  const whatsNewData = data?.whatsNewData?.map((ds: IWhatsNewData) => ds!) ?? [];
  const buildVersion = data?.getbuildversion?.map((ds: IGetbuildversion) => ds!) ?? [];
  console.log(buildVersion)
  if( filtersetting !== undefined ){
   const optionVal = localStorage.getItem('filterInLocalStorage') || selectedOption;
   
    algorithmByDate(optionVal);
    if(whatsNewData.length > 0){
        openModal();
    }
    
  }
  if(buildVersion.length > 0 ){
     const buildver:any = (localStorage.getItem('buildversionupdate') || '');
     const buildvertenant:any = (localStorage.getItem('buildversiontenant') || '');
     const builddb:any = buildVersion[0].buildversion;
     const buildtenant:any = buildVersion[0].tenantId;
      if(buildver.trim().toString() === builddb.trim().toString() && buildvertenant.trim().toString() === buildtenant.trim().toString()){
            
        }else{
            localStorage.setItem('buildversionupdate', builddb.trim().toString());
            localStorage.setItem('buildversiontenant', buildtenant.trim().toString());
            window.location.reload(true);
        }
        
  }
  const filtersettings = [
      {
        "key": "7",
        "value": "Last 7 Days"
      },
      {
        "key": "30",
        "value": "Last 30 Days"
      },
      {
        "key": "90",
        "value": "Last 90 Days"
      },
      {
        "key": "183",
        "value": "Last 6 Months"
      },
      {
        "key": "365",
        "value": "Last Year"
      }
  ];
  
  const onChange = async(event: React.ChangeEvent<HTMLSelectElement>) => {
     setSelectedOption(event.target.value);
      localStorage.setItem('filterInLocalStorage', event.target.value);
     try {
      algorithmByDate(event.target.value);
    } catch (err) {
      console.error(err);
    }
  };
  
  function algorithmByDate(val: any){
    if( val !== "ALL" && val !==""){
        var d = new Date();
        var endDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);
        
        d.setDate(d.getDate()-val);

        var startDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);

        //console.log(startDate);
        //console.log(endDate);

        query.refetch({
              start: startDate,
              end: endDate,
        });
    }else{
        query.refetch({
              start: undefined,
              end: undefined,
        });
    }
  }
  
  return (
    <Layout className={'relativeclass'}>
      <div className={'filterbox'}>
        <label>Filter</label>
        <select onChange={onChange} value={selectedOption} >
          <option value="ALL">ALL</option>
          {filtersettings.map((e, key) => {
            return <option key={key} value={e.key}>{e.value}</option>;
          })}
        </select>
      </div>
      <PanelV2
        style={{
          gridArea: 'algorithms'
        }}
        title="Algorithms"
        loading={loading}
        error={error}
      >
        <AlgorithmTable data={data?.algorithmAnalytics ?? []} />
      </PanelV2>

        { configData[0]?.ndcPie &&
            <>
          <PanelV2
            style={{
              gridArea: 'flaggedNDCCS'
            }}
            title="Flagged NDCs"
            loading={loading}
            error={error}
          >
            <Pie
              data={{
                labels: data?.controlledNDCFlaggedAnalytics?.map((cfa: IControlledNdcFlaggedAnalytic) => cfa.productName!) ?? [],
                datasets: [
                  {
                    data: data?.controlledNDCFlaggedAnalytics?.map((cfa: IControlledNdcFlaggedAnalytic) => cfa.count) ?? [],
                    backgroundColor: colors,
                  },
                ],
              }}
              options={{
                legend: {
                  display: false,
                }
              }}
            />
          </PanelV2>

          <PanelV2
            style={{
              gridArea: 'flaggedNDCFamilies'
            }}
            title="Flagged Drug Families"
            loading={loading}
            error={error}
          >
            <Pie
              data={{
                labels: data?.drugFamilyNDCAnalytics?.map((dfa: IDrugFamilyNdcAnalytics) => dfa.drugFamilyNumber!) ?? [],
                datasets: [
                  {
                    data: data?.drugFamilyNDCAnalytics?.map((dfa: IDrugFamilyNdcAnalytics) => dfa.count) ?? [],
                    backgroundColor: colors,
                  },
                ],
              }}
              options={{
                legend: {
                  display: false,
                }
              }}
            />
          </PanelV2>
        </>
      }
      
      { configData[0]?.orderPie &&
            <>
          <PanelV2
            style={{
              gridArea: 'flaggedCS'
            }}
            title="Flagged CS"
            loading={loading}
            error={error}
          >
            <Pie
              data={{
                labels: data?.controlledFlaggedAnalytics?.map((cfa: IControlledFlaggedAnalytic) => cfa.productName!) ?? [],
                datasets: [
                  {
                    data: data?.controlledFlaggedAnalytics?.map((cfa: IControlledFlaggedAnalytic) => cfa.count) ?? [],
                    backgroundColor: colors,
                  },
                ],
              }}
              options={{
                legend: {
                  display: false,
                }
              }}
            />
          </PanelV2>

          <PanelV2
            style={{
              gridArea: 'flaggedFamilies'
            }}
            title="Flagged Drug Families"
            loading={loading}
            error={error}
          >
            <Pie
              data={{
                labels: data?.drugFamilyAnalytics?.map((dfa: IDrugFamilyAnalytics) => dfa.drugFamilyNumber!) ?? [],
                datasets: [
                  {
                    data: data?.drugFamilyAnalytics?.map((dfa: IDrugFamilyAnalytics) => dfa.count) ?? [],
                    backgroundColor: colors,
                  },
                ],
              }}
              options={{
                legend: {
                  display: false,
                }
              }}
            />
          </PanelV2>
        </>
      }
    </Layout>
  );
}

const Layout = styled('section')`
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
  grid-template-areas: 
    'algorithms algorithms'
    'flaggedNDCCS flaggedNDCFamilies'
    'flaggedCS flaggedFamilies';
`;

export default Overview;
