import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import AddUserModal from './AddUserModal';

export interface IAddUserModalContext {
  open(): void;
}

export const AddUserModalContext = React.createContext<Maybe<IAddUserModalContext>>(null);

interface Props {
  children: any;
}

export const AddUserModalProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <AddUserModalContext.Provider
      value={{
        open: () => setOpen(true),
      }}
    >
      {children}

      <AddUserModal
        open={open}
        onRequestClose={() => setOpen(false)}
      />
    </AddUserModalContext.Provider>
  );
};

export const useAddUserModal = () => {
  const context = React.useContext(AddUserModalContext);

  if (!context) {
    throw new Error('AddUserModalContext missing');
  }

  return context;
};
