import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { createUploadLink } from 'apollo-upload-client';
import { Auth } from 'aws-amplify';
import config from '../config';

export const cache = new InMemoryCache();

const request = async (operation: any) => {
  const session = await Auth.currentSession();
  const accessToken = session.getIdToken().getJwtToken();
  operation.setContext({ headers: { authorization: `Bearer ${accessToken}` } });
};

const requestLink = new ApolloLink((operation: any, forward: any) =>
  new Observable((observer: any) => {
    let handle: any;
    Promise.resolve(operation)
      .then((oper: any) => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) {
        handle.unsubscribe();
      }
    };
  })
);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError((({ graphQLErrors, networkError }: { graphQLErrors: any, networkError: any }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }: { message: any, locations: any, path: any }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }) as any),
    requestLink,
    withClientState({
      defaults: {
        isConnected: true
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_: any, { isConnected }: { isConnected: boolean }, { cache: c }: { cache: any }) => {
            c.writeData({ data: { isConnected } });
            return null;
          },
        },
      },
      cache,
    }),
    createUploadLink({
      uri: `${config.tenantUrl}/graphql`,
      credentials: 'same-origin',
    }),
  ]),
  cache,
});

export default apolloClient;
