import { Button, createStyles, InputAdornment, makeStyles, Paper, TextField, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { AccountCircle, Lock } from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { useNotification } from '../../components/Notification/NotificationProvider';

const useStyles = makeStyles((theme: Theme) => createStyles({
  box: {
    height: '100%',
    backgroundColor: '#1976D2',
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

function Login() {
  const classes = useStyles();
  const { handleSubmit, register, setValue, errors, formState } = useForm();
  const { setMessage } = useNotification();

  const onSubmit = async (values: any) => {
    try {
      setMessage(undefined);
      const usernameval = values.username.trim();
      const res = await Auth.signIn(usernameval, values.password);
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const newPassword = prompt('Please set a new password');

        if (!newPassword) {
          throw new Error('Password can not be empty!');
        }

        if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d`$*.[\]{}()?\-"!@#%&/,><':;|_~]{8,}$/.test(
            newPassword,
          )
        ) {
          throw new Error(
            'Password must contain 1 uppercase letter, 1 lowercase letter, 1 number and a minimum of 8 characters! Acceptable special characters are ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ `',
          );
        }

        const attributes = {
          email: res.username,
          family_name: 'admin',
          given_name: 'admin',
        };

        await Auth.completeNewPassword(res, newPassword, attributes);
      }
    } catch (err) {
      console.error(err);
      setMessage(err.message);
    }
  };

  const handleForgotPassword = async () => {
    const email = prompt('Please enter email');
    if (!email) {
      alert('Please enter a valid email');
    } else {
      try {
        let loop = true;
        await Auth.forgotPassword(email);
        do {
          const code = prompt('Please enter code that was sent to your email');
          if (!code) {
            alert('Please enter verification code!');
            continue;
          }

          const password = prompt('Please set a new password');
          if (!password) {
            alert('Password can not be empty!');
            continue;
          }

          if (
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d`$*.[\]{}()?\-"!@#%&/,><':;|_~]{8,}$/.test(
              password,
            )
          ) {
            alert(
              '\'Password must contain 1 uppercase letter, 1 lowercase letter, 1 number and a minimum of 8 characters! Acceptable special characters are ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\\\ , > < \\\' : ; | _ ~ `\'',
            );
            continue;
          }

          try {
            await Auth.forgotPasswordSubmit(email, code, password);
            alert('Your password has been reset.');
          } catch (err) {
            alert(err.message);
          }
          loop = false;
        } while (loop);
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }
  };

  return (
    <Box
      className={classes.box}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p="1rem"
    >
      <Paper>
        <Box p="3rem">
          <form
            className={classes.form}
            onSubmit={handleSubmit(onSubmit)}
          >
            <RHFInput
              as={
                <TextField
                  error={!!errors.username}
                  helperText={errors.username ? 'Username is required' : ''}
                  label="Username"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              }
              rules={{ required: true }}
              name="username"
              register={register}
              setValue={setValue}
            />

            <RHFInput
              as={
                <TextField
                  error={!!errors.password}
                  helperText={errors.password ? 'Password is required' : ''}
                  label="Password"
                  type="password"
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              }
              rules={{ required: true }}
              name="password"
              register={register}
              setValue={setValue}
            />

            <Button
              color="primary"
              disabled={formState.isSubmitting}
              type="submit"
            >
              Login
            </Button>
          </form>
        </Box>
      </Paper>

      <Button
        onClick={handleForgotPassword}
        type="button"
        size="small"
        style={{
          marginTop: '1rem',
        }}
      >
        Forgot Password?
      </Button>
    </Box>
  );
}

export default Login;
