import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import AddUserIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useAddUserModal } from '../../components/AddUserModal/AddUserModalContext';
import { useEditUserModal } from '../../components/EditUserModal/EditUserModalContext';
import { Layout } from '../../components/Layout';
import PanelV2 from '../../components/Panel/PanelV2';
import Table from '../../components/Table/Table';
import { IUser, useListUsersQuery,useGetSettingsQuery } from '../../generated/graphql';
import UpdateUserStatusButton from './components/UpdateUserStatusButton';

function Users() {

  const query1 = useGetSettingsQuery({
    fetchPolicy: 'no-cache'
  });
   const { data: dataR } = query1;
  
   const role= dataR?.userSettings?.role;
   console.log(role)
   const { open: openModal } = useAddUserModal();
   const { open: editopenModal } = useEditUserModal();
   const query = useListUsersQuery({ fetchPolicy: 'no-cache' });
   const { loading, error, data } = query;
  let columns: Array<any>  =  [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Role',
        key: 'role',
        dataIndex: 'role',
      },
      {
        title: 'Enabled',
        key: 'enabled',
        render(user: IUser) {
          return user.enabled ? 'Yes' : 'No';
        }
      },
      {
        title: 'Date Added',
        key: 'dateAdded',
        render(user: IUser) {
          return DateTime.fromMillis(parseInt(user.createdAt!, 10)).toLocaleString(DateTime.DATETIME_MED);
        }
      },
      {
        title: 'Actions',
        key: 'actions',
        render(user: IUser) {
          switch (true) {
            case user.role === 'owner':
              return null;
            case user.enabled:
              return (
              <>
                
                <Tooltip title="Edit User">
                    <IconButton onClick={() => editopenModal(user.userId!,user.title!,user.name!,user.email!,user.role!,user.enabled!)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  </>
              );
            default:
              return (
              <>
                
                  <Tooltip title="Edit User">
                    <IconButton onClick={() => editopenModal(user.userId!,user.title!,user.name!,user.email!,user.role!,user.enabled!)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  </>
              );
          }
        },
      }
    ];
   if(role !='admin'){
     columns =  [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Role',
        key: 'role',
        dataIndex: 'role',
      },
      {
        title: 'Enabled',
        key: 'enabled',
        render(user: IUser) {
          return user.enabled ? 'Yes' : 'No';
        }
      },
      {
        title: 'Date Added',
        key: 'dateAdded',
        render(user: IUser) {
          return DateTime.fromMillis(parseInt(user.createdAt!, 10)).toLocaleString(DateTime.DATETIME_MED);
        }
      }
    ];
   } 
  return (
    <Layout>
      <PanelV2
        title="Users"
        loading={loading}
        error={error}
      >
        <Toolbar>
          <Typography
            variant="h1"
            color="inherit"
            style={{ flex: 1 }}
          />
          <Tooltip title="Refresh">
            <IconButton onClick={() => query.refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {role == 'admin' && 
        <>
          <Tooltip title="Add User">
            <IconButton onClick={() => openModal()}>
              <AddUserIcon />
            </IconButton>
          </Tooltip>
          </>
          }
        </Toolbar>

        <Table
          columns={columns}
          data={data?.users ?? []}
          rowKey="userId"
        />
      </PanelV2>
    </Layout>
  );
}

export default Users;
