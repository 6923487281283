import { IconButton,ListSubheader, TableRow, TableCell, ListItemText, ListItem, TableBody, Table } from "@material-ui/core"
import * as React from "react"
import { useFlagUserModal } from '../../../components/FlagUserModal/FlagUserModalContext';
import { Field } from 'formik';
import { getStyles, renderRowCell } from './NotesDrawerContent.style';
import { useGetNotesDrawerContentQuery } from '../../../generated/graphql';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import Modal from '@material-ui/core/Modal';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

}
const CommentBox = (props: any) => {
  const classes = getStyles();
  const { open: openModal } = useFlagUserModal();
 const [modalStyle] = React.useState(getModalStyle);
 const [open, setOpen] = React.useState(false);

 const handleOpen = () => {
   console.log(props.orders,"lekshmi")
   setOpen(true);
 };

 const handleClose = () => {
   setOpen(false);
 };

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p>
      
    </div>
  );
    if(props.orderItem.issuspicious ==='true' && props.orders.suspiciousorder  === 'true'){
      return (<></>);
    }
    if(!props.orderItem.algorithm){ 
      return (<></>);
    }
    if(props.orderItem.cleared ==='true'){
      return (<></>);
    }
   
    const filterItemAndCallModal = () => {
      let userid = props.orders.approvaluser.filter( function (user:any) {
        return (user.ndc === props.orderItem.ndc)
      });
      //debugger;
      openModal({
        userid: userid[0].userId,
        requestid:props.orderItem.requestId,
        orderid:props.orderItem.orderId,
        ndc:props.orderItem.ndc
      });
    }
   
    return (
      <TableRow 
        key={`orderItemcomment.ndc_${props.index}`}
       
      >
        <TableCell colSpan={3}>
          <label>
            <Field type="checkbox" name={`clearflag[${props.index}]`} value={`uuid-${props.orderItem.orderUuid}`} /> 
            {(props.orderItem.approvedcount!='0') && <>
             <span className="post__content" dangerouslySetInnerHTML={{__html: (props.orders.suspiciousorder  === 'true') ? `Mark As Suspicious<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(<b>${props.orderItem.approvedcount}</b> Approval(s) Required)` : `Clear Flag <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(<b>${props.orderItem.approvedcount}</b> Approval(s) Required)`}}></span>
             </>
            } 
            {(props.orderItem.approvedcount == '1') && 
            <>
           
             
            <IconButton onClick={() => filterItemAndCallModal()}>
                          <EmailIcon />
                        </IconButton>
            </>
           
            }
            {(props.orderItem.approvedcount == '0') && <>
             <span className="post__content" dangerouslySetInnerHTML={{__html: (props.orders.suspiciousorder  === 'true') ? `Mark As Suspicious` : `Clear Flag`}}></span>
             </>
            }
          </label>
        </TableCell>
        <TableCell colSpan={4}> 
		
          <Field type="text" name={`message[${props.index}]`} placeholder={(props.orders.suspiciousorder  === 'true') ? 'Enter Reason' : 'Clear Flag Comment'} className="commentboxcls"  />
          <div className={classes.errorcls}>{props.errors[`message${props.index}`] && (props.errors[`message${props.index}`]  !== "") ? props.errors[`message${props.index}`] : null}</div>
        </TableCell>

        
        
      </TableRow>
    )
}

export default CommentBox;