import { Paper, Typography } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties } from 'react';
import SwitchLoadingErrorChildren, { Props as SwitchLoadingErrorChildrenProps } from '../SwitchLoadingErrorChildren';

interface Props extends SwitchLoadingErrorChildrenProps {
  style?: CSSProperties
  title?: string;
}

function PanelV2(props: Props) {
  const { style, title, loading, error, children } = props;

  return (
    <section style={style}>
      {
        title && (
          <Typography
            variant="subtitle1"
            color="secondary"
            paragraph
          >
            {title}
          </Typography>
        )
      }

      <Paper
        style={{
          overflow: 'auto',
          padding: '1rem',
        }}
      >
        <SwitchLoadingErrorChildren
          loading={loading}
          error={error}
        >
          {children}
        </SwitchLoadingErrorChildren>
      </Paper>
    </section>
  );
}

export default PanelV2;
