import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import NotesDrawer from './NotesDrawer';

export interface INotesDrawerContext {
  open(id: string,requestid:string,customerid: string,customername: string,trade: string,orderedat: string): void;
}

export const NotesDrawerContext = React.createContext<Maybe<INotesDrawerContext>>(null);

interface Props {
  children: any;
}

export const NotesDrawerProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState<Maybe<string>>();
  
  const [requestid, setrequestid] = React.useState<string>('');
  const [customerid, setcustomerid] = React.useState<string>('');
  const [customername, setcustomername] = React.useState<string>('');
  const [trade, settrade] = React.useState<string>('');
  const [orderedat, setorderedat] = React.useState<string>('');

  return (
    <NotesDrawerContext.Provider
      value={{
        open: (id: string,requestid:string,customerid: string,customername: string,trade: string,orderedat: string) => {
          setId(id);
          setrequestid(requestid);
          setcustomerid(customerid);
          setcustomername(customername);
          settrade(trade);
          setorderedat(orderedat);
          setOpen(true);
        },
      }}
    >
      {children}

      <NotesDrawer
        open={open}
        id={id}
        requestid={requestid}
         customerid={customerid}
          customername={customername}
           trade={trade}
            orderedat={orderedat}
        onRequestClose={() => setOpen(false)}
      />
    </NotesDrawerContext.Provider>
  );
};

export const useNotesDrawer = () => {
  const context = React.useContext(NotesDrawerContext);

  if (!context) {
    throw new Error('NotesDrawerContext missing');
  }

  return context;
};
