import { AppBar, Tab, Tabs } from '@material-ui/core';
import * as React from 'react';
import { Layout } from '../../components/Layout';
import CustomerReports from './components/Customers/CustomerReports';
import OrderReports from './components/Orders/OrdersReports';

interface Props {

}

const Reports = (props: Props) => {
  const [tab, setTab] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => setTab(value);

  const renderTabPanel = () => {
    switch (tab) {
      case 0:
        return <OrderReports />;
      case 1:
        return <CustomerReports />;
      default:
        throw new Error(`Reports tab value ${tab} not supported`);
    }
  };

  return (
    <section>
      <AppBar position="static">
       
      </AppBar>

      <Layout>
        {renderTabPanel()}
      </Layout>
    </section>
  );
};

export default Reports;
