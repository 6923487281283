import { IOrderLineItem, IFlagsndc } from '../../../generated/graphql';
import moment from 'moment';
const getLineItems = (data: any) => {
    let lineItems: any[] = [];
    let algorithmExist =  true;
    let cleardalgmExist = false;
    let algorithmCount =0;
    let algorithmCountAll =0;
    let algorithmClearCountAll =0;
    
    if(data?.order?.lineItems){
        lineItems = data.order.lineItems.map((item: IOrderLineItem) => {
        let enddatetxt:string = '';
        let algorithmName: string[] = [];
        let existingalgorithm: string[] = [];
        let cleared ='false';
        let approvedcount:string ='2';
        data?.order?.flagsndc?.forEach((flagNdcObj: IFlagsndc) => {
        algorithmCountAll++;
        console.log(flagNdcObj.enddate,"dsdsd")
          if(flagNdcObj.ndc === item.ndc && flagNdcObj.algorithm){
          let totalavg =  Number(flagNdcObj.total).toFixed(2);
           
          if(flagNdcObj.enddate != ''){
             let enddate:any =  moment(Number(flagNdcObj.enddate)).format('MM-DD-YYYY');
             console.log(enddate,"dsdsd")
             if(enddate != '01-01-1970'){
                 let enddatetxt = "from "+enddate;
             }
            
          }
       
          let orderIds =  flagNdcObj.orderIds ? JSON.parse(flagNdcObj.orderIds).join(", ") :[];
            let appendtxt = flagNdcObj.algorithm === "Algorithm3: NDC frequency over tolerance level" ? `(Threshold : Over ${flagNdcObj.total} times in ${flagNdcObj.threshold} day(s).)` : ((flagNdcObj.algorithm === "Algorithm4b: NDC over customer\'s family average"  ) ? `(Average Period : ${flagNdcObj.frequency} Days  | Family : ${flagNdcObj.familynumber} | Average Value: ${totalavg}  |   Threshold : ${flagNdcObj.threshold})` : (flagNdcObj.algorithm === "Algorithm9: Line items with already existing suspicious or NDC flags" ? `(Order ID: ${orderIds})` :`(Average Value : ${totalavg}  |   Threshold : ${flagNdcObj.threshold})`));
            
            
            let algorithm = (flagNdcObj.algorithm === "Algorithm4b: NDC over customer\'s  family average"  ) ? `Algorithm4b: NDC over customer\'s family average` : flagNdcObj.algorithm;
                if (! existingalgorithm.includes(algorithm)) {
                  algorithmName.push(algorithm + '<br/>' + appendtxt); 
                }
               
             existingalgorithm.push(algorithm);

            algorithmExist = true;
            cleardalgmExist=true;
              if(flagNdcObj.cleared === 'true'){
                 cleared ='true';
                 algorithmClearCountAll++;
              }
            
          }
        });
        let issuspicious ='false';
        let issuspiciousnotes ='false';
		data?.order?.flaggedndc?.forEach((notesObj: { ndc: any; suspicious:any; }) => {
          if(notesObj.ndc === item.ndc ){
         
              if(notesObj.suspicious === true){
                issuspicious ='true';
              }
            
          }
        });
		  data?.order?.approvalcount?.forEach((approvalcountObj: { ndc: any; total:any; }) => {
      if(approvalcountObj.ndc ==''){
          approvedcount = '0';
      }else{
          if(approvalcountObj.ndc === item.ndc ){
        
             if(approvalcountObj.total != undefined ){

                approvedcount = approvalcountObj.total;
             }
            
          }
          }
        });
    
		data?.order?.notes?.forEach((notesObj: { ndc: any; issuspicious:any; }) => {

          if(notesObj.ndc === item.ndc ){
         
              if(notesObj.issuspicious === true){
			    issuspiciousnotes ='true';
              }
            
          }
        });
		 if(algorithmClearCountAll == algorithmCountAll){
            algorithmExist = false;
        }
        if(algorithmName.length > 0 && cleared === 'false'){
          algorithmCount++;
        }
        return {
          ...item,
          cleared,
          issuspicious,
          approvedcount,
          issuspiciousnotes,
          algorithm: (algorithmName.length > 0) ? algorithmName.join('</br>') : ''
        }
      })
    }
    return {
        lineItems,
        algorithmExist,
        cleardalgmExist,
        algorithmCount
    };
}

export default getLineItems;