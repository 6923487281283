import { AppBar } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Tab from '../../components/Tab';
import useTab from '../../components/Tab/useTab';
import ClassOfTrade from './components/ClassOfTrade';
import FlaggedClassOfTrade from './components/FlaggedClassOfTrade';
import NDCs from './components/NDCs';
import OrderAverages from './components/OrderAverages';
import OrdersByFamily from './components/OrdersByFamily';
import OrdersByTrade from './components/OrdersByTrade';
import Overview from './components/Overview';
import { IGettabview,useGetOverviewAnalyticsQuery } from '../../generated/graphql';

interface ITab {
  name: string
  component: React.ComponentType<any>
}

interface Props extends RouteComponentProps {

}

function Analytics(props: Props) {
 
  const { hash } = props.location;
  const query = useGetOverviewAnalyticsQuery();
  const {  data } = query;
  const showDashboardtab = data?.gettabview?.map((ds: IGettabview) => ds!) ?? [];
  let showDashboardtabData ='false';
  
  const showDashboardtabDataval = showDashboardtab.flat();
  if(showDashboardtab.length > 0 ){
      showDashboardtabData = showDashboardtabDataval[0].showdashboardtab ?? 'false';
     console.log(showDashboardtabData);
  }     
  let tabs: Array<ITab>  =  [
    {
      name: 'Overview',
      component: Overview,
    },
  ];
  if(showDashboardtabData.toString()==='true'){
    
       tabs=[
        {
          name: 'Overview',
          component: Overview,
        },
        {
          name: 'NDC',
          component: NDCs,
        },
        {
          name: 'Order Averages',
          component: OrderAverages,
        },
        {
          name: 'Orders By Trade',
          component: OrdersByTrade,
        },
        {
          name: 'Orders By Family',
          component: OrdersByFamily,
        },
        {
          name: 'Class of Trade',
          component: ClassOfTrade,
        },
        {
          name: 'Flagged Class of Trade',
          component: FlaggedClassOfTrade,
        },
      ];
    }
  
  const tab = useTab({ hash, tabs });
  return (
    <section>
      <AppBar position="static">
        <Tab.Header
          {...tab}
          tabs={tabs}
        />
      </AppBar>
      <Tab.Content
        {...tab}
        tabs={tabs}
      />
    </section>
  )
}

export default Analytics;
