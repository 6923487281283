import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import * as React from 'react';
import { useEffect } from 'react';
import { useGetRequestAttachmentLazyQuery } from '../../generated/graphql';
import AttachFileIcon from '@material-ui/icons/AttachFile';
interface Props {
  requestId: string
}

const ViewAttachmentButtonNote = (props: Props) => {
  const { requestId } = props;
  const [getRequestAttachment, { loading, error, data }] = useGetRequestAttachmentLazyQuery();

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (data) {
	var link=document.createElement('a');
	 document.body.appendChild(link);
	 link.href=data.requestAttachment;
	 link.click();
	
	
    }
  }, [data]);

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={() => getRequestAttachment({ variables: { requestId } })}
      disabled={loading}
      startIcon={<OpenInNewIcon />}
    >
      Download CSV
    </Button>
  );
};

export default ViewAttachmentButtonNote;
