import { Switch, Tooltip } from '@material-ui/core';
import * as L from 'partial.lenses';
import * as R from 'ramda';
import * as React from 'react';
import Table from '../../../components/Table/Table';
import { GetSettingsDocument, IWebhook, useUpdateWebhookMutation } from '../../../generated/graphql';
import DeleteWebhookButton from './DeleteWebhookButton';

const columns = [
  {
    title: 'URL',
    key: 'url',
    dataIndex: 'url',
  },
  {
    title: 'Secret',
    key: 'secret',
    dataIndex: 'secret',
  },
];

interface Props {
  data: Array<IWebhook>;
}

function Webhooks(props: Props) {
  const { data } = props;
  const [updateWebhook] = useUpdateWebhookMutation();

  const onChange = (webhook: IWebhook) => async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    try {
      await updateWebhook({
        variables: {
          input: {
            webhookId: webhook.webhookId!,
            url: webhook.url!,
            active: checked!,
          },
        },
        update: (store, { data }) => {
          if (!data) {
            return;
          }

          const d = store.readQuery({
            query: GetSettingsDocument,
          });

          store.writeQuery({
            query: GetSettingsDocument,
            data: L.modify(['webhooks', L.find(R.whereEq({ webhookId: webhook.webhookId }))], () => data.updateWebhook, d),
          });
        },
      });
       window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Table
      rowKey="webhookId"
      data={data}
      columns={[
        ...columns,
        {
          title: 'Active',
          key: 'active',
          render: (webhook: IWebhook) => (
            <Switch
              checked={webhook.active!}
              onChange={onChange(webhook)}
            />
          )
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (webhook: IWebhook) => (
            <Tooltip title="Delete Webhook">
              <DeleteWebhookButton id={webhook.webhookId!} />
            </Tooltip>
          )
        }
      ]}
    />
  );
}

export default Webhooks;
