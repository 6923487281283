import * as React from 'react';

interface Props {

}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.PureComponent<Props, State> {

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
