import { Toolbar, Typography, Tooltip } from '@material-ui/core';
import * as React from 'react';
import { useQueryParam } from 'use-query-params';
import { NumberParam, StringParam } from 'use-query-params/lib/params';
import { Layout } from '../../components/Layout';

import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import PanelV2 from '../../components/Panel/PanelV2';
import Searchbar from '../../components/Searchbar/Searchbar';
import SwitchLoadingErrorChildren from '../../components/SwitchLoadingErrorChildren';
import { ICustomer, useListCustomersQuery } from '../../generated/graphql';
import useNavigator from '../../lib/useNavigator';
import CustomersTable from './components/CustomersTable';
import Button from '@material-ui/core/Button';
import { CommaArrayParam } from '../../utils';
function Customers() {
  const [sort, setSort] = useQueryParam('sort', CommaArrayParam);
  const [page, setPage] = useQueryParam<number>('page', NumberParam);
  const [limit, setLimit] = useQueryParam<number>('limit', NumberParam);
  const [q, setQ] = useQueryParam<string>('q', StringParam);
  
  const { loading, error, data } = useListCustomersQuery({
    variables: {
      q,
      limit,
      page,
      sort
    },
  });
  const navigator = useNavigator();
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
  return (
    <Layout>
      <PanelV2 title="Customers">
        <Toolbar>
          <Typography
            variant="h1"
            color="inherit"
            style={{ flex: 1 }}
          />

          <Searchbar
            value={q}
            onSearch={setQ}
          />
          <HtmlTooltip
            title={
              <React.Fragment>
                {"Search includes: Customer ID,Customer Name"}
              </React.Fragment>
            }
            >
            <Button><HelpIcon/></Button>
          </HtmlTooltip>
        </Toolbar>

        <SwitchLoadingErrorChildren
          loading={loading}
          error={error}
        >
          <CustomersTable
            datacustomer={data?.customers?.data ?? []}
            pagination={data?.customers?.pagination}
            onChangePage={setPage}
            onChangeRowsPerPage={setLimit}
            onClickRow={(customer: ICustomer) => navigator.goToCustomer(customer.customerId)}
          />
        </SwitchLoadingErrorChildren>
      </PanelV2>
    </Layout>
  );
}

export default Customers;
