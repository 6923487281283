import * as React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Field } from 'formik';
import { getStyles } from './NotesDrawerContent.style';


const ClearAllbox = (props: any) => {
  const classes = getStyles();
  return (
  
    <TableRow>
      <TableCell  colSpan={3}>
      <label>
              <Field type="checkbox" name="checkAllflagorder"  />
			  {(props.orders.suspiciousorder  === 'true') ? ' Mark As All Suspicious' : ' Clear flags(s) from all line(s)'}
              
          </label>
      </TableCell>
      <TableCell  colSpan={5}>
	  
        <Field type="text" name="ClearAllComment" placeholder={(props.orders.suspiciousorder  === 'true') ? 'Enter Reason' : 'Clear Flag Comment'}  className="commentboxcls"/>
        <div className={classes.errorcls}>
          {props.errors.checkallcomment && (props.errors.checkallcomment  !== "") ? props.errors.checkallcomment : null}
        </div>
      </TableCell>
      
    </TableRow>
    
  )
}

export default ClearAllbox;