import {
  createStyles,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { INote, useAddNotesMutation, useGetNotesDrawerContentQuery } from '../../../generated/graphql';
import ViewAttachmentButton from '../../../routes/Orders/components/ViewAttachmentButton';
import CommonCommentBox from './CommonCommentBox';

const useStyles = makeStyles((theme: Theme) => createStyles({
  list: {
    overflowY: 'auto',
    flex: 1,
    padding: 0,
  },
  listText: {
    maxWidth: '100%',
    overflowWrap: 'break-word',
    padding: 0,
  },
  subheader: {
    backgroundColor: '#ffffff',
    display:'block',
  },
  submitcls:{
    padding:'24px',
    textAlign:'center',
  },
  btnclssave:{
    color:' #fff',
    backgroundColor: '#204d74',
    borderColor: '#122b40',
    display: 'inline-block',
    marginBottom: '0',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    backgroundImage: 'none',
    border: '1px solid transparent',
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: '1.42857143',
    borderRadius: '4px',
    float: 'left',
    marginLeft: '-13px',
  },
  btnclscancel:{
    color:' #000',
    display: 'inline-block',
    marginBottom: '0',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    backgroundImage: 'none',
    border: '1px solid transparent',
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: '1.42857143',
    borderRadius: '4px',
    float: 'left',
    marginLeft: '20px',
  },
  tagname:{
    marginLeft: '12px',
  },
   tagnamecls:{
    marginLeft: '12px',
    color:'red',
    },
}));

interface Props {
  notes: Array<INote>;
  errors:any;
  orderId:any;
  requestId:any;
  customerId:any;
  customerName:any;
  trade:any;
  orderedAt:any;
}

function NotesList(props: Props) {
  const { notes = [] ,errors,orderId,requestId,customerId,customerName,trade,orderedAt} = props;
  const classes = useStyles();
  const element = useRef<HTMLDivElement | null>(null);

  function  addnote(){
    const lsOutput1:any = document.getElementById('notecls');
    lsOutput1.style.display = 'none';
    const lsOutput:any = document.getElementById('targetdiv');
    lsOutput.style.display = 'block';
}

function  cancelNotesData(){
  const lsOutput1:any = document.getElementById('notecls');
  lsOutput1.style.display = 'block';
  const lsOutput:any = document.getElementById('targetdiv');
  lsOutput.style.display = 'none';
  const lsOutput2:any = document.getElementById('commonmessage');
  lsOutput2.value ='';
  const commonmessageerror:any = document.getElementById('commonmessageerror');
  commonmessageerror.innerHTML  = '';
}

 // Mutation - Create Note
 const [addNote] = useAddNotesMutation();

 const query = useGetNotesDrawerContentQuery({ variables: { orderId: orderId,requestId:requestId,customerId:customerId,customerName:customerName,trade:trade,orderedAt:orderedAt}});
  async function  addNotesData(){
 
    const lsOutput:any = document.getElementById('commonmessage');
     const commonmsg = lsOutput.value;
     if(commonmsg === ''){
      const commonmessageerror:any = document.getElementById('commonmessageerror');
      commonmessageerror.innerHTML = "Please enter comment";
       
    }else{
     try {
     
      const lsOutput:any = document.getElementById('btnclsnote');
      lsOutput.setAttribute('disabled', true);
    //  lsOutput.classList.add("btndisabled"); 
      await addNote({
        variables: {
          input: {
            orderId: orderId,
            requestId:requestId ?? '',
            customerId:customerId ?? '',
            customerName:customerName ?? '',
            trade:trade ?? '',
            orderedAt:orderedAt ?? '',
            commonmessage: commonmsg,
          },
        },
        
      });
      const lsOutput8:any = document.getElementById('btnclsnote');
      lsOutput8.removeAttribute('disabled', false);
      const lsOutput1:any = document.getElementById('commonmessage');
      lsOutput1.value ='';
      const commonmessageerror:any = document.getElementById('commonmessageerror');
      commonmessageerror.innerHTML  = '';
     // lsOutput8.classList.remove("btndisabled");
      cancelNotesData();
      
      
      
      query.refetch();
    } catch (err) {
      console.error(err);
    }
  }
  }
  useEffect(() => {
    if (element.current) {
     // element.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [element, notes]);
  if(notes.length === 0){ 
    return (
      <List className={classes.list}>
      <ListSubheader className={classes.subheader}>Notes</ListSubheader>
      <button type="button" onClick={() => addnote()} className="notecls" id="notecls">Add Note</button>
        <div id="targetdiv" className="noteclsdiv">
        
        <CommonCommentBox errors={errors}/>
        <div className={classes.submitcls}>
          <button type="button"  className={classes.btnclssave} id="btnclsnote" onClick={() => addNotesData()}>
          Save Note
          </button>
          <button type="button"  className={classes.btnclscancel} onClick={() => cancelNotesData()}>
            Cancel
          </button>
          </div>
          </div>  
      <ListItem> No notes found.</ListItem>
      </List>
    
      );
  }
  return (
    <List className={classes.list}>
      <ListSubheader className={classes.subheader}>Notes</ListSubheader>
      <button type="button" onClick={() => addnote()} className="notecls" id="notecls">Add Note</button>
        <div id="targetdiv" className="noteclsdiv">
        
        <CommonCommentBox errors={errors}/>
        <div className={classes.submitcls}>
          <button type="button"  className={classes.btnclssave} id="btnclsnote" onClick={() => addNotesData()}>
          Save Note
          </button>
          <button type="button"  className={classes.btnclscancel} onClick={() => cancelNotesData()}>
            Cancel
          </button>
          </div>
          </div>  
      {
        notes.map((note: INote) => (
          <ListItem
            key={note.noteId!}
            divider
          >
            <div
              className={classes.listText}
              ref={element}
            >
              <ListItemText
                primary={
                  <>
                    <Typography
                      // variant="title"
                    >
                      {note.user!.name}
                    </Typography>
                    <Typography
                      variant="caption"
                    >
                      {DateTime.fromMillis(parseInt(note.createdAt!, 10)).toLocaleString(DateTime.DATETIME_MED)}
                       <b className={classes.tagname}>
					  
						  {(note.noteType?.trim() =='n' && (note.issuspicious == false)) && 'NDC Based Flag(s) Cleared'}
						  {(note.noteType?.trim() =='o' && (note.issuspicious == false)) && 'Order Based Flag(s) Cleared'}
						  {(note.noteType?.trim() =='all' && (note.issuspicious == false)) && 'NDC Based Flag(s) Cleared;  Note applies to all lines.'}
						  {(note.noteType?.trim() =='g' && (note.issuspicious == false)) && 'General Note'}
              </b>
               <b className={classes.tagnamecls}>
						  {(note.issuspicious == true && note.ndc==null) && 'Suspicious Order'}
						  {(note.issuspicious == true && note.ndc!=null) && 'Suspicious NDC'}
						
                    </b>
					
                    </Typography>
                  </>
                }
                secondary={
                  <>
                    <Typography>{note.message}</Typography>
                    {
                      !!note.attachment && <ViewAttachmentButton noteId={note.noteId!} />
                    }
                  </>
                }
              />
            </div>
          </ListItem>
        ))
      }
    </List>
  );
}

export default NotesList;
