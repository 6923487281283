import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, CircularProgress,Tooltip} from '@material-ui/core';
import 'date-fns';
import download from 'downloadjs';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import HelpIcon from '@material-ui/icons/Help';

import { withStyles } from '@material-ui/core/styles';
import { RHFInput } from 'react-hook-form-input';
import Button from '../../../../components/Button';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import PanelV2 from '../../../../components/Panel/PanelV2';
import useDownloadReport from '../../../../lib/useDownloadReport';
import { useGetOrdersReportsQuery } from '../../../../generated/graphql';
import moment from "moment";
import {
  Checkbox
} from "@material-ui/core";

interface Values {
  startDate?: string;
  endDate?: string;
  flagged?: string;
  customerName?: string;
  zipCode?: string;
  minME?: string;
  maxME?: string;
}

interface Props {

}
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const OrderReports = () => {
  const [downloadReport] = useDownloadReport({ type: 'orders' });
  const defaultValues = {
 
  flagged: "true", // default value is the radio input value
  
};
  const { handleSubmit, register, setValue } = useForm<Values>({ defaultValues });
  const query = useGetOrdersReportsQuery();
  const {  data,loading } = query;
  
  const isalgmdisable = (data?.algorithmdisableexport?.length !== 0 ) ? 1 : 0;
  console.log(isalgmdisable);
  console.log(data?.algorithmdisableexport?.length);
  const onSubmit = async (values: Values) => {
    try {
	
	console.log(values)
      setloadingdatatxt(false)
      setloadingdata(true)
	  
	   var fromDate = values.startDate
		var toDate = values.endDate;
		const commonmessageerror:any = document.getElementById('commondateerror');
		const commonmessagemainerror:any = document.getElementById('commondatemainerror');
		if(fromDate!= undefined && toDate!=undefined){
			const days = moment(toDate).diff(fromDate, 'days')
			
		if((days) >100)
		{
		
      		commonmessageerror.innerHTML = "Please limit the date range to a maximum interval of 100 days.";
      		commonmessagemainerror.innerHTML = "Selected date range is over the limits of this online report, please select a shorter period.";
			setloadingdata(false)
			return;
		}

		}else{
			
      	   commonmessageerror.innerHTML = "Please enter Start Date and End Date";
		   setloadingdata(false)
		   return;
		}
		commonmessageerror.innerHTML ='';
		commonmessagemainerror.innerHTML ='';
      const res = await downloadReport(values);
      let csvname='NavSOM_Orders Generated On Date '+moment().format("YYYY-MM-DD")+' Time '+moment().format("hh-mm-ss")+'.csv';
      if(values.flagged === "cleared"){
          csvname= 'NavSOM_Cleared_Orders Generated On Date '+moment().format("YYYY-MM-DD")+' Time '+moment().format("hh-mm-ss")+'.csv';
      }
      if(values.flagged === "true"){
        csvname= 'NavSOM_Flagged_Orders Generated On Date '+moment().format("YYYY-MM-DD")+' Time '+moment().format("hh-mm-ss")+'.csv';
    }
      if(download(await res.blob(), csvname, 'text/csv')){
        setloadingdata(false)
      }
      
    } catch (error) {
     console.log(error)
      setloadingdatatxt(true)
      setloadingdata(false)
    }
  };
  
  const[loadingdata,setloadingdata]= React.useState(false);
  const[loadingdatatxt,setloadingdatatxt]= React.useState(false);
 
  if (loading ) {
    return <CircularProgress />;
  }
  
  return (
    <PanelV2
      title="Order Reports"
    >
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFInput
          as={
            <TextField
            type="date"
              label="Start Date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="date"
          name="startDate"
          register={register}
          setValue={setValue}
        />

        &nbsp;

        <RHFInput
          as={
            <TextField
            type="date"
              label="End Date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="date"
          name="endDate"
          register={register}
          setValue={setValue}
        />
		 <div className="errorclsmsg" id="commondateerror"></div>
        <br /><br />

        <FormControl component="fieldset">
          <FormLabel component="legend">Flagged Status</FormLabel>
          <RHFInput
            as={
              <RadioGroup>
               <FormControlLabel value="all" control={<Radio />} label="ALL" />
               <FormControlLabel value="noflags" control={<Radio />} label="No Flags" />
                <FormControlLabel value="true" control={<Radio />} label="Flagged" />
                <FormControlLabel value="cleared" control={<Radio />} label="Cleared" />
                <FormControlLabel value="suspicious" control={<Radio />} label="Suspicious" />
              </RadioGroup>
            }
            type="radio"
            name="flagged"
            register={register}
            setValue={setValue}
          />
        </FormControl>
        <br /><br />

          <RHFInput
            as={
              <TextField
                label="NDC"
                className="form-textfield"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            }
            type="text"
            name="ndc"
            register={register}
            setValue={setValue}
          />
                  <HtmlTooltip
            title={
              <React.Fragment>
                {"Please add multiple entries to be searched in the same field, separated by commas."}
                {" For example, multiple NDCs can be added to the NDC field separated by commas."}
              </React.Fragment>
            }
            >
            <Button><HelpIcon/></Button>
          </HtmlTooltip>
          
          <br /><br />

        <RHFInput
          as={
            <TextField
              label="Family"
              className="form-textfield"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="family"
          register={register}
          setValue={setValue}
        />

        <br /><br />
        <RHFInput
          as={
            <TextField
              label="Customer Trade"
              className="form-textfield"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="trade"
          register={register}
          setValue={setValue}
        />

        <br /><br />

        <RHFInput
          as={
            <TextField
              label="Customer Name"
              className="form-textfield"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="customerName"
          register={register}
          setValue={setValue}
        />
        <br /><br />

        <RHFInput
          as={
            <TextField
              label="Customer Id"
              className="form-textfield"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="customerId"
          register={register}
          setValue={setValue}
        />
        <br /><br />

        <RHFInput
          as={
            <TextField
              label="Zip Code"
              className="form-textfield"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="zipCode"
          register={register}
          setValue={setValue}
        />
  <h3>Enabled Algorithm</h3>
        
    {
    data?.algorithmexport?.map((algm :any) => (
        <div className="form-group form-check checkboxcls">
          <RHFInput
              as={<Checkbox name={algm.name} defaultChecked={true}/>}
              name= {algm.name}
              type="checkbox"
              value="true"
              defaultChecked={true}
              register={register}
              setValue={setValue}
            />
  
        <label htmlFor={algm.name} className="form-check-label checkboxclslabel">{(algm.name == "Algorithm15") ? "Algorithm4b" : ((algm.name == "Algorithm16") ? "Algorithm5b" : algm.name)} : {algm.description}</label>
     </div>
      ))
      }
      
      {(isalgmdisable == 1) && <h3>Disabled Algorithm</h3> }
      {
          data?.algorithmdisableexport?.map((algm :any) => (
              <div className="form-group form-check checkboxcls">
                <RHFInput
                    as={<Checkbox name={algm.name} defaultChecked={true}/>}
                    name={algm.name}
                    type="checkbox"
                    value="true"
                    defaultChecked={true}
                    register={register}
                    setValue={setValue}
                  />
        
              <label htmlFor={algm.name} className="form-check-label checkboxclslabel">{algm.name} : {algm.description}</label>
          </div>
            ))
            }
          
 
        <br /><br />

        <Button
          type="submit"
          variant="outlined"
          size="small"
          color="primary"
          disabled={loadingdata}
          loading={loadingdata}
        >
          
          Download Report
        </Button>
				<div className="errorclsmsg" id="commondatemainerror"></div>
        <span id="datarecord">  
        {loadingdatatxt && <span> No Records Found </span>}
        {loadingdata && 
          <span>  Report Processing...Please wait</span>
          }
          </span>
		  
        <ErrorMessage error={'No Records Found'} />

      </form>
    </PanelV2>
  );
};

export default OrderReports;
