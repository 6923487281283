import * as R from 'ramda';
import * as React from 'react';

interface Props {
  index?: number
  tabs: Array<{ component: React.ComponentType<any> }>
}

function Content(props: Props) {
  const { index = 0, tabs } = props;
  return R.pipe<Array<{ component: React.ComponentType<any> }>, any, React.ReactElement<any>>(
    R.nth(index),
    ({ component: Component }: any) => <Component />
  )(tabs)
}

export default Content;
