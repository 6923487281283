import { TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import Button from '../../../components/Button';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { useGetSettingsQuery,IOAuth2Client, Maybe, useCreateOAuth2ClientMutation } from '../../../generated/graphql';

interface Props {
  oAuth2Client?: Maybe<IOAuth2Client>;
}

function OAuth2Panel(props: Props) {
  const { oAuth2Client } = props;
  const [createAPIKey, { loading, error }] = useCreateOAuth2ClientMutation();
  const query = useGetSettingsQuery();
  const handleOnClick = async () => {
  
  
    try {
      await createAPIKey();
      query.refetch();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Typography variant="caption">Client ID</Typography>

      <TextField
        id="clientId"
        name="clientId"
        type="text"
        value={oAuth2Client?.clientId}
        margin="normal"
        fullWidth
        disabled
      />

      <br />

      <br />

      <Typography variant="caption">Client Secret</Typography>

      <TextField
        id="clientSecret"
        name="clientSecret"
        type="text"
        value={oAuth2Client?.clientSecret}
        margin="normal"
        fullWidth
        disabled
      />

      <Button
        type="button"
        variant="outlined"
        size="small"
        color="primary"
        disabled={loading}
        loading={loading}
        onClick={handleOnClick}
      >
        Generate New Keys
      </Button>

      <ErrorMessage error={error} />
    </>
  );
}

export default OAuth2Panel;
