import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { formatPercentage } from '../../../lib/formatter';

interface Props {
  data: Array<any>
  checked: Array<boolean>

  onChange?(data: any, index: number): void
}

function ClassOfTradeTable(props: Props) {
  const { onChange, data = [], checked = [] } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Select</TableCell>
          <TableCell>Trade</TableCell>
          <TableCell># Flagged</TableCell>
          <TableCell>% of total flagged</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          RA.mapIndexed((row: any, index: number) => (
            <TableRow key={row.trade}>
              <TableCell>
                <Checkbox
                  checked={checked[index]}
                  onChange={() => !!onChange && onChange(row, index)}
                />
              </TableCell>
              <TableCell>{row.trade}</TableCell>
              <TableCell>{row.flagCount}</TableCell>
              <TableCell>{formatPercentage(row.flagCount / row.orderCount)}</TableCell>
            </TableRow>
          ))(data)
        }
      </TableBody>
    </Table>
  );
}

export default ClassOfTradeTable;
