import * as R from 'ramda';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';
import * as React from 'react';
import {useCreateWhatsNewHistoryMutation,useGetDashboardSettingsQuery,IWhatsNewData } from '../../generated/graphql';
import Button from '../Button';
const useStyles = makeStyles((theme: Theme) => createStyles({
  
  whatsnewtitle: {
    display: 'inline-block',
  },
  whatsnewclose: {
    display: 'inline-block',
    float: 'right',
  },
  whiteSpace: {
        whiteSpace: 'pre-wrap',
  },
  
}));

interface Values {
  url: string
}

interface Props {
  open?: boolean;

  onRequestClose?(): void;
}

function WhatsNewModal(props: Props) {
  const { open = false, onRequestClose } = props;
  const classes = useStyles();
  const query = useGetDashboardSettingsQuery();
  const { data } = query;
 query.refetch({});
  const whatsNewAllData = data?.fetchWhatsNewData?.map((ds: IWhatsNewData) => ds!) ?? [];
   const [createWhatsNewHistory] = useCreateWhatsNewHistoryMutation();
  
   
    function createMarkup(desc: any) {
        return {__html: desc};
    }
   const reviewWhatsNew = () => {//how to handle its success and failure here
        createWhatsNewHistory({});
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
        }
        window.location.reload(true);
    };
   
  

  return (
    <Dialog
      open={open}
      onClose={() => !!onRequestClose && onRequestClose()}
      fullWidth ={true}
       maxWidth ="md"
     
    >
      <form >
        <DialogTitle className={classes.whatsnewtitle}>What's New</DialogTitle>
        <DialogActions className={classes.whatsnewclose}>
          <Button
             variant="contained"
             color="primary"
             type="button"
             onClick={() => reviewWhatsNew()}
          >
            Reviewed
          </Button>

          
        </DialogActions>
        <DialogContent >
          <div>
          {
              R.map((whatsnew: IWhatsNewData) => (
              <div>
                
                    <div className={'descriptioncls'}><div dangerouslySetInnerHTML={createMarkup(whatsnew.description)}/></div>
                 
               </div>
              ))(whatsNewAllData)
            }
         
         </div>
        </DialogContent>

        
      </form>
    </Dialog>
  );
}

export default WhatsNewModal;
