import * as React from 'react';
import Table from '../../../components/Table/Table';
import { INdcAnalytics } from '../../../generated/graphql';
import * as formatter from '../../../lib/formatter';

const columns = [
  {
    title: 'NDC',
    key: 'ndc',
    dataIndex: 'ndc',
  },
  {
    title: 'Product Name',
    key: 'productName',
    dataIndex: 'productName',
  },
  {
    title: 'Average Quantity',
    key: 'averageQuantity',
    render: (analytics: INdcAnalytics) => formatter.formatFloat(analytics.averageLineQuantity),
  },
  {
    title: 'Average Total',
    key: 'averageTotal',
    render: (analytics: INdcAnalytics) => formatter.formatFloat(analytics.averageLineQuantity),
  },
  {
    title: 'Percentage of Orders',
    key: 'percentageOfOrders',
    render: (analytics: INdcAnalytics) => formatter.formatCurrency(analytics.averageLineTotal),
  },
  {
    title: 'Count',
    key: 'count',
    dataIndex: 'count',
  },
];

interface Props {
  data: Array<INdcAnalytics>;
}

function NDCTable(props: Props) {
  const { data = [] } = props;

  return (
    <Table
      data={data}
      columns={columns}
      rowKey="ndc"
    />
  );
}

export default NDCTable;
