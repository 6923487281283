import * as R from 'ramda';

export const formatPercentage = R.pipe<any, any, any>(
  R.defaultTo(0),
  (new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  })).format,
);

export const formatCurrency = R.pipe<any, any, any>(
  R.defaultTo(0),
  (new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })).format,
);

export const formatFloat = R.pipe<any, any, any>(
  R.defaultTo(0),
  (new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  })).format,
);