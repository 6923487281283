import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { DateTime } from 'luxon';
import * as React from 'react';

import { useQueryParam, useQueryParams } from 'use-query-params';
import { NumberParam, StringParam } from 'use-query-params/lib/params';
import PanelV2 from '../../../../components/Panel/PanelV2';
import SwitchLoadingErrorChildren from '../../../../components/SwitchLoadingErrorChildren';
import Table from '../../../../components/Table/Table';
import { useUploadFileModal } from '../../../../components/UploadFile/UploadFileModalContext';
import { IFile, useListCustomerFilesQuery, useListCustomerOrdersQuery } from '../../../../generated/graphql';
import ViewCustomerFileButton from './components/ViewCustomerFileButton';
import { CommaArrayParam } from '../../../../utils';

const columns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Filename',
    key: 'fileName',
    dataIndex: 'fileName',
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type.name',
    render: (file: IFile) => file.type?.name ?? '',
  },
  {
    title: 'Created At',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (file: IFile) => DateTime.fromISO(file.createdAt).toLocaleString(DateTime.DATETIME_MED),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (file: IFile) => (
      <Tooltip title="View File">
        <ViewCustomerFileButton fileId={file.id} />
      </Tooltip>
    )
  }
];

interface Props {
  id: string;
}

function CustomerFiles(props: Props) {
  const { id } = props;
  
  
 let { loading, error, data } = useListCustomerFilesQuery({ variables: { customerId: id } });
  const { open: openModal } = useUploadFileModal();
console.log(data?.customer)
  let customername='';
  let customertitle =`Files for Customer ${id}`;
  if(data?.customer?.customerName !==null){
     customername = ' : '+data?.customer?.customerName
     customertitle = customertitle+ customername
  }
  return (
    <PanelV2 title={customertitle}>
      <Toolbar>
        <Typography
          variant="h1"
          color="inherit"
          style={{ flex: 1 }}
        />

        <IconButton onClick={() => openModal(id)}>
          <UploadIcon />
        </IconButton>
      </Toolbar>

      <SwitchLoadingErrorChildren
        loading={loading}
        error={error}
      >
        <Table
          columns={columns}
          data={data?.files ?? []}
          rowKey="id"
        />
      </SwitchLoadingErrorChildren>
    </PanelV2>
  );
}

export default CustomerFiles;
