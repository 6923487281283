import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import PanelV2 from '../../../../components/Panel/PanelV2';
import { useGetMonthlyCustomerOrderAnalyticsQuery } from '../../../../generated/graphql';

interface Props {
  id: string;
}

function CustomerAnalytics(props: Props) {
  const { id } = props;
  const { loading, error, data } = useGetMonthlyCustomerOrderAnalyticsQuery({ variables: { customerId: id } });

  const monthlyOrderAnalytics = data?.monthlyCustomerOrderAnalytics ?? [];

  const dataSet = {
    datasets: [
      {
        label: 'Count',
        type: 'line',
        data: R.map((value: any) => ({
          x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
          y: value.count,
        }))(monthlyOrderAnalytics),
        backgroundColor: 'rgba(46, 204, 113, 0.25)',
      },
      {
        label: 'Total',
        type: 'line',
        data: R.map((value: any) => ({
          x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
          y: value.totalOrderTotal,
        }))(monthlyOrderAnalytics),
        backgroundColor: 'rgba(46, 204, 113, 0.25)',
      },
      {
        label: 'Average Total',
        type: 'line',
        data: R.map((value: any) => ({
          x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
          y: value.averageOrderTotal,
        }))(monthlyOrderAnalytics),
        backgroundColor: 'rgba(46, 204, 113, 0.25)',
      },
      {
        label: 'Quantity',
        type: 'line',
        data: R.map((value: any) => ({
          x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
          y: value.totalOrderQuantity,
        }))(monthlyOrderAnalytics),
        backgroundColor: 'rgba(46, 204, 113, 0.25)',
      },
      {
        label: 'Average Quantity',
        type: 'line',
        data: R.map((value: any) => ({
          x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
          y: value.averageOrderQuantity,
        }))(monthlyOrderAnalytics),
        backgroundColor: 'rgba(46, 204, 113, 0.25)',
      },
      {
        label: 'ME',
        type: 'line',
        data: R.map((value: any) => ({
          x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
          y: value.totalOrderMe,
        }))(monthlyOrderAnalytics),
        backgroundColor: 'rgba(46, 204, 113, 0.25)',
      },
      {
        label: 'Average ME',
        type: 'line',
        data: R.map((value: any) => ({
          x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
          y: value.averageOrderMe,
        }))(monthlyOrderAnalytics),
        backgroundColor: 'rgba(46, 204, 113, 0.25)',
      },
    ],
  };

  return (
    <PanelV2
      title={`Customer ${id}`}
      loading={loading}
      error={error}
    >
      <Line
        data={dataSet}
        options={{
          scales: {
            xAxes: [
              {
                type: 'time',
                time: {
                  unit: 'month',
                },
              },
            ],
          },
        }}
      />
    </PanelV2>
  );
}

export default CustomerAnalytics;
