import { AppBar, Tab, Tabs } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Layout } from '../../components/Layout';
import CustomerAnalytics from './components/Analytics/CustomerAnalytics';
import CustomerFiles from './components/Files/CustomerFiles';
import CustomerOrders from './components/Orders/CustomerOrders';

interface Props extends RouteComponentProps<{ id: string }> {

}

function Customer(props: Props) {
  const { id } = props.match.params;
  const [tab, setTab] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => setTab(value);

  const renderTabPanel = () => {
    switch (tab) {
      case 0:
        return <CustomerOrders id={id} />;
      case 1:
        return <CustomerFiles id={id} />;
      case 2:
        return <CustomerAnalytics id={id} />;
      default:
        throw new Error(`Customer tab value ${tab} not supported`);
    }
  };

  return (
    <section>
      <AppBar position="static">
      <Tabs value={tab} onChange={handleChange}>
          <Tab label="Orders" />
          <Tab label="Files" />
          <Tab label="Analytics" />
        </Tabs>
      </AppBar>

      <Layout>
        {renderTabPanel()}
      </Layout>
    </section>
  );
}

export default Customer;
