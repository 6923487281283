import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as L from 'partial.lenses';
import * as React from 'react';
import { IUser, useListUsersQuery , useCreateEmailMutation } from '../../generated/graphql';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
interface Values {
  userid: string
  orderid: string
  requestid: string
   ndc: string
   email: string
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));
interface Props {
  open?: boolean
  data?: {
    userid: string,
    orderid: string,
    requestid: string,
    ndc:string
  }
  onRequestClose(): void
}

function FlagUserModal(props: Props) {
const classes = useStyles();
  const { open = false,onRequestClose } = props;
  const [selectedEmail,setSelectedEmail] = React.useState('');
  const query = useListUsersQuery({ fetchPolicy: 'no-cache' });
   const { data } = query;
   const [createEmail, { loading, error }] = useCreateEmailMutation();
   const handleonchange = (event:any) =>{
    setSelectedEmail(event.target.value);
   }
  const handlesubmit = async () =>{
      try {
        await createEmail({
          variables: {
             input: {
              userId:props?.data?.userid,
              orderId:props?.data?.orderid,
              requestId:props?.data?.requestid,
             ndc: props?.data?.ndc, 
             email: selectedEmail
            },
          },
          update: (store, { data }) => {
            if (!data) {
              return;
            }

          },

        });
        //window.location.reload();
        if (onRequestClose) {
          onRequestClose();
        }
      } catch (err) {
        console.error(err);
      }
    
}
  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
    >
      <form >
        <DialogTitle>Send Notification</DialogTitle>

        <DialogContent>
       
          
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-dialog-native">Email</InputLabel>
            <Select required name="enabled" fullWidth margin="dense" onChange={handleonchange}>
            {data?.users?.map(databj => {
              if(props?.data?.userid != databj.userId){
                return <option value={databj.email ?? ''}>{databj?.email}</option>
              } 
                
                })
              }
             </Select>
         
         </FormControl>
          
          <ErrorMessage error={error} />
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="default"
            onClick={onRequestClose}
          >
            Cancel
          </Button>

          <Button
            type="button"
            color="primary"
            disabled={loading}
            loading={loading}
            onClick={handlesubmit}
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FlagUserModal;
