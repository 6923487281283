import {
  createStyles,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import UploadIcon from '@material-ui/icons/CloudUpload';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import ShowChart from '@material-ui/icons/ShowChart';
import OrdersIcon from '@material-ui/icons/Store';
import ReportsIcon from '@material-ui/icons/Equalizer';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import useNavigator from '../../lib/useNavigator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 4px',
    },
    drawerPaper: {
      marginTop: '4rem',
      width: 240,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerPaperClose: {
      width: 60,
    },
    list: {
      flex: 0,
    },
	active: {
      flex: 0,
    },
  }),
);

interface Props {
  children: any
}

export function NavigationDrawerProvider(props: Props) {
  const { children } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const navigator = useNavigator();
   let orderClass = "inactive";
   let reportClass = "inactive";
   let customerClass ="inactive";
   let uploadClass ="inactive";
   let settingsClass = "inactive";
   let usersClass = "inactive";
   let homeClass="inactive";
  if((window.location.href.indexOf("orders") > -1)){
  	 orderClass = "menuactive";
  }else if((window.location.href.indexOf("reports") > -1)){
  	 reportClass = "menuactive";
  }else if((window.location.href.indexOf("customers") > -1)){
  	 customerClass = "menuactive";
  }else if((window.location.href.indexOf("uploads") > -1)){
  	 uploadClass = "menuactive";
  }else if((window.location.href.indexOf("settings") > -1)){
  	 settingsClass = "menuactive";
  }else if((window.location.href.indexOf("users") > -1)){
  	 usersClass = "menuactive";
  }else{
  	homeClass = "menuactive";
  }
 
  const items = [
    {
      title: 'Dashboard',
      onClick: navigator.goToHome,
      icon: ShowChart,
	  className:homeClass
    },
    {
      title: 'Orders',
      onClick: navigator.goToOrders,
      /*onClick: () => {
        window.location.href = 'orders?sort=orderId:desc,customerId:desc,orderDate:desc'
      },
      */
      icon: OrdersIcon,
	  className:orderClass
    },
    {
      title: 'Customers',
      onClick: navigator.goToCustomers,
      icon: PeopleIcon,
	  className:customerClass
    },
    {
      title: 'Reports',
      onClick: navigator.goToReports,
      icon: ReportsIcon,
	  className:reportClass
    },
   
    {
      title: 'Uploads',
      onClick: navigator.goToUploads,
      icon: UploadIcon,
	  className:uploadClass
    },
    {
      title: 'Users',
      onClick: navigator.goToUsers,
      icon: PeopleIcon,
	  className:usersClass
    },
    {
      title: 'Settings',
      onClick: navigator.goToSettings,
      icon: SettingsIcon,
	  className:settingsClass
    },
    {
      title: 'Logout',
      onClick: async () => {

       // localStorage.removeItem('buildversionupdate');
        await Auth.signOut();
      },
      icon: LogoutIcon,
    },
  ];

  return (
    <>
      {children}

      <Drawer
        open={open}
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
      >
        <div
          className={classes.drawerHeader}
        >
          <IconButton
            onClick={() => setOpen(!open)}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List style={{ padding: 0 }}>
          {
            items.map(({ onClick, icon: Icon, title,className }) => (
              <React.Fragment key={title}>
                <ListItem button onClick={onClick} className={className}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText  primary={title} />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))
          }
        </List>
      </Drawer>
    </>
  );
}
