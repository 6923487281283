import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import CreateWebhookModal from './CreateWebhookModal';

export interface ICreateWebhookModalContext {
  open(): void;
}

export const CreateWebhookModalContext = React.createContext<Maybe<ICreateWebhookModalContext>>(null);

interface Props {
  children: any;
}

export const CreateWebhookModalProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <CreateWebhookModalContext.Provider
      value={{
        open: () => setOpen(true),
      }}
    >
      {children}

      {
        open && (
          <CreateWebhookModal
            open={open}
            onRequestClose={() => setOpen(false)}
          />
        )
      }
    </CreateWebhookModalContext.Provider>
  );
};

export const useCreateWebhookModal = () => {
  const context = React.useContext(CreateWebhookModalContext);

  if (!context) {
    throw new Error('CreateWebhookModalContext missing');
  }

  return context;
};
