import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as L from 'partial.lenses';
import * as React from 'react';
import { IUser, ListUsersDocument, useCreateUserMutation } from '../../generated/graphql';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
interface Values {
  name: string
  email: string
  title: string
   role: string
   enabled: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));
interface Props {
  open?: boolean

  onRequestClose(): void
}

function AddUserModal(props: Props) {
const classes = useStyles();
  const { open = false, onRequestClose } = props;
  const [createUser, { loading, error }] = useCreateUserMutation();
var roles = [
  {
    "key": 'admin',
    "value": "Admin"
  },
  {
    "key": 'member',
    "value": "Member"
  }
];
var userStatus = [
  {
    "key": 'true',
    "value": "Enabled"
  },
  {
    "key": 'false',
    "value": "Disabled"
  }
];
  const formik = useFormik<Values>({
    initialValues: {
      name: '',
      email: '',
      title: '',
      role: '',
      enabled: true,
    },
    onSubmit: async (values) => {
    var statusval = String(values.enabled);
     console.log(statusval);
     let userStatus = true;
     if(statusval == 'false'){
       userStatus = false;
     }
      try {
        await createUser({
          variables: {
             input: {
             title:values.title,
             name:values.name,
             email:values.email,
             role: values.role, 
             enabled: userStatus
            },
          },
          update: (store, { data }) => {
            if (!data) {
              return;
            }

            const d = store.readQuery({
              query: ListUsersDocument,
            });

            store.writeQuery({
              query: ListUsersDocument,
              data: L.modify('users', (users: Array<IUser>) => [data.createUser, ...users], d),
            });
          },

        });
        window.location.reload();
        if (onRequestClose) {
          onRequestClose();
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Add New User</DialogTitle>

        <DialogContent>
        <FormControl className={classes.formControl}>
          <TextField
            name="name"
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            onChange={formik.handleChange}
            fullWidth
            required
          /></FormControl>
          <FormControl className={classes.formControl}>
          <TextField
            name="email"
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            onChange={formik.handleChange}
            fullWidth
            required
          />
          </FormControl>
          <FormControl className={classes.formControl}>
          <TextField
            name="title"
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            onChange={formik.handleChange}
            fullWidth
            required
          />
          </FormControl>
          <FormControl className={classes.formControl}>
        <InputLabel htmlFor="demo-dialog-native">Role<span>*</span></InputLabel>
        <Select onChange={formik.handleChange} native input={<Input id="demo-dialog-native" />} required name="role" fullWidth margin="dense">
            {roles.map((e, key) => {
                return <option key={key} value={e.key}>{e.value}</option>;
            })}
         </Select>
         </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-dialog-native">User Status<span>*</span></InputLabel>
            <Select onChange={formik.handleChange} native input={<Input id="demo-dialog-native" />} required name="enabled" fullWidth margin="dense">
                {userStatus.map((e, key) => {
                    return <option key={key} value={e.key}>{e.value}</option>;
                })}
             </Select>
         
         </FormControl>
          
          <ErrorMessage error={error} />
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="default"
            onClick={onRequestClose}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={loading}
            loading={loading}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddUserModal;
