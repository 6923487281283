import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import ImportModal from './ImportModal';

export interface IImportModalContext {
  open(): void;
}

export const ImportModalContext = React.createContext<Maybe<IImportModalContext>>(null);

interface Props {
  children: any;
}

export const ImportModalProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <ImportModalContext.Provider
      value={{
        open: () => setOpen(true),
      }}
    >
      {children}

      {
        open && (
          <ImportModal
            open={open}
            onRequestClose={() => setOpen(false)}
          />
        )
      }
    </ImportModalContext.Provider>
  );
};

export const useImportModal = () => {
  const context = React.useContext(ImportModalContext);

  if (!context) {
    throw new Error('ImportModalContext missing');
  }

  return context;
};
