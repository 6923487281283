import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const getStyles = makeStyles((theme: Theme) => createStyles({
    form: {
      margin: theme.spacing(),
    },
    actions: {
      display: 'flex',
    },
    clearFlag: {
      flex: 1,
    },
    flagcls:{
      marginLeft:'22px'
    },
     markassuscls:{
      color:'red'
    },
	markassusordercls:{
      color:'red',
	  marginLeft: '13px',
    },
    commonmsgcls:{
      backgroundColor: 'transparent',
      border: 'none',
      margin: '12px',
      outline: '0',
      borderBottom: '1px solid black',
      width: '90%',
    },
    filecls:{
      padding:'10px',
    },
    orderflagclsnew:{
      width:'25%'
    },
    orderboxcls:{
      width:'80%'
    },
    orderflagcls:{
      width:'12%'
    },
    submitcls:{
      padding:'24px',
      textAlign:'center',
    },
    btncls:{
      color:' #fff',
      backgroundColor: '#204d74',
      borderColor: '#122b40',
      display: 'inline-block',
      marginBottom: '0',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      cursor: 'pointer',
      backgroundImage: 'none',
      border: '1px solid transparent',
      padding: '6px 12px',
      fontSize: '14px',
      lineHeight: '1.42857143',
      borderRadius: '4px',
    },
    errorcls:{
      color:'red',
    },
    errorclsmsg:{
      color:'red',
      marginLeft: '20px',
    },
    list: {
      overflowY: 'auto',
      flex: 1,
      padding: 0,
    },
    listText: {
      maxWidth: '100%',
      overflowWrap: 'break-word',
      padding: 0,
    },
    subheader: {
      backgroundColor: '#ffffff',
      display:'block',
    },
    btnclssave:{
      color:' #fff',
      backgroundColor: '#204d74',
      borderColor: '#122b40',
      display: 'inline-block',
      marginBottom: '0',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      cursor: 'pointer',
      backgroundImage: 'none',
      border: '1px solid transparent',
      padding: '6px 12px',
      fontSize: '14px',
      lineHeight: '1.42857143',
      borderRadius: '4px',
      float: 'left',
      marginLeft: '-13px',
    },
    btnclscancel:{
      color:' #000',
      display: 'inline-block',
      marginBottom: '0',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      cursor: 'pointer',
      backgroundImage: 'none',
      border: '1px solid transparent',
      padding: '6px 12px',
      fontSize: '14px',
      lineHeight: '1.42857143',
      borderRadius: '4px',
      float: 'left',
      marginLeft: '20px',
    },
    paper: {
    backgroundColor: '#fff',
    width: '50%',
    top: '25%',
    left: '25%',
    position: 'absolute'
  },
   
}));

export const renderRowCell = (source: any,order:any) => {

 let nt =0;
  if (order.clearFlag && order.ndcflagged === 'false') {
    nt++;
  }
  if (order.clearFlag && order.ndcflagged === 'true' && order.ndccleared ==='true') {
    nt++;
  }
  if (!order.flagged && order.ndcflagged === 'true' && order.ndccleared === 'true') {
    nt++;
  }
 if(order.issuspiciousorder == true &&  nt==0 && source.cleared ==='false' && source.algorithm !== ''){
       return 'pinkclass';
    }
    if ((source.algorithm !== '' && source.cleared ==='false')) {
      return 'redclass';
    }
  
    return '';
};


export const renderFlagCell = (source: any,order:any) => {


 if(order.issuspiciousorder == true && source.issuspiciousnotes == 'true' && source.cleared ==='false'){
          return 'pinkclass';
    }
    if ((source.algorithm !== '' && source.cleared ==='false')) {
	
      return 'redclass';
    }
  
    return '';
};

export const renderOrdersCell = (source: any) => {
	if(source.flaggedorder.length > 0 && !source.cleared){
		return 'pinkclass';
	}
	if(!source.cleared){
		return 'redclass';
	}
    return '';
};


