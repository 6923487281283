import * as R from 'ramda';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useNavigator from '../../lib/useNavigator';

interface ITab {
  name: string
}

const sanitizeTabNames = R.pipe<Array<ITab>, Array<string>, Array<string>>(
  R.map(R.prop('name')),
  R.map(R.toLower),
);

const sanitizeHash = R.pipe<string, string, string>(
  R.replace('#', ''),
  unescape,
);

function useTab({ hash, tabs }: { hash: string, tabs: Array<{ name: string }> }) {
  const [index, setIndex] = useState(0);
  const { goTo } = useNavigator();

  useEffect(() => {
    const tab = sanitizeHash(hash);
    const tabNames = sanitizeTabNames(tabs);
    const idx = R.findIndex(R.equals(tab))(tabNames);
    R.when(R.complement(R.equals(-1)), setIndex)(idx);
  }, [hash, tabs]);

  return {
    index,
    onChange(event: React.ChangeEvent<any>, idx: number) {
      setIndex(idx);
      const tab = R.nth(idx)(tabs);
      if (tab) {
        goTo(`/#${tab.name.toLowerCase()}`);
      }
    }
  }
}

export default useTab;
