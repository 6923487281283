import { IconButton, TableCell, TableRow, Toolbar, Tooltip, Typography } from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';

import { withStyles } from '@material-ui/core/styles';
import UploadIcon from '@material-ui/icons/CloudUpload';
import RefreshIcon from '@material-ui/icons/Refresh';
import { DateTime } from 'luxon';
import * as React from 'react';

import { useQueryParam, useQueryParams } from 'use-query-params';

import { NumberParam, StringParam } from 'use-query-params/lib/params';
import { useImportModal } from '../../components/ImportModal/ImportModalContext';
import { Layout } from '../../components/Layout';
import PanelV2 from '../../components/Panel/PanelV2';
import Table from '../../components/Table/Table';
import { IRequest, IUser, useListRequestsQuery } from '../../generated/graphql';

import ViewAttachmentButton from './ViewAttachmentButtonNote';

import Button from '@material-ui/core/Button';


const columns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'requestId',
  },
  {
    title: 'User',
    key: 'user',
    render(user: IUser) {
      return user.name!;
    },
  },
  {
    title: '',
    key: 'a',
  },
  {
    title: '',
    key: 'b',
  },
  {
    title: 'Created At',
    key: 'createdAt',
    render(request: IRequest) {
      return DateTime.fromMillis(parseInt(request.createdAt!, 10)).toLocaleString(DateTime.DATETIME_MED);
    },
  },
];





function Uploads() {

  const [page, setPage] = useQueryParam<number>('page', NumberParam);
  const [limit, setLimit] = useQueryParam<number>('limit', NumberParam);
  const { open: openModal } = useImportModal();
  const query = useListRequestsQuery({
  variables: {
      limit,
      page
    },
    fetchPolicy: 'no-cache',
    pollInterval: 12 
  });
  const { loading, error, data } = query;
  query.refetch();

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
  return (
    <Layout>
      
      <PanelV2
        title="Imports"
        loading={loading}
        error={error}
      >
        <Toolbar> 
          <Typography
            variant="h1"
            color="inherit"
            style={{ flex: 1 }}
          />
          <Tooltip title="Refresh">
            <IconButton onClick={() => query.refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => openModal()}>
            <UploadIcon />
          </IconButton>
        </Toolbar>

        <Table
          columns={columns}
          data={data?.requests?.data ?? []}
           pagination={data?.requests?.pagination}
            onChangePage={setPage}
            onChangeRowsPerPage={setLimit}
          rowKey="requestId"
          renderAdditionalRow={(request: IRequest) => request.statuses?.map((status, index) => (
		  
            <TableRow key={`${request.requestId}-${status!.status}-${index}`}>
             
			    <TableCell>{(status.type=='UPLOAD' && status!.status && status!.status == 'STARTING' &&
                      !!request.attachment) && <ViewAttachmentButton requestId={request.requestId!} />
                    }</TableCell>
              <TableCell />
             
              <TableCell>{status!.type}</TableCell>
			  
              <TableCell>{status!.status}{(status!.status && status!.status == 'FAILED') && <HtmlTooltip
            title={
              <React.Fragment>
			  {
				(status!.message && status!.message.includes('Transaction query already complete, run with'))
				  ?
				  'Please contact NavigateSOM admin for more details about this error.'
				  :
				  (status!.message && status!.message.includes('date/time field value out of range'))
					?
					'Order Date is incorrect format.'
					:
					(status!.message && status!.message.includes('new row for relation'))
					?
					'NDC is incorrect format. All NDCs should have 11 characters.'
					:
					(status!.message && status!.message.includes('violates not-null constraint'))
					?
					'No data fields in any columns can be blank or Null or the CSV import will fail'
					:
					(status!.message && status!.message.includes("Cannot read property 'replace' of undefined"))
					?
					'No data fields in any columns can be blank or Null or the CSV import will fail.'
					:
					((status!.message && status!.message.includes("input syntax for integer:")))
					?
					'Quantity field cannot be blank and it should be in the integer format or the CSV import will fail.'
					:
					((status!.message && status!.message.includes("input syntax for type timestamp")))
					?
					'Order Date field cannot be blank and it should be in the mm-dd-yyyy format or the CSV import will fail.'
					:
					((status!.message && status!.message.includes("input syntax for type double precision")))
					?
					'Line Total field cannot be blank and it should be in the decimal format or the CSV import will fail.'
					:
					status!.message
				}
			 
              </React.Fragment>
            }
            >
            <Button><HelpIcon/></Button>
          </HtmlTooltip>}</TableCell>
              <TableCell>{DateTime.fromMillis(parseInt(status!.createdAt!, 10)).toLocaleString(DateTime.DATETIME_MED)}</TableCell>
			 
            </TableRow>
          ))}
        />
      </PanelV2>
    </Layout>
  );
}

export default Uploads;
