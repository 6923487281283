import { useHistory } from 'react-router-dom';

function useNavigator() {
  const history = useHistory();

  
 
  const push = (route: string) => history.push(route);

  return {

    goTo(path: string) {
      push(path);
      
    },
    goBack() {
    
      history.goBack();
    },
    goToHome() {
      localStorage.removeItem('filterInLocalStorage');
      push('/');
    },
    goToUsers() {
      push('/users');
    },
    goToUploads() {
      push('/uploads');
    },
    goToCustomers() {
      push('/customers');
    },
    goToCustomer(id: string) {
      push(`/customers/${id}`);
    },
    goToOrders() {
      push('/orders');
    },
    goToReports() {
      push('/reports');
    },
    goToSettings() {
      push('/settings');
    },
  };
}

export default useNavigator;
