import * as React from 'react';
import Table from '../../../components/Table/Table';
import { IFlaggedClassOfTrade } from '../../../generated/graphql';

const columns = [
  {
    title: 'Customer ID',
    key: 'customerId',
    dataIndex: 'customerId',
  },
  {
    title: 'Trade',
    key: 'trade',
    dataIndex: 'trade',
  },
  {
    title: 'Order ID',
    key: 'orderId',
    dataIndex: 'orderId',
  },
  {
    title: 'Quantity',
    key: 'orderQuantity',
    dataIndex: 'orderQuantity',
  },
  {
    title: 'Trade Average Quantity',
    key: 'tradeAverageQuantity',
    render: (item: IFlaggedClassOfTrade) => item.tradeAverageQuantity!.toFixed(2),
  },
  {
    title: 'Date',
    key: 'date',
    render: (item: IFlaggedClassOfTrade) => `${item.orderMonth}/${item.orderYear}`,
  },
];

interface Props {
  data?: Array<IFlaggedClassOfTrade>;
}

function FlaggedClassOfTradeTable(props: Props) {

  const { data = [] } = props;
if(data.length === 0){
    return (
  
    <div>No Records Found</div>
    
  );
}else{
  return (
  
    <Table
      data={data}
      columns={columns}
      rowKey="customerId"
    />
    
  );
  }
  
}

export default FlaggedClassOfTradeTable;
