import { createStyles, Dialog, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { IOrderLineItem } from '../../generated/graphql';
import OrderItemsTable from './components/OrderItemsTable';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogPaper: {
    maxWidth: '90vw',
    overflow: 'auto',
  },
  title: {
    position: 'sticky',
    left: 0,
  },
}));

interface Props {
  open?: boolean;
  orderItems: Array<IOrderLineItem>;
  orderId: string;
  requestId: string;

  onRequestClose(): void;
}

function OrderItemsTableModal(props: Props) {
  const { open = false, onRequestClose, orderItems = [], orderId = "" ,requestId =""} = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={classes.title}>
        Order Items
      </DialogTitle>

      <OrderItemsTable orderItems={orderItems} orderId={orderId}  requestId={requestId}/>
    </Dialog>
  );
}

export default OrderItemsTableModal;
