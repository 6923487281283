import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import download from 'downloadjs';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import Button from '../../../../components/Button';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import PanelV2 from '../../../../components/Panel/PanelV2';
import useDownloadReport from '../../../../lib/useDownloadReport';

interface Values {
  startDate?: string;
  endDate?: string;
  flagged?: string;
  customerName?: string;
  zipCode?: string;
  minME?: string;
  maxME?: string;
  ndc?: string;
}

interface Props {

}

const CustomerReports = (props: Props) => {
  const [downloadReport, { loading, error }] = useDownloadReport({ type: 'customers' });
  const { handleSubmit, register, setValue } = useForm<Values>();

  const onSubmit = async (values: Values) => {
    try {
      const res = await downloadReport(values);

      download(await res.blob(), 'customer-report.csv', 'text/csv');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PanelV2
      title="Customer Reports"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFInput
          as={
            <TextField
              label="Start Date (yyyy-mm-dd)"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="startDate"
          register={register}
          setValue={setValue}
        />

        &nbsp;

        <RHFInput
          as={
            <TextField
              label="End Date (yyyy-mm-dd)"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="endDate"
          register={register}
          setValue={setValue}
        />

        <br /><br />

        <FormControl component="fieldset">
          <FormLabel component="legend">Flagged Status</FormLabel>
          <RHFInput
            as={
              <RadioGroup>
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value="true" control={<Radio />} label="Flagged" />
                <FormControlLabel value="false" control={<Radio />} label="Non-Flagged" />
              </RadioGroup>
            }
            type="radio"
            name="flagged"
            register={register}
            setValue={setValue}
          />
        </FormControl>

        <br /><br />

        <RHFInput
          as={
            <TextField
              label="Customer Name"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="customerName"
          register={register}
          setValue={setValue}
        />

        <br /><br />

        <RHFInput
          as={
            <TextField
              label="Zip Code"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="zipCode"
          register={register}
          setValue={setValue}
        />

        <br /><br />

        <section>
          <RHFInput
            as={
              <TextField
                label="Min ME"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            }
            type="number"
            name="minME"
            register={register}
            setValue={setValue}
          />

          &nbsp;

          <RHFInput
            as={
              <TextField
                label="Max ME"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            }
            type="number"
            name="maxME"
            register={register}
            setValue={setValue}
          />
        </section>

        <br /><br />

        <RHFInput
          as={
            <TextField
              label="NDC"
              InputLabelProps={{
                shrink: true,
              }}
            />
          }
          type="text"
          name="ndc"
          register={register}
          setValue={setValue}
        />

        <br /><br />

        <Button
          type="submit"
          variant="outlined"
          size="small"
          color="primary"
          disabled={loading}
          loading={loading}
        >
          Download Report
        </Button>

        <ErrorMessage error={error} />
      </form>
    </PanelV2>
  );
};

export default CustomerReports;
