import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import WhatsNewModalHeader from './WhatsNewModalHeader';

export interface IWhatsNewModalHeaderContext {
  open(): void;
}

export const WhatsNewModalHeaderContext = React.createContext<Maybe<IWhatsNewModalHeaderContext>>(null);

interface Props {
  children: any;
}

export const WhatsNewModalHeaderProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <WhatsNewModalHeaderContext.Provider
      value={{
        open: () => setOpen(true),
      }}
    >
      {children}

      {
        open && (
          <WhatsNewModalHeader
            open={open}
            onRequestClose={() => setOpen(false)}
          />
        )
      }
    </WhatsNewModalHeaderContext.Provider>
  );
};

export const useWhatsNewModalHeader = () => {
  const context = React.useContext(WhatsNewModalHeaderContext);

  if (!context) {
    throw new Error('WhatsNewModalHeaderContext missing');
  }

  return context;
};
