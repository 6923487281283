import { DateTime } from 'luxon';
import * as React from 'react';
import Table from '../../../components/Table/Table';
import { ICustomer, IPagination, Maybe, useListCustomersQuery } from '../../../generated/graphql';
import { ISort } from '../../../types';
import { useQueryParam } from 'use-query-params';
import { NumberParam, StringParam } from 'use-query-params/lib/params';
import { CommaArrayParam, decodeSort, encodeSort } from '../../../utils';
const columns = [
  {
    title: 'ID',
    key: 'customerId',
    dataIndex: 'customerId',
    sortable: true,
  },
  {
    title: 'Name',
    key: 'customerName',
    dataIndex: 'customerName',
    sortable: true,
  },
  {
    title: 'Last Ordered At',
    key: 'ordered_at',
    dataIndex: 'lastOrderedAt',
    render: (customer: ICustomer) => DateTime.fromISO(customer.lastOrderedAt!).toLocaleString(DateTime.DATE_MED),
    sortable: true,
  }
];

interface Props {
  datacustomer: Array<ICustomer>
  pagination?: Maybe<IPagination>
  sorts?: Array<ISort>

  onClickRow?(customer: ICustomer): void

  onChangePage?(page: number): void

  onChangeRowsPerPage?(value: number): void

  onSort?(sort: ISort): void
}

const CustomersTable = (props: Props) => {
  const {  pagination, onClickRow, onChangePage, onChangeRowsPerPage } = props;
  const [sort, setSort] = useQueryParam('sort', CommaArrayParam);
  const [page, setPage] = useQueryParam<number>('page', NumberParam);
  const [limit, setLimit] = useQueryParam<number>('limit', NumberParam);
  const [q, setQ] = useQueryParam<string>('q', StringParam);
  const { loading, error, data } = useListCustomersQuery({
    variables: {
      q,
      limit,
      page,
      sort,
    },
  });
  return (
    <Table
      data={data?.customers?.data ?? []}
      pagination={pagination}
      columns={columns}
      onClickTableRow={(customer: ICustomer) => onClickRow?.(customer)}
      onSort={(s: ISort) => setSort(encodeSort(s, sort))}
        sorts={decodeSort(sort)}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      tableClass="ordersTable"
    />
  );
};

export default CustomersTable;
