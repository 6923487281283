import { IconButton, Toolbar, Tooltip, Typography, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import FlagIcon from '@material-ui/icons/Flag';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { DateTime } from 'luxon';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState ,useEffect } from 'react';
import { useQueryParam, useQueryParams } from 'use-query-params';
import { NumberParam, StringParam } from 'use-query-params/lib/params';
import DateRangeFilter from '../../components/DateRangeFilter/DateRangeFilter';
import { Layout } from '../../components/Layout';
import { useNotesDrawer } from '../../components/NotesDrawer/NotesDrawerContext';
import { useOrderItemsTableModal } from '../../components/OrderItemsTableModal/OrderItemsTableModalContext';
import PanelV2 from '../../components/Panel/PanelV2';
import Searchbar from '../../components/Searchbar/Searchbar';
import SwitchLoadingErrorChildren from '../../components/SwitchLoadingErrorChildren';
import Table from '../../components/Table/Table';
import { IOrder, useListOrdersQuery } from '../../generated/graphql';
import ClearFlagIcon from '../../icons/ClearFlag';
import WarningIcon from '@material-ui/icons/Warning';
import * as formatter from '../../lib/formatter';
import { ISort, } from '../../types';
import { CommaArrayParam, decodeSort, encodeSort } from '../../utils';
import Button from '@material-ui/core/Button';

const columns = [
  {
    title: 'Order ID',
    key: 'orderId',
    dataIndex: 'orderId',
    sortable: true,
    sort_order: "desc"
  },
  {
    title: 'Customer ID',
    key: 'customerId',
    dataIndex: 'customerId',
    sortable: true,
  },
  {
    title: 'Customer Name',
    key: 'customerName',
    dataIndex: 'customerName',
    sortable: true,
    
  },
  {
    title: 'Trade',
    key: 'trade',
    dataIndex: 'trade',
    sortable: true,
  },
  {
    title: 'Quantity',
    key: 'orderQuantity',
    dataIndex: 'orderQuantity',
    sortable: true,
    render: (order: IOrder) => formatter.formatFloat(order.orderQuantity),
  },
  {
    title: 'Total',
    key: 'orderTotal',
    dataIndex: 'orderTotal',
    sortable: true,
    render: (order: IOrder) => formatter.formatFloat(order.orderTotal),
  },
  {
    title: 'ME',
    key: 'orderMe',
    dataIndex: 'orderMe',
    sortable: true,
    render: (order: IOrder) => formatter.formatFloat(order.orderMe),
  },
  {
    title: 'Date',
    key: 'orderedAt',
    dataIndex: 'orderedAt',
    sortable: true,
    render: (order: IOrder) => DateTime.fromSQL(order.orderedAt!.substring(0, 10)).toLocaleString(DateTime.DATE_MED),
  },
];

function Orders() {
  const [sort, setSort] = useQueryParam('sort', CommaArrayParam);
  const [page, setPage] = useQueryParam<number>('page', NumberParam);
  const [limit, setLimit] = useQueryParam<number>('limit', NumberParam);
  const [dateRange, setDateRange] = useQueryParams({ start: StringParam, end: StringParam });
  const [q, setQ] = useQueryParam<string>('q', StringParam);
  const [algorithm] = useQueryParam<string>('algorithm', StringParam);
  let [filtersettings] = useQueryParam<string>('filtersettings', StringParam);
let titilefield:any ='Orders';
  if(algorithm !==undefined){
    titilefield = (algorithm === '1') ? 'Algorithm1: Order over quantity average' : ((algorithm === '2') ? 'Algorithm2: Order over cost average' : ((algorithm === '3') ? 'Algorithm3: NDC frequency over tolerance level' :((algorithm === '4') ? 'Algorithm4: Order over customer\'s monthly family average' :((algorithm === '5') ? 'Algorithm5: Order over customer\'s monthly cost average' :((algorithm === '6') ? 'Algorithm6: Order over customer\'s monthly trade average':((algorithm === '8') ? 'Algorithm8: Order over Morphine Equivalency (ME) average' :((algorithm === '1b') ? 'Algorithm1b: NDC over quantity average' :((algorithm === '2b') ? 'Algorithm2b: NDC over cost average' :((algorithm === '4b') ? 'Algorithm4b: NDC over customer\'s family average' :((algorithm === '5b') ? 'Algorithm5b: NDC over customer\'s  quantity average' : ((algorithm === '9') ? 'Algorithm9: Line items with already existing suspicious or NDC flags' : 'Algorithm6b:  NDC over customer\'s monthly trade average')))))))))));
  }
 
   const [selectedOption, setSelectedOption] = useState(filtersettings);
   useEffect(function persistForm() {
    if( filtersettings === undefined ){
	 console.log(filtersettings)
	  filtersettings ='ALL';
      setSelectedOption(filtersettings);
      }else{
	  setSelectedOption(filtersettings);
	  }
  },[filtersettings]);
  var orderssort = [
  {
    "key": "notflag",
    "value": "Clear - Not Flagged"
  },
  {
    "key": "flag",
    "value": "Flagged"
  },
  {
    "key": "cleared",
    "value": "Cleared"
  },
  {
    "key": "suspicious",
    "value": "Suspicious"
  }
];
  const query = useListOrdersQuery({
    variables: {
      q,
      limit,
      page,
      sort,
      ...dateRange,
      algorithm,
	  filtersettings
    },
    fetchPolicy: 'no-cache'
  });
  const { loading, error, data } = query;
  query.refetch();
  
  const { open: openDrawer } = useNotesDrawer();
  const { open: openModal } = useOrderItemsTableModal();
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
 const onChange = async(event: React.ChangeEvent<HTMLSelectElement>) => {
   setSelectedOption(event.target.value);
    try {
	let param ='filtersettings';
    var url = window.location.href;  
	   if(!url.includes('?')){
            url += '?' + param + '=' + event.target.value;
        }else if(!url.includes(param)){
            url += '&' + param + '=' + event.target.value;
        }else {
            let paramStartIndex = url.search(param);
            let paramEndIndex = url.indexOf('&', paramStartIndex);
            if (paramEndIndex == -1){
                paramEndIndex = url.length;
            }
            let brands = url.substring(paramStartIndex, paramEndIndex);
    
            url = url.replace(brands, param + '=' + event.target.value);
        }
	
	window.location.href = url;
   } catch (err) {
     console.error(err);
   }
 };
const clearedflag = (order:IOrder) => {
    
  if (order.flaggedexist) {
    return true;
  }
  
  
  return false;
};


  const renderFlagIcon = (order: IOrder) => {
    console.log(order)
    switch (true) {
      
		case (clearedflag(order)):
        return (
          <Tooltip title="Cleared">
            <IconButton
              onClick={() => openDrawer(order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
            >
              <ClearFlagIcon />
            </IconButton>
          </Tooltip>
        );
		case (order.issuspiciousorder):
        return (
          <Tooltip title="Suspicious Flag">
            <IconButton
              onClick={() => openDrawer(order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
            >
              <WarningIcon />
            </IconButton>
          </Tooltip>
        ); 
      case (order.flagged || (order.ndcflagged === 'true')):
        return (
          <Tooltip title="Flagged">
            <IconButton
              onClick={() => openDrawer(order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
            >
              <FlagIcon />
            </IconButton>
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Clear">
            <IconButton
              onClick={() => openDrawer(order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
        );
    }
  };

  // Loading state
  if (loading) {
    return <CircularProgress />;
  }
 

  return (
    
    <Layout>
	
      <PanelV2 title={titilefield}>
        <Toolbar>
		<small className={'flaggedicon'}>
		{(selectedOption == 'flag') && <FlagIcon />}
		  {(selectedOption == 'cleared') && <ClearFlagIcon />}
		  {(selectedOption == 'notflag') && <CheckIcon />}
		  {(selectedOption == 'suspicious') &&  <WarningIcon />}</small>
		   &nbsp;&nbsp;<div className={'dashboard-filterbox'}>
         <select  value={selectedOption} onChange={onChange} >
          <option value="ALL">ALL</option>
          {orderssort.map((e, key) => {
                return <option key={key} value={e.key}>{e.value}</option>;
            })}
         </select>
      </div>
          <Typography
            variant="h1"
            color="inherit"
            style={{ flex: 1 }}
          />
		  
          <Searchbar
            value={q}
            onSearch={setQ}
          />
          <HtmlTooltip
            title={
              <React.Fragment>
                {"Search includes: Order ID, Customer ID, Customer Name, Trade"}
              </React.Fragment>
            }
            >
            <Button><HelpIcon/></Button>
          </HtmlTooltip>
          <Tooltip title="Filter Date">
            <DateRangeFilter
              selectionRange={{
                startDate: dateRange.start ? DateTime.fromSQL(dateRange.start).toJSDate() : undefined,
                endDate: dateRange.end ? DateTime.fromSQL(dateRange.end).toJSDate() : undefined,
              }}
              onClose={({ startDate, endDate }: any) => {
                setDateRange({
                  start: startDate ? DateTime.fromJSDate(startDate).toSQLDate() : undefined,
                  end: endDate ? DateTime.fromJSDate(endDate).toSQLDate() : undefined,
                });
              }}
            />
          </Tooltip>
        </Toolbar>

        <SwitchLoadingErrorChildren
          loading={loading}
          error={error}
        >
        
          <Table
            columns={[
              {
                title: 'Flagged',
                key: 'flaggedexist',
                dataIndex: 'id',
                render: (order: IOrder) => renderFlagIcon(order),
              },
              ...columns,
              {
                title: 'Actions',
                key: 'actions',
                render: (order: IOrder) => (
                  <Tooltip title="View Order Items">
                    <IconButton
                      onClick={() => openModal(order.lineItems!,order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                )
              },
            ]}
            data={data?.orders?.data ?? []}
            pagination={data?.orders?.pagination}
            onChangePage={setPage}
            onChangeRowsPerPage={setLimit}
            onSort={(s: ISort) => setSort(encodeSort(s, sort))}
            sorts={decodeSort(sort)}
            //rowKey="orderId"
            tableClass="ordersTable"
           
          />
        </SwitchLoadingErrorChildren>
      </PanelV2>
    </Layout>
  );
}

export default Orders;
