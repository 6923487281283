import { IconButton } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ApolloError } from 'apollo-client';
import * as React from 'react';
import { useNotification } from '../../../../../components/Notification/NotificationProvider';
import { useGetCustomerFileSignedUrlMutation } from '../../../../../generated/graphql';

interface Props {
  fileId: string;
  forwardedRef?: any;
}

function ViewCustomerFileButton(props: Props) {
  const { fileId, forwardedRef } = props;
  const [getCustomerFileSignedUrl, { loading }] = useGetCustomerFileSignedUrlMutation({ variables: { id: fileId } });
  const { setMessage } = useNotification();

  const handleOnClick = async () => {
    try {
      const { data } = await getCustomerFileSignedUrl();
      window.open(data!.getFileSignedUrl!, '_blank', 'rel="noopener noreferrer"');
    } catch (err) {
      console.error(err);
      setMessage((err as ApolloError).graphQLErrors[0].message);
    }
  };

  return (
    <IconButton
      aria-label="view file"
      disabled={loading}
      onClick={handleOnClick}
      ref={forwardedRef}
    >
      <OpenInNewIcon />
    </IconButton>
  );
}

export default React.forwardRef<Props, any>((props, ref) => <ViewCustomerFileButton {...props} forwardedRef={ref} />);
