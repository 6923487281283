import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import ErrorMessage from './ErrorMessage/ErrorMessage';

export interface Props {
  loading?: boolean;
  error?: any;
  children: any;
}

const SwitchLoadingErrorChildren = (props: Props) => {
  const { loading, error, children } = props;

  switch (true) {
    case loading:
      return <CircularProgress />;
    case error:
      return <ErrorMessage error={error} />;
    default:
      return children;
  }
};

export default SwitchLoadingErrorChildren;
