import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as L from 'partial.lenses';
import { FormHelperText } from '@material-ui/core';
import * as R from 'ramda';
import * as React from 'react';
import { Maybe, IUser, useListUsersQuery,useUpdateUserRoleMutation } from '../../generated/graphql';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

interface Values {
  title: string
  name: string
  role: string
  id: string
  enabled: boolean
}

interface Props {
  id?: Maybe<string>;
  title: string;
  name: string;
  email: string;
   role: string;
   enabled: boolean;
   open?: boolean

  onRequestClose?(): void
}

function EditUserModal(props: Props) {
 const classes = useStyles();
   const query = useListUsersQuery({ fetchPolicy: 'no-cache' });
        const {  data } = query;
const { id, open = false, onRequestClose,title,name,email,role,enabled } = props;
  const userId:string = String(id);
  const namex:string = String(name);
  const titlex:string = String(title);
  const emailx:string = String(email);
  const rolex:string = String(role);
  const enabledx:boolean = Boolean(enabled);
  const [updateUserRole, { loading, error }] = useUpdateUserRoleMutation();
var roles = [
  {
    "key": 'admin',
    "value": "Admin"
  },
  {
    "key": 'member',
    "value": "Member"
  }
];
var userStatus = [
  {
    "key": 'true',
    "value": "Enabled"
  },
  {
    "key": 'false',
    "value": "Disabled"
  }
];
  const formik = useFormik<Values>({
    initialValues: {
      name: namex,
      title: titlex,
      id: userId,
      role: rolex,
      enabled: enabledx,
    },
    onSubmit: async (values) => {
    console.log("dsdfsff",values)
   let titleval = values.title;
    if(titleval ==''){
        titleval = titlex;
    }
    let nameval = values.name;
    if(nameval ==''){
        nameval = namex;
    }
    let roleval = values.role;
    if(roleval ==''){
        roleval = rolex;
    }

    var statusval = String(values.enabled);
     console.log(statusval);
     let userStatus = true;
     if(statusval == 'false'){
     	 userStatus = false;
     }
       
      try {
      await updateUserRole({
        variables: {
          input: {
           userId: userId,
           title:titleval,
           name:nameval,
           role: roleval, 
           enabled: userStatus
          },
        },
        
      
      });
	
      window.location.reload();
        
        if (onRequestClose) {
          onRequestClose();
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

 
  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit User - {emailx}</DialogTitle>
        
        <DialogContent>
        <FormHelperText><b>Email address cannot be changed on this screen.  Contact your NavigateSOM support representative if you need assistance with changes to emails.</b></FormHelperText>
        
          <FormControl className={classes.formControl}>
          <TextField
            name="name"
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            onChange={formik.handleChange}
            defaultValue={namex}
            fullWidth
            required
          />
          </FormControl>
            <FormControl className={classes.formControl}>
          <TextField
            name="title"
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            onChange={formik.handleChange}
            defaultValue={titlex}
            fullWidth
            required
          />
          </FormControl>
          <FormControl className={classes.formControl}>
        <InputLabel htmlFor="demo-dialog-native">Role</InputLabel>
        <Select defaultValue={rolex}  onChange={formik.handleChange} native input={<Input id="demo-dialog-native" />} required name="role">
            {roles.map((e, key) => {
                return <option key={key} value={e.key}>{e.value}</option>;
            })}
         </Select>
      </FormControl>
           <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-dialog-native">User Status</InputLabel>
            <Select defaultValue={enabledx}  onChange={formik.handleChange} native input={<Input id="demo-dialog-native" />} required name="enabled">
                {userStatus.map((e, key) => {
                    return <option key={key} value={e.key}>{e.value}</option>;
                })}
             </Select>
          </FormControl>
      
      
         
          <ErrorMessage error={error} />
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            color="default"
            onClick={onRequestClose}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            color="primary"
            disabled={loading}
            loading={loading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditUserModal;
