import { createMuiTheme } from '@material-ui/core/styles';
import Amplify from 'aws-amplify';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';
import App from './App';
import { AddUserModalProvider } from './components/AddUserModal/AddUserModalContext';
import { FlagUserModalProvider } from './components/FlagUserModal/FlagUserModalContext';
import { EditUserModalProvider } from './components/EditUserModal/EditUserModalContext';
import { CreateWebhookModalProvider } from './components/CreateWebhookModal/CreateWebhookModalContext';
import { WhatsNewModalProvider } from './components/WhatsNewModal/WhatsNewModalContext';
import { WhatsNewModalHeaderProvider } from './components/WhatsNewModalHeader/WhatsNewModalHeaderContext';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { ImportModalProvider } from './components/ImportModal/ImportModalContext';
import { NotesDrawerProvider } from './components/NotesDrawer/NotesDrawerContext';
import { NotificationProvider } from './components/Notification/NotificationProvider';
import { OrderItemsTableModalProvider } from './components/OrderItemsTableModal/OrderItemsTableModalContext';
import { UploadFileModalProvider } from './components/UploadFile/UploadFileModalContext';
import config from './config';
import './index.css';
import apolloClient from './lib/apolloClient';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

const theme = createMuiTheme({});
 localStorage.removeItem('filterInLocalStorage');
Amplify.configure({
  Auth: {
    identityPoolId: config.identityPoolId || '',
    region: config.region || '',
    userPoolId: config.userPoolId || '',
    userPoolWebClientId: config.clientId || '',
  },
});

ReactDOM.render(
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient as any}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ErrorBoundary>
              <NotificationProvider>
                <OrderItemsTableModalProvider>
                <AddUserModalProvider>
                <FlagUserModalProvider>
                  <NotesDrawerProvider>
                    <UploadFileModalProvider>
                      
                      <EditUserModalProvider>
                        <ImportModalProvider>
                          <CreateWebhookModalProvider>
                          <WhatsNewModalProvider>
                          <WhatsNewModalHeaderProvider>
						  
                            <App />
                        
						  </WhatsNewModalHeaderProvider>
                          </WhatsNewModalProvider>
                          </CreateWebhookModalProvider>
                        </ImportModalProvider>
                      </EditUserModalProvider>
                      
                    </UploadFileModalProvider>
                  </NotesDrawerProvider>
                  </FlagUserModalProvider>
                  </AddUserModalProvider>
                </OrderItemsTableModalProvider>
              </NotificationProvider>
            </ErrorBoundary>
          </QueryParamProvider>
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

unregister();
registerServiceWorker();
