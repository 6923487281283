import { createStyles, fade, InputBase, makeStyles, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

interface Props {
  value?: string;

  onSearch?(value: string): void;
}

function Searchbar(props: Props) {
  const { value, onSearch } = props;
  const classes = useStyles();
  const [search, setSearch] = useState(value);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>

      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        type="search"
        onKeyUp={({ key, currentTarget }: React.KeyboardEvent<HTMLInputElement>) => {
          if (key === 'Enter') {
            onSearch?.(currentTarget.value);
          }
        }}
        onChange={(event) => setSearch(event.currentTarget.value)}
        value={search}
      />
    </div>
  );
}

export default Searchbar;
