import { Auth, Hub, Logger } from 'aws-amplify';
import { useEffect, useState } from "react";

const alex: any = new Logger('Alexander_the_auth_watcher');

function useAuth() {
  const initialState = sessionStorage.getItem('isAuthenticated');
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(initialState) || false);

  const check = async () => {
    try {
      await Auth.currentSession();
      setIsAuthenticated(true);
      sessionStorage.setItem('isAuthenticated', String(true));
    } catch (err) {
      setIsAuthenticated(false);
      sessionStorage.setItem('isAuthenticated', String(false));
    }
  };

  useEffect(() => {
    check();

    alex.onHubCapsule = (capsule: any) => {
      switch (capsule.payload.event) {
        case 'signIn':
          setIsAuthenticated(true);
          sessionStorage.setItem('isAuthenticated', String(true));
          break;
        case 'signUp':
          setIsAuthenticated(true);
          sessionStorage.setItem('isAuthenticated', String(true));
          break;
        case 'signOut':
          setIsAuthenticated(false);
          sessionStorage.setItem('isAuthenticated', String(false));
          break;
      }
    };

    Hub.listen('auth', alex);

    return () => {
      Hub.remove('auth', alex);
    };
  }, []);

  return {
    isAuthenticated,
  };
}

export default useAuth;
