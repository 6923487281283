import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import PanelV2 from '../../../components/Panel/PanelV2';
import { useGetClassOfTradeAnalyticsQuery,useGetDashboardSettingsQuery,IDashboardSetting } from '../../../generated/graphql';
import ClassOfTradeTable from './ClassOfTradeTable';
interface ICheckedState {
  trade: string
  checked: boolean
}

const initialState: Array<ICheckedState> = [
  { trade: 'SPC', checked: false },
  { trade: 'VET', checked: false },
  { trade: 'RET', checked: false },
  { trade: 'LTC', checked: false },
  { trade: 'DEN', checked: false },
  { trade: 'HOS', checked: false },
  { trade: 'DOC', checked: false },
  { trade: 'WDD', checked: false },
];

function ClassOfTrade() {


const response1 = useGetDashboardSettingsQuery();
  
  //console.log(response1.data);
  
  const settingval = response1.data?.dashboardSettings?.map((ds: IDashboardSetting) => ds.defaultfiltersettings!) ?? ["ALL"];
  const filtersetting = settingval[0].trim();
  const [selectedOption, setSelectedOption] = useState(filtersetting);
  
  useEffect(function persistForm() {
    if( filtersetting !== undefined ){
         setSelectedOption(localStorage.getItem('filterInLocalStorage') || filtersetting);
      }
  },[filtersetting]);
   const [state, setState] = useState<Array<ICheckedState>>(initialState);
  const trades: any = state.filter(R.propEq('checked', true)).map(R.prop('trade'));
  
  const query = useGetClassOfTradeAnalyticsQuery({ variables: { trades } });
  const { loading, error, data } = query;
  const handleOnChange = ({ trade }: any) => setState(state.map((d: any) => d.trade !== trade ? d : {
    ...d,
    checked: !d.checked
  }));

const filtersettings = [
      {
        "key": "7",
        "value": "Last 7 Days"
      },
      {
        "key": "30",
        "value": "Last 30 Days"
      },
      {
        "key": "90",
        "value": "Last 90 Days"
      },
      {
        "key": "183",
        "value": "Last 6 Months"
      },
      {
        "key": "365",
        "value": "Last Year"
      }
  ];
  
  const onChange = async(event: React.ChangeEvent<HTMLSelectElement>) => {
     setSelectedOption(event.target.value);
     localStorage.setItem('filterInLocalStorage', event.target.value);
     try {
      algorithmByDate(event.target.value);
    } catch (err) {
      console.error(err);
    }
  };
   if( filtersetting !== "ALL" ){
     const optionVal = localStorage.getItem('filterInLocalStorage') || selectedOption;
    algorithmByDate(optionVal);
   
  }
   function algorithmByDate(val: any){
    if( val !== "ALL" ){
        var d = new Date();
        var endDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);
        
        d.setDate(d.getDate()-val);

        var startDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);

       // console.log(startDate);
        //console.log(endDate);

        query.refetch({
              start: startDate,
              end: endDate,
        });
    }else{
        query.refetch({
              start: undefined,
              end: undefined,
        });
    }
  }
  
 
  return (
    <Layout className={'relativeclass'}>
      <div className={'filterbox'}>
        <label>Filter</label>
        <select onChange={onChange} value={selectedOption} >
          <option value="ALL">ALL</option>
          {filtersettings.map((e, key) => {
            return <option key={key} value={e.key}>{e.value}</option>;
          })}
        </select>
      </div>
      <PanelV2
        loading={loading}
        error={error}
      >
        <Line
          data={{
            datasets: [
              {
                label: 'Count',
                type: 'line',
                data: data?.monthlyTradeAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.count,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Total',
                type: 'line',
                data: data?.monthlyTradeAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.totalOrderTotal,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Average Total',
                type: 'line',
                data: data?.monthlyTradeAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.averageOrderTotal,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Quantity',
                type: 'line',
                data: data?.monthlyTradeAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.totalOrderQuantity,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Average Quantity',
                type: 'line',
                data: data?.monthlyTradeAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.averageOrderQuantity,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'ME',
                type: 'line',
                data: data?.monthlyTradeAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.totalOrderMe,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
              {
                label: 'Average ME',
                type: 'line',
                data: data?.monthlyTradeAnalytics?.map((value: any) => ({
                  x: DateTime.fromObject({ year: value.orderYear, month: value.orderMonth }).toJSDate(),
                  y: value.averageOrderMe,
                })) ?? [],
                backgroundColor: 'rgba(46, 204, 113, 0.25)',
              },
            ],
          }}
          options={{
            scales: {
              xAxes: [
                {
                  type: 'time',
                  time: {
                    unit: 'month',
                  },
                },
              ],
            },
          }}
        />
      </PanelV2>

      <PanelV2
        loading={loading}
        error={error}
      >
        <ClassOfTradeTable
          data={data?.tradeAnalytics ?? []}
          checked={state.map(R.prop('checked'))}
          onChange={handleOnChange}
        />
      </PanelV2>
    </Layout>
  );
}

const Layout = styled('section')`
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
`;

export default ClassOfTrade;
