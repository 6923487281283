import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import FlagIcon from '@material-ui/icons/Flag';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { DateTime } from 'luxon';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';

import { useState ,useEffect } from 'react';
import * as React from 'react';
import { useQueryParam, useQueryParams } from 'use-query-params';
import { NumberParam, StringParam } from 'use-query-params/lib/params';
import DateRangeFilter from '../../../../components/DateRangeFilter/DateRangeFilter';
import { useNotesDrawer } from '../../../../components/NotesDrawer/NotesDrawerContext';
import { useOrderItemsTableModal } from '../../../../components/OrderItemsTableModal/OrderItemsTableModalContext';
import PanelV2 from '../../../../components/Panel/PanelV2';
import Searchbar from '../../../../components/Searchbar/Searchbar';
import SwitchLoadingErrorChildren from '../../../../components/SwitchLoadingErrorChildren';
import Table from '../../../../components/Table/Table';
import { IOrder, useListCustomerOrdersQuery } from '../../../../generated/graphql';
import ClearFlagIcon from '../../../../icons/ClearFlag';

import WarningIcon from '@material-ui/icons/Warning';
import * as formatter from '../../../../lib/formatter';
import { ISort } from '../../../../types';
import { CommaArrayParam, decodeSort, encodeSort } from '../../../../utils';
import Button from '@material-ui/core/Button';
const columns = [
  {
    title: 'Order ID',
    key: 'orderId',
    dataIndex: 'orderId',
    sortable: true,
  },
  {
    title: 'Trade',
    key: 'trade',
    dataIndex: 'trade',
    sortable: true,
  },
  {
    title: 'Quantity',
    key: 'orderQuantity',
    dataIndex: 'orderQuantity',
    sortable: true,
    render: (order: IOrder) => formatter.formatFloat(order.orderQuantity),
  },
  {
    title: 'Total',
    key: 'orderTotal',
    dataIndex: 'orderTotal',
    sortable: true,
    render: (order: IOrder) => formatter.formatFloat(order.orderTotal),
  },
  {
    title: 'ME',
    key: 'orderMe',
    dataIndex: 'orderMe',
    sortable: true,
    render: (order: IOrder) => formatter.formatFloat(order.orderMe),
  },
  {
    title: 'Date',
    key: 'orderedAt',
    dataIndex: 'orderedAt',
    sortable: true,
    render: (order: IOrder) => DateTime.fromSQL(order.orderedAt!.substring(0, 10)).toLocaleString(DateTime.DATE_MED),
  },
];

interface Props {
  id: string;
}

function CustomerOrders(props: Props) {
  const { id } = props;
  const [sort, setSort] = useQueryParam('sort', CommaArrayParam);
  const [page, setPage] = useQueryParam<number>('page', NumberParam);
  const [limit, setLimit] = useQueryParam<number>('limit', NumberParam);
  const [dateRange, setDateRange] = useQueryParams({ start: StringParam, end: StringParam });
  const [q, setQ] = useQueryParam<string>('q', StringParam);
  const [filtersettings] = useQueryParam<string>('filtersettings', StringParam);
  const [selectedOption, setSelectedOption] = useState(filtersettings);
   
  var orderssort = [
  {
    "key": "notflag",
    "value": "Clear - Not Flagged"
  },
  {
    "key": "flag",
    "value": "Flagged"
  },
  {
    "key": "cleared",
    "value": "Cleared"
  },
  {
    "key": "suspicious",
    "value": "Suspicious"
  }
];
  const { loading, error, data } = useListCustomerOrdersQuery({
    variables: {
      id,
      q,
      limit,
      page,
      sort,
      ...dateRange,
	  filtersettings
    },
  });
  const { open: openDrawer } = useNotesDrawer();
  const { open: openModal } = useOrderItemsTableModal();

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const onChange = async(event: React.ChangeEvent<HTMLSelectElement>) => {
   setSelectedOption(event.target.value);
    try {
	let param ='filtersettings';
    var url = window.location.href;  
	   if(!url.includes('?')){
            url += '?' + param + '=' + event.target.value;
        }else if(!url.includes(param)){
            url += '&' + param + '=' + event.target.value;
        }else {
            let paramStartIndex = url.search(param);
            let paramEndIndex = url.indexOf('&', paramStartIndex);
            if (paramEndIndex == -1){
                paramEndIndex = url.length;
            }
            let brands = url.substring(paramStartIndex, paramEndIndex);
    
            url = url.replace(brands, param + '=' + event.target.value);
        }
	
	window.location.href = url;
   } catch (err) {
     console.error(err);
   }
 };
const clearedflag = (order:IOrder) => {
    
   
  if (order.clearFlag && order.ndcflagged === 'false') {
    return true;
  }
  if (order.clearFlag && order.ndcflagged === 'true' && order.ndccleared ==='true') {
    return true;
  }
  if (!order.flagged && order.ndcflagged === 'true' && order.ndccleared === 'true') {
    return true;
  }
  return false;
};
  const renderFlagIcon = (order: IOrder) => {
   console.log(order)
    switch (true) {
	 
      case (clearedflag(order)):
        return (
          <Tooltip title="Cleared">
            <IconButton
              onClick={() => openDrawer(order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
            >
              <ClearFlagIcon />
            </IconButton>
          </Tooltip>
        );
    case (order.issuspiciousorder):
        return (
          <Tooltip title="Suspicious Flag">
            <IconButton
              onClick={() => openDrawer(order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
            >
              <WarningIcon />
            </IconButton>
          </Tooltip>
        ); 
      case (order.flagged || (order.ndcflagged === 'true')):
        return (
          <Tooltip title="Flagged">
            <IconButton
              onClick={() => openDrawer(order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
            >
              <FlagIcon />
            </IconButton>
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Clear">
            <IconButton
              onClick={() => openDrawer(order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
        );
    }
  };
  console.log(data?.customer)
  let customername='';
  let customertitle =`Orders for Customer ${id}`;
  if(data?.customer?.customerName !==null){
     customername = ' : '+data?.customer?.customerName
     customertitle = customertitle+ customername
  }
  return (
    <PanelV2 title={customertitle}>
      <Toolbar>
	  <small className={'flaggedicon'}>
		{(selectedOption == 'flag') && <FlagIcon />}
		  {(selectedOption == 'cleared') && <ClearFlagIcon />}
		  {(selectedOption == 'notflag') && <CheckIcon />}
		  {(selectedOption == 'suspicious') &&  <WarningIcon />}</small>
		   &nbsp;&nbsp;<div className={'dashboard-filterbox'}>
         <select  value={selectedOption} onChange={onChange} >
          <option value="ALL">ALL</option>
          {orderssort.map((e, key) => {
                return <option key={key} value={e.key}>{e.value}</option>;
            })}
         </select>
      </div>
        <Typography
          variant="h1"
          color="inherit"
          style={{ flex: 1 }}
        />

        <Searchbar
          value={q}
          onSearch={setQ}
        />
          <HtmlTooltip
            title={
              <React.Fragment>
                {"Search includes: Order ID, Trade"}
              </React.Fragment>
            }
            >
            <Button><HelpIcon/></Button>
          </HtmlTooltip>
        <Tooltip title="Filter Date">
          <DateRangeFilter
            selectionRange={{
              startDate: dateRange.start ? DateTime.fromSQL(dateRange.start).toJSDate() : undefined,
              endDate: dateRange.end ? DateTime.fromSQL(dateRange.end).toJSDate() : undefined,
            }}
            onClose={({ startDate, endDate }: any) => {
              setDateRange({
                start: startDate ? DateTime.fromJSDate(startDate).toSQLDate() : undefined,
                end: endDate ? DateTime.fromJSDate(endDate).toSQLDate() : undefined,
              });
            }}
          />
        </Tooltip>
      </Toolbar>

      <SwitchLoadingErrorChildren
        loading={loading}
        error={error}
      >
        <Table
          columns={[
            {
              title: 'Flagged',
              key: 'flaggedexist',
              dataIndex: 'id',
              sortable: true,
              render: (order: IOrder) => renderFlagIcon(order),
            },
            ...columns,
            {
              title: 'Actions',
              key: 'actions',
              render: (order: IOrder) => (
                <Tooltip title="View Order Items">
                  <IconButton
                    onClick={() => openModal(order.lineItems!,order.orderId!,order.requestId!,order.customerId!,order.customerName!,order.trade!,order.orderedAt!)}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              )
            },
          ]}
          data={data?.customer?.orders?.data ?? []}
          pagination={data?.customer?.orders?.pagination}
          onChangePage={setPage}
          onChangeRowsPerPage={setLimit}
          onSort={(s: ISort) => setSort(encodeSort(s, sort))}
          sorts={decodeSort(sort)}
          rowKey="orderId"
          tableClass={"ordersTable"}
        />
      </SwitchLoadingErrorChildren>
    </PanelV2>
  );
}

export default CustomerOrders;
