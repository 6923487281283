import { IconButton, Toolbar, Tooltip, Switch } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { useState ,useEffect } from 'react';
import { useCreateWebhookModal } from '../../components/CreateWebhookModal/CreateWebhookModalContext';
import { Layout } from '../../components/Layout';
import PanelV2 from '../../components/Panel/PanelV2';
import { IFilterSetting,ITenantConfiguration,useGetSettingsQuery,useUpdateSettingsMutation,useUpdateUserSettingsMutation,useUpdateUserClearedSettingsMutation,useUpdatePageSettingsMutation,useUpdatePassphraseSettingsMutation,useGetTenantConfigurationsQuery, IUserSettings, ITenantFrequencyConfigurations} from '../../generated/graphql';
import OAuth2Panel from './components/OAuth2Panel';
import Webhooks from './components/Webhooks';
function Settings() {

const query = useGetSettingsQuery({
    fetchPolicy: 'no-cache'
  });
   const { loading, error, data } = query;
   const { open: openModal } = useCreateWebhookModal();
   const defaultfiltersettings = data?.filterSettings?.map((ds: IFilterSetting) => ds.defaultfiltersettings!) ?? [""];
   const defaultfiltersettingsval =  defaultfiltersettings[0].trim();
   const notificationEnabled= data?.userSettings?.notificationsEnabled?? false;
   const notificationClearedEnabled= data?.userSettings?.notificationsclearedEnabled?? false;
   const role= data?.userSettings?.role;
  console.log(data);
 const tenConfigs = useGetTenantConfigurationsQuery();
 
 const notificationFrequency = data?.tenantFrequencyConfigurations?.map((ds: ITenantFrequencyConfigurations) => ds.timefrequency!) ?? 0;
   const notificationFrequencyval =  notificationFrequency;
 const limitsettings = tenConfigs.data?.tenantConfigurations?.map((ds: ITenantConfiguration) => ds.tenantpagelimit!) ?? [""];
 const passphrase = tenConfigs.data?.tenantConfigurations?.map((ds: ITenantConfiguration) => ds.passphrase!) ?? [""];
 var limitsettingsval =  limitsettings[0];
 var passphraseval =  passphrase[0];
if(limitsettingsval == null){
  limitsettingsval ='15';
}
if(passphraseval == null){
  passphraseval ='15';
}
   const [selectedOption, setSelectedOption] = useState(defaultfiltersettingsval);
   const [selectedPageOption, setSelectedPageOption] = useState(limitsettingsval);
   const [selectedPassphraseOption, setSelectedPassphraseOption] = useState(limitsettingsval);
   const [selectedNotifyOption, setSelectedNotifyOption] = useState(notificationEnabled);
   const [selectedNotifyClearedOption, setSelectedClearedNotifyOption] = useState(notificationClearedEnabled);
   useEffect(function persistForm() {
    if( limitsettingsval !== undefined ){
      setSelectedPageOption(limitsettingsval);
      }
  },[limitsettingsval]);
 useEffect(function persistForm() {
    if( passphraseval !== undefined ){
      setSelectedPassphraseOption(passphraseval);
      }
  },[passphraseval]);
 
  useEffect(function persistForm() {
    if( notificationEnabled !== undefined ){
      setSelectedNotifyOption(notificationEnabled);
      }
  },[notificationEnabled]);
  useEffect(function persistForm() {
    if( notificationClearedEnabled !== undefined ){
      setSelectedClearedNotifyOption(notificationClearedEnabled);
      }
  },[notificationClearedEnabled]);
    useEffect(function persistForm() {
    if( defaultfiltersettingsval !== undefined ){
        setSelectedOption(defaultfiltersettingsval);
      }
  },[defaultfiltersettingsval]);
  var filtersettings = [
  {
    "key": "7",
    "value": "Last 7 Days"
  },
  {
    "key": "30",
    "value": "Last 30 Days"
  },
  {
    "key": "90",
    "value": "Last 90 Days"
  },
  {
    "key": "183",
    "value": "Last 6 Months"
  },
  {
    "key": "365",
    "value": "Last Year"
  }
];
var pagelimitsettings = [
  {
    "key": "15",
    "value": "15"
  },
  {
    "key": "30",
    "value": "30"
  },
  {
    "key": "50",
    "value": "50"
  },
  {
    "key": "100",
    "value": "100"
  }
];
var passphraselength = [
  {
    "key": 22,
    "value": "22 bytes"
  },
  {
    "key": 32,
    "value": "32 bytes"
  }
];

  const [updateSettings] = useUpdateSettingsMutation();
  const [updatePageSettings] = useUpdatePageSettingsMutation();
  const [updatePassphraseSettings] = useUpdatePassphraseSettingsMutation();
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [updateUserSettingsCleared] = useUpdateUserClearedSettingsMutation();
  const onChangePagelimit = async(event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPageOption(event.target.value);
     try {
      await updatePageSettings({
        variables: {
          input: {
            pagesettings: event.target.value,
          },
        },
        
      });
      
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };
   const onChangePassphraselength = async(event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPassphraseOption(event.target.value);
     try {
      await updatePassphraseSettings({
        variables: {
          input: {
            Passphrasesettings: event.target.value,
          },
        },
        
      });
      
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };
  
  const onChange = async(event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    try {
     await updateSettings({
       variables: {
         input: {
           defaultfiltersettings: event.target.value,
         },
       },
       
     });
     
     window.location.reload();
   } catch (err) {
     console.error(err);
   }
 };
 const onChangeNotify = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  setSelectedNotifyOption(checked);
  try {
   await updateUserSettings({
     variables: {
       input: {
        notificationsEnabled: checked,
       },
     },
     
   });
   
  window.location.reload();
 } catch (err) {
   console.error(err);
 }
};
 const onChangeclearedNotify = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  setSelectedClearedNotifyOption(checked);
  try {
   await updateUserSettingsCleared({
     variables: {
       input: {
        notificationsclearedEnabled: checked,
       },
     },
     
   });
   
  window.location.reload();
 } catch (err) {
   console.error(err);
 }
};
 

  
  return (
  
    <Layout>
     <PanelV2
        title="Dashboard Filter"
        loading={loading}
        error={error}
      >
     
         <div className={'dashboard-filterbox'}>
         <select value={selectedOption} onChange={onChange} >
          <option value="ALL">ALL</option>
          {filtersettings.map((e, key) => {
                return <option key={key} value={e.key}>{e.value}</option>;
            })}
         </select>
      </div>
      </PanelV2>
      <PanelV2
        title="Orders Page Rows Limit"
        loading={loading}
        error={error}
      >
     
         <div className={'dashboard-filterbox'}>
         <select value={selectedPageOption} onChange={onChangePagelimit} >
          {pagelimitsettings.map((e, key) => {
                return <option key={key} value={e.key}>{e.value}</option>;
            })}
         </select>
      </div>
      </PanelV2> 
	  
      <PanelV2
        title="Email Notifications For Flagged Orders"
        loading={loading}
        error={error}
      >
         <p>{selectedNotifyOption && `Notifications Enabled (Frequency: ${notificationFrequencyval} hour(s)) `} {!selectedNotifyOption && 'Notifications Disabled'}</p>
        <Switch
          checked={selectedNotifyOption!}
          onChange={onChangeNotify}
        />
       
        </PanelV2>
        <PanelV2
        title="Email Notifications for Cleared Orders"
        loading={loading}
        error={error}
      >
         <p>{selectedNotifyClearedOption && `Notifications Enabled`} {!selectedNotifyClearedOption && 'Notifications Disabled'}</p>
        <Switch
          checked={selectedNotifyClearedOption!}
          onChange={onChangeclearedNotify}
        />
       
        </PanelV2>
        {role == 'admin' && 
        <>
      <PanelV2
        title="API Keys"
        loading={loading}
        error={error}
      >
        <OAuth2Panel oAuth2Client={data?.oAuth2Client} />
      </PanelV2>
	  
      <PanelV2
        title="Webhooks"
        loading={loading}
        error={error}
      >
        <Toolbar
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title="Add Webhook">
            <IconButton onClick={() => openModal()}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <Webhooks data={data?.webhooks ?? []} />
      </PanelV2>
     
      
      <PanelV2
        title="Choose Secret Key Length"
        loading={loading}
        error={error}
      >
     
         <div className={'dashboard-filterbox'}>
         <select value={selectedPassphraseOption} onChange={onChangePassphraselength} >
          {passphraselength.map((e, key) => {
                return <option key={key} value={e.key}>{e.value}</option>;
            })}
         </select>
      </div>
      </PanelV2>
       </>
      }
    </Layout>
  );
};

export default Settings;
