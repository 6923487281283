import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import UploadFileModal from './UploadFileModal';

export interface IUploadFileModalContext {
  open(customerId: string): void;
}

export const UploadFileModalContext = React.createContext<Maybe<IUploadFileModalContext>>(null);

interface Props {
  children: any;
}

export const UploadFileModalProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState<Maybe<string>>();

  return (
    <UploadFileModalContext.Provider
      value={{
        open: (customerId: string) => {
          setCustomerId(customerId);
          setOpen(true);
        },
      }}
    >
      {children}

      {
        open && customerId && (
          <UploadFileModal
            open={open}
            customerId={customerId}
            onRequestClose={() => setOpen(false)}
          />
        )
      }
    </UploadFileModalContext.Provider>
  );
};

export const useUploadFileModal = () => {
  const context = React.useContext(UploadFileModalContext);

  if (!context) {
    throw new Error('UploadFileModalContext missing');
  }

  return context;
};
