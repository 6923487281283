import { createStyles, makeStyles, TableCell, Theme } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import classNames from 'classnames';
import * as React from 'react';
import { ISort } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  clickable: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    // marginRight: theme.spacing.unit,
  },
}));

interface Props {
  children: string;
  name: string;
  sort?: ISort;

  onClick?(sort: ISort): void;
}

const SortableTableCell = (props: Props) => {
  const { name, sort, onClick, children } = props;
  const classes = useStyles();

  const translateOrder = (order?: string) => {
    switch (order) {
      case 'asc':
        return 'desc';
      case 'desc':
        return undefined;
      default:
        return 'asc';
    }
  };

  const handleOnClick = () => {
   
    if (onClick) {
      if (!sort) {
        onClick({
         
          name,
          order: translateOrder(undefined),
        });
      } else {
        const { order } = sort;
        onClick({
          name,
          order: translateOrder(order),
        });
      }
    }
  };

  return (
    <TableCell
      className={classNames(onClick ? classes.clickable : '')}
      onClick={handleOnClick}
    >
      <div className={classes.container}>
        {sort && sort.order === 'asc' && (<ArrowUpwardIcon className={classes.icon} />)}
        {sort && sort.order === 'desc' && (<ArrowDownwardIcon className={classes.icon} />)}
        <span> {children}</span>
      </div>
    </TableCell>
  );
};

export default SortableTableCell;
