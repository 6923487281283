import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import * as React from 'react';
import { IOrderLineItem } from '../../../generated/graphql';

interface Props {
  orderItems: Array<IOrderLineItem>;
  orderId: string;
  requestId:string;
}

function OrderItemsTable(props: Props) {
  const { orderItems = [] } = props;
  
  const renderRowCell = (source: any) => {
    if ( (source.algorithm !== undefined ) ) {
      return 'redclass';
    }

    return '';
  };
  
    
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>NDC</TableCell>
          <TableCell>Qty</TableCell>
          <TableCell>Cost</TableCell>
          <TableCell>ME</TableCell>
          <TableCell>Product Name</TableCell>
          <TableCell>Controlled</TableCell>
          <TableCell>Dosage Form</TableCell>
          <TableCell>Size</TableCell>
          <TableCell>Package SUM</TableCell>
          <TableCell>Qty (Case)</TableCell>
          <TableCell>Package Description</TableCell>
          <TableCell>Labeler</TableCell>
          <TableCell>Rx/OTC</TableCell>
         
        </TableRow>
      </TableHead>
      <TableBody>
        {
          orderItems.map((orderItem) => (
            <TableRow key={`orderItem.ndc_${orderItem.ndc}`} className={renderRowCell(orderItem)}>
              <TableCell>{orderItem.ndc}</TableCell>
              <TableCell>{orderItem.lineQuantity}</TableCell>
              <TableCell>{orderItem.lineTotal}</TableCell>
              <TableCell>{orderItem.me}</TableCell>
              <TableCell>{orderItem.productName}</TableCell>
              <TableCell>{orderItem.controlled}</TableCell>
              <TableCell>{orderItem.dosageForm}</TableCell>
              <TableCell>{orderItem.size}</TableCell>
              <TableCell>{orderItem.packageSum}</TableCell>
              <TableCell>{orderItem.qtyCase}</TableCell>
              <TableCell>{orderItem.packageDescription}</TableCell>
              <TableCell>{orderItem.labeler}</TableCell>
              <TableCell>{orderItem.rxOtc}</TableCell>
              
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}

export default OrderItemsTable;
