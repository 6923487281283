import { AppBar, Theme, Toolbar, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';
import { NavigationDrawerProvider } from './components/NavigationDrawer/NavigationDrawer';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PublicRoute from './components/PublicRoute/PublicRoute';
import { useGetHelpAttachmentQuery } from './generated/graphql';
import useAuth from './lib/useAuth';
import { Analytics, Customer, Customers, Login, Orders, Reports, Settings, Uploads, Users } from './routes';

import { useWhatsNewModalHeader } from './components/WhatsNewModalHeader/WhatsNewModalHeaderContext'; 
import FeedbackIcon from '@material-ui/icons/Feedback';
import HelpIcon from '@material-ui/icons/Help';
import { useEffect } from 'react';

function App() {
  const { isAuthenticated } = useAuth();
   const { open: openModal } = useWhatsNewModalHeader();
  
   let buildversion ='';
   const buildver:any = localStorage.getItem('buildversionupdate') || '';
   if(buildver !== undefined && buildver !== ''){
       buildversion = 'Version '+buildver;
   }
    const query = useGetHelpAttachmentQuery();
const {data} = query;
   async function  getHelpAttachment(){
 
 
  query.refetch();
    if (data) {
      window.open(data.helpAttachment, '_blank', 'rel="noopener noreferrer",width=1200,height=800');
    }
  
}
  return (
    <>
      <CssBaseline />

      <Switch>
        <PublicRoute
          exact
          isAuthenticated={isAuthenticated}
          redirectTo="/"
          path="/login"
          component={Login}
        />

        <PrivateRoute
          path="/"
          isAuthenticated={isAuthenticated}
          redirectTo="/login"
          render={() => (
            <NavigationDrawerProvider>
              <Layout>
                <AppBar
                  style={{
                    gridArea: 'header',
                  }}
                  position="static"
                >
                  <Toolbar>
                    <Typography variant="h6">
                      NavigateSOM
                    </Typography>
                    <Typography variant="h6" style={{
                    position: 'absolute',
                    right: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }} onClick={() => {openModal()}}>
                  
                  <FeedbackIcon/>
                    <div style={{
                    display: 'inlineBlock',
                    marginLeft: '12px',
                    cursor: 'pointer',
                    top: '10px',
                  }}>
                      What's New
                      
                      </div>
                    </Typography>
                    <div style={{
                            fontSize: '12px',
                            clear: 'both',
                            position: 'absolute',
                            right: '26px',
                            display: 'flex',
                            alignItems: 'center',
                            top: '42px',
                    }}>
                     {buildversion}
                     </div>
                 
                    <Typography variant="h6" style={{
                    position: 'absolute',
                    right: '170px',
                    display: 'flex',
                    alignItems: 'center',
                  }} onClick={() => getHelpAttachment()}>
                  
                  <HelpIcon/>
                    <div style={{
                    display: 'inlineBlock',
                    marginLeft: '12px',
                    cursor: 'pointer',
                    top: '10px',
                  }}>
                     Help
                      
                      </div>
                    </Typography>
                   
                  </Toolbar>
                </AppBar>

                <section
                  style={{
                    gridArea: 'body',
                    overflow: 'auto',
                  }}
                >
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={Analytics}
                    />
                    <Route
                      exact
                      path="/settings"
                      component={Settings}
                    />
                    <Route
                      exact
                      path="/customers"
                      component={Customers}
                    />
                    <Route
                      exact
                      path="/customers/:id"
                      component={Customer}
                    />
                    <Route
                      exact
                      path="/orders"
                      component={Orders}
                    />
                    <Route
                      exact
                      path="/users"
                      component={Users}
                    />
                    <Route
                      exact
                      path="/uploads"
                      component={Uploads}
                    />
                    <Route
                      exact
                      path="/reports"
                      component={Reports}
                    />
                  </Switch>
                </section>
              </Layout>
            </NavigationDrawerProvider>
          )}
        />
      </Switch>
    </>
  );
}

const Layout = styled(`main`)`
  height: 100vh;
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    'header header'
    'sidebar body';
  background-color: ${({ theme }: { theme: Theme }) => theme.palette.background.default};
`;

export default App;
