import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import * as React from 'react';
import { IPagination, Maybe } from '../../generated/graphql';
import { ISort } from '../../types';

import SortableTableCell from '../SortableTableCell/SortableTableCell';
import useNavigator from '../../lib/useNavigator';
interface IColumn {
  title: string
  key: string
  dataIndex?: string
  sortable?: boolean

  render?(data?: any): React.ReactElement<any> | string | null
  addRowClass?:string;
}


interface Props {
  columns: Array<IColumn>
  data: Array<any>
  pagination?: Maybe<IPagination>
  sorts?: Array<ISort>
  rowKey?: string;

  renderAdditionalRow?(data?: any, index?: number): any

  onClickTableRow?(data: any): void

  onChangePage?(page: number): void

  onChangeRowsPerPage?(value: number): void

  onSort?(sort: ISort): void
  tableClass?:string;
}

function TableWrapper(props: Props) {
  const { columns, data, rowKey, renderAdditionalRow, onClickTableRow, pagination, onChangePage, onChangeRowsPerPage, sorts, onSort,tableClass } = props;
 const navigator = useNavigator();

  const renderCell = (source: any) => (column: IColumn) => {
    if (column.render) {
      return column.render(source);
    }

    if (column.dataIndex) {
    
       if(column.dataIndex === 'role' ){
          return source[column.dataIndex].charAt(0).toUpperCase() + source[column.dataIndex].slice(1);; 
       }
      if(source[column.dataIndex] === null && column.dataIndex === 'customerName' ){
          return '--'; 
       }
       if(source[column.dataIndex] !== null && column.dataIndex === 'id' ){
        return <div className="tooltip">{source[column.dataIndex]}<span className="tooltiptext">Click to see List of Customer's Orders</span></div>;
       }
       if(source[column.dataIndex] !== null && column.dataIndex === 'customerId' ){
        return <div className="tooltip">{source[column.dataIndex]}<span className="tooltiptext">Click to see List of Customer's Orders</span></div>;
       }
        return source[column.dataIndex];
    }

   
    return null;
  };

  const handleClick = (source: any) => (columns: any) => (column: IColumn) => {
   let customerID = '';
   let showalgorders = '';
   let algorithmtype = '';
      columns.map( function(item:any) { 
              if(item.dataIndex === 'customerId' ){
                   customerID = source[item.dataIndex];
               }
               if(item.dataIndex === 'algtype' ){
                algorithmtype = source[item.dataIndex];
               }

      } );
      if(column.dataIndex === 'customerId' ){
         navigator.goToCustomer(source[column.dataIndex]);
       }
      
       if(column.dataIndex === 'customerName' && source[column.dataIndex]!== null){
         // navigator.goToCustomer(customerID);
       }
       
       if(column.dataIndex === 'overViewAlgorithm' && source[column.dataIndex]!== null){
          showalgorders = '1';
       }
       if(column.dataIndex === 'ndcFlagCount' && source[column.dataIndex]!== null){
          showalgorders = '1';
       }
       if(column.dataIndex === 'flagCount' && source[column.dataIndex]!== null){
          showalgorders = '1';
       }
       if(column.dataIndex === 'overViewPercentage' && source[column.dataIndex]!== null){
          showalgorders = '1';
       }
       if(showalgorders === '1' ){
            let filterby: any = localStorage.getItem('filterInLocalStorage') && localStorage.getItem('filterInLocalStorage') !== '' ? localStorage.getItem('filterInLocalStorage') : '';
            
            if( filterby !== '' && filterby !== 'ALL'){
                var d = new Date();
                var endDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);

                d.setDate(d.getDate()-filterby);

                var startDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);


                window.location.href = 'orders?algorithm='+source.algtype+'&start='+startDate+'&end='+endDate+'&filtersettings=dashboardfilter';
            }else{
                window.location.href = 'orders?algorithm='+source.algtype+'&filtersettings=dashboardfilter';
            }
            
          // navigator.goToOrders();
       }
    return null;
    
   
  };

  
 const renderRowCell = (source: any) => {
 let nt =0;
  if (source.clearFlag && source.ndcflagged === 'false') {
    nt++;
  }
  if (source.clearFlag && source.ndcflagged === 'true' && source.ndccleared ==='true') {
    nt++;
  }
  if (!source.flagged && source.ndcflagged === 'true' && source.ndccleared === 'true') {
    nt++;
  }
 if(source.suspiciousorder == 'true' &&  nt==0 ){
       return 'pinkclass';
    }
   if ( source.flagged && (!source.clearFlag)  || (source.ndcflagged === 'true' && ((source.ndccleared === 'false') || (source.ndccleared === null)) )) {
      return 'redclass';
    }

    return '';
  };

  const renderTableCellHead = ({ title, key, sortable }: IColumn) => {
    
    if (sortable) {
      return (
        <SortableTableCell
          key={key}
          name={key}
          sort={sorts?.find(({ name }) => name === key)}
          onClick={onSort}
        >
          {title}
        </SortableTableCell>
      );
    }

    return (
      <TableCell key={key}>
        {title}
      </TableCell>
    );
  };

  

  return (

    <Table className={tableClass ? tableClass:''}>
      <TableHead>
        <TableRow>
          {
            columns.map(renderTableCellHead)
          }
        </TableRow>
      </TableHead>

      <TableBody>
        {
        
          data.map((source, index) => (
            <React.Fragment key={rowKey ? source[rowKey] : index}>
              <TableRow
                key={rowKey ? source[rowKey] : index}
                hover
                onClick={() => onClickTableRow?.(source)}
                className={renderRowCell(source)}
              >
                {
                  columns.map(column => (
                    <TableCell key={column.key}  className={column.key === 'customerName' ? 'customercls' :''} onClick={() => handleClick(source)(columns)(column)}>
                    
                      {renderCell(source)(column)}
                    </TableCell>
                  ))
                }
              </TableRow>

              {
                renderAdditionalRow?.(source, index)
              }
            </React.Fragment>
          ))
        }
      </TableBody>

      {pagination && (
        <TableFooter>
          <TableRow>
            <TablePagination
              count={pagination.total!}
              rowsPerPage={pagination.limit!}
              rowsPerPageOptions={[15, 30, 50, 100, pagination.limit!]}
              page={pagination.page!}
              onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, page: number) => onChangePage?.(page)}
              onChangeRowsPerPage={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChangeRowsPerPage?.(parseInt(event.target.value, 10))}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
}

export default TableWrapper;

