import { Auth } from 'aws-amplify';
import * as React from 'react';
import config from '../config';

interface DownloadReportRequest {
  startDate?: string;
  endDate?: string;
  flagged?: string;
  customerName?: string;
  zipCode?: string;
  minME?: string;
  maxME?: string;
  ndc?: string;
}

interface Props {
  type: 'orders' | 'customers';
}

const useDownloadReport = (props: Props): [(request: DownloadReportRequest) => Promise<any>, { loading: boolean, error?: any, data?: Response }] => {
  const { type } = props;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any | undefined>();
  const [data, setData] = React.useState<Response | undefined>();

  const fn = React.useCallback(async (request: DownloadReportRequest) => {
    try {
      setLoading(true);

      const session = await Auth.currentSession();

      const accessToken = session.getIdToken().getJwtToken();

      const url = `${config.tenantUrl}/${type}/export`;

      const body = JSON.stringify(request);

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'text/csv',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body,
      });
      if (!res.ok) {
        const error = new Error(res.statusText);
        setError('No Records Found');
        throw error;
      }
      console.log(res)
      setData(res);

      return res;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [type]);

  return [fn, { loading, error, data }];
};

export default useDownloadReport;
