import * as React from 'react';
import styled from 'styled-components';
import PanelV2 from '../../../components/Panel/PanelV2';
import { useGetFlaggedClassOfTradeAnalyticsQuery,useGetDashboardSettingsQuery,IDashboardSetting } from '../../../generated/graphql';
import FlaggedClassOfTradeTable from './FlaggedClassOfTradeTable';
import { useState, useEffect } from 'react';
function FlaggedClassOfTrade() {
  const response1 = useGetDashboardSettingsQuery();
  
  //console.log(response1.data);
  
  const settingval = response1.data?.dashboardSettings?.map((ds: IDashboardSetting) => ds.defaultfiltersettings!) ?? ["ALL"];
  const filtersetting = settingval[0].trim();
  const [selectedOption, setSelectedOption] = useState(filtersetting);
  
  useEffect(function persistForm() {
    if( filtersetting !== undefined ){
        setSelectedOption(localStorage.getItem('filterInLocalStorage') || filtersetting);
      }
  },[filtersetting]);
  
  
  
  const query = useGetFlaggedClassOfTradeAnalyticsQuery();
  const { loading, error, data } = query;
  
   if( filtersetting !== "ALL" ){
     const optionVal = localStorage.getItem('filterInLocalStorage') || selectedOption;
    algorithmByDate(optionVal);
   
  }
  
  const filtersettings = [
      {
        "key": "7",
        "value": "Last 7 Days"
      },
      {
        "key": "30",
        "value": "Last 30 Days"
      },
      {
        "key": "90",
        "value": "Last 90 Days"
      },
      {
        "key": "183",
        "value": "Last 6 Months"
      },
      {
        "key": "365",
        "value": "Last Year"
      }
  ];
  
  const onChange = async(event: React.ChangeEvent<HTMLSelectElement>) => {
     setSelectedOption(event.target.value);
     localStorage.setItem('filterInLocalStorage', event.target.value);
     try {
      algorithmByDate(event.target.value);
    } catch (err) {
      console.error(err);
    }
  };
  
  function algorithmByDate(val: any){
    if( val !== "ALL" ){
        var d = new Date();
        var endDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);
        
        d.setDate(d.getDate()-val);

        var startDate = d.getUTCFullYear() + '-' + ('0' + (d.getUTCMonth()+1)).slice(-2) + '-' + ('0' + d.getUTCDate()).slice(-2);

        //console.log(startDate);
        //console.log(endDate);

        query.refetch({
              start: startDate,
              end: endDate,
        });
    }else{
        query.refetch({
              start: undefined,
              end: undefined,
        });
    }
  }
  return (
    <Layout className={'relativeclass'}>
      <div className={'filterbox'}>
        <label>Filter</label>
        <select onChange={onChange} value={selectedOption} >
          <option value="ALL">ALL</option>
          {filtersettings.map((e, key) => {
            return <option key={key} value={e.key}>{e.value}</option>;
          })}
        </select>
      </div>
      <PanelV2
        title="Flagged Class of Trade"
        loading={loading}
        error={error}
      >
        <FlaggedClassOfTradeTable data={data?.flaggedClassOfTrade ?? []} />
      </PanelV2>
    </Layout>
  );
}

const Layout = styled('section')`
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
`;

export default FlaggedClassOfTrade;
