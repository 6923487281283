import * as R from 'ramda';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';
import * as React from 'react';
import {useGetDashboardSettingsQuery,IWhatsNewData } from '../../generated/graphql';
import Button from '../Button';
const useStyles = makeStyles((theme: Theme) => createStyles({
 
  whatsnewtitle: {
    display: 'inline-block',
  },
  whatsnewclose: {
    display: 'inline-block',
    float: 'right',
  },
  whiteSpace: {
        whiteSpace: 'pre-wrap',
  },
  
}));

interface Values {
  url: string
}

interface Props {
  open?: boolean;

  onRequestClose?(): void;
}

function WhatsNewModalHeader(props: Props) {
  const { open = false, onRequestClose } = props;
  const classes = useStyles();
  const query = useGetDashboardSettingsQuery();
  const { data } = query;
  
  const whatsNewAllData = data?.fetchWhatsNewData?.map((ds: IWhatsNewData) => ds!) ?? [];
    function createMarkup(desc: any) {
        return {__html: desc};
    }
  

  return (
    <Dialog
      open={open}
      onClose={() => !!onRequestClose && onRequestClose()}
       fullWidth ={true}
       maxWidth ="md"
    >
      <form >
        <DialogTitle className={classes.whatsnewtitle}>What's New</DialogTitle>
        <DialogActions className={classes.whatsnewclose}>
          
          <Button
            variant="contained"
             color="primary"
            type="button"
            onClick={onRequestClose}
          >
            Close
          </Button>
          
        </DialogActions>
        <DialogContent >
          <div>
          {
              R.map((whatsnew: IWhatsNewData) => (
              <div >
                
                   <div className={'descriptioncls'}><div dangerouslySetInnerHTML={createMarkup(whatsnew.description)}/></div>
                 
               </div>
              ))(whatsNewAllData)
            }
         
         </div>
        </DialogContent>

        
      </form>
    </Dialog>
  );
}

export default WhatsNewModalHeader;
