import * as L from 'partial.lenses';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { decodeDelimitedArray, encodeDelimitedArray } from 'use-query-params/lib/serialize';
import { ISort } from './types';

export const CommaArrayParam = {
  encode: (array: string[] | null | undefined) => encodeDelimitedArray(array, ','),
  decode: (arrayStr: string | string[] | null | undefined) => decodeDelimitedArray(arrayStr, ',')
};

export const encodeSort = (s: ISort, data: any) => R.pipe<any, any, any, any, any, any, any, any>(
  R.defaultTo(RA.stubArray()),
  R.map(R.split(':')),
  R.map(([name, order]: any) => ({ name, order })),
  L.remove([L.elems, L.whereEq({ name: s.name })]),
  R.append(s),
  R.reject(R.propSatisfies(R.isNil, 'order')),
  R.map(({ name, order }: ISort) => `${name}:${order}`),
)(data);

export const decodeSort = R.pipe<any, any, any, any>(
  R.defaultTo(RA.stubArray()),
  R.map(R.split(':')),
  R.map(([name, order]: any) => ({ name, order })),
);
