import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import WhatsNewModal from './WhatsNewModal';

export interface IWhatsNewModalContext {
  open(): void;
}

export const WhatsNewModalContext = React.createContext<Maybe<IWhatsNewModalContext>>(null);

interface Props {
  children: any;
}

export const WhatsNewModalProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <WhatsNewModalContext.Provider
      value={{
        open: () => setOpen(true),
      }}
    >
      {children}

      {
        open && (
          <WhatsNewModal
            open={open}
            onRequestClose={() => setOpen(false)}
          />
        )
      }
    </WhatsNewModalContext.Provider>
  );
};

export const useWhatsNewModal = () => {
  const context = React.useContext(WhatsNewModalContext);

  if (!context) {
    throw new Error('WhatsNewModalContext missing');
  }

  return context;
};
