import * as React from 'react';
import Table from '../../../components/Table/Table';
import { IAlgorithmAnalytic } from '../../../generated/graphql';
import { formatPercentage } from '../../../lib/formatter';

const columns = [
  {
    title: 'Algorithm',
    key: 'algorithm',
    render: (algorithm: IAlgorithmAnalytic) => `${algorithm.name}: ${algorithm.description}`,
    dataIndex: 'overViewAlgorithm'
  },
  {
    title: 'Orders Flagged',
    key: 'numberFlagged',
    dataIndex: 'flagCount',
  },
  {
    title: 'NDC Flagged',
    key: 'ndcFlagged',
    dataIndex: 'ndcFlagCount',
  },
  {
    title: 'Percentage of Orders',
    key: 'percentageOfOrders',
    render: (algorithm: IAlgorithmAnalytic) => formatPercentage(algorithm.flagCount! / algorithm.orderCount!),
    dataIndex: 'overViewPercentage'
  },
];

interface Props {
  data: Array<IAlgorithmAnalytic>
}

function AlgorithmTable(props: Props) {
  const { data = [] } = props;

  return (
    <Table
      data={data}
      columns={columns}
      rowKey="name"
    />
  );
}

export default AlgorithmTable;
