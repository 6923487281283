import { Button, ButtonProps, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props extends ButtonProps {
  loading?: boolean;
  children: any;
  component?: any;
}

export default (props: Props) => {
  const { loading, children } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button {...props}>
        {children}
      </Button>

      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
