import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as L from 'partial.lenses';
import * as React from 'react';
import { GetSettingsDocument, IWebhook, useCreateWebhookMutation } from '../../generated/graphql';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogContent: {
    width: '500px',
  },
}));

interface Values {
  url: string
}

interface Props {
  open?: boolean;

  onRequestClose?(): void;
}

function CreateWebhookModal(props: Props) {
  const { open = false, onRequestClose } = props;
  const classes = useStyles();
  const [createWebhook, { loading, error }] = useCreateWebhookMutation();

  const formik = useFormik<Values>({
    initialValues: {
      url: '',
    },
    onSubmit: async (input) => {
      try {
        await createWebhook({
          variables: { input },
          update: (store, { data }) => {
            if (!data) {
              return;
            }

            const d = store.readQuery({
              query: GetSettingsDocument,
            });

            store.writeQuery({
              query: GetSettingsDocument,
              data: L.modify('webhooks', (webhooks: Array<IWebhook>) => [data.createWebhook, ...webhooks], d),
            });
          },
        });

        if (onRequestClose) {
          onRequestClose();
        }
        window.location.reload();
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => !!onRequestClose && onRequestClose()}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Add Webhook</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <TextField
            name="url"
            autoFocus
            margin="dense"
            label="URL"
            variant="outlined"
            type="url"
            fullWidth
            required
            value={formik.values.url}
            onChange={formik.handleChange}
          />

          <ErrorMessage error={error} />
        </DialogContent>

        <DialogActions>
          <Button
            color="default"
            type="button"
            onClick={() => onRequestClose?.()}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            type="submit"
            disabled={loading}
            loading={loading}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateWebhookModal;
