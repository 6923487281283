import { CircularProgress, Divider, List, ListItem, ListItemText, ListSubheader, Typography,Switch } from '@material-ui/core';
import * as React from 'react';
import { useGetNotesDrawerContentQuery, useCreateNoteMutation, useAddNotesMutation,useListOrdersQuery, useUpdateSuspiciousOrderMutation,useListCustomerOrdersQuery, INote, useAddDocumentsMutation } from '../../../generated/graphql';
import NotesList from './NotesList';
import {Formik, Form, Field } from 'formik';
//import {getStyles} from './NotesDrawerContent.style';
import {Props, Algorithm} from './NotesDrawerContent.types'
import getLineItems from './GetLineItems';
import NDCFlags from './NDCFlags';
import OrderFlags from './OrderFlags';
import CommonCommentBox from './CommonCommentBox';

import { getStyles } from "./NotesDrawerContent.style";
import { useQueryParam, useQueryParams } from 'use-query-params';

import { NumberParam, StringParam } from 'use-query-params/lib/params';
import { CommaArrayParam } from '../../../utils';
import { DateTime } from 'luxon';
import ViewAttachmentButton from '../../../routes/Orders/components/ViewAttachmentButton';
import { useEffect, useRef } from 'react';

// TODO
// Form Binding - Done
// Refactoring - Partial done, order flags section need to recheck
// Clear All Flags
// Validation = Done
// Attachment = Done
// Submit
// Styles

const NotesDrawerContent = (props: Props) => {
  console.log(props)
  // Get Props
  const { id ,requestid,customerid ,customername,trade,orderedat} = props;
  
  // Get Styles
  const classes = getStyles();
 
function  printform(){
  // document.body.style.overflow='unset';
   window.print();
 }
 function  addnote(){
  const lsOutput1:any = document.getElementById('notecls');
  lsOutput1.style.display = 'none';
  const lsOutput:any = document.getElementById('targetdiv');
  lsOutput.style.display = 'block';
  
}
function  adddocuments(){
  const lsOutput1:any = document.getElementById('documentcls');
  lsOutput1.style.display = 'none';
  const lsOutput:any = document.getElementById('documentdiv');
  lsOutput.style.display = 'block';
}
function  cancelDocumentData(){
  const lsOutput1:any = document.getElementById('documentcls');
  lsOutput1.style.display = 'block';
  const lsOutput:any = document.getElementById('documentdiv');
  lsOutput.style.display = 'none';
  const lsOutput2:any = document.getElementById('file1');
  lsOutput2.value ='';
  const commondocumenterror:any = document.getElementById('commondocumenterror');
  commondocumenterror.innerHTML  = '';
}


  // Mutation - Create Note
  const [createNote] = useCreateNoteMutation();
  const [addDocuments] = useAddDocumentsMutation();
  const [updateSuspiciousOrder] = useUpdateSuspiciousOrderMutation();
  // Query - Get All Notes
  //const requestidx:string = String(requestid);
  const customeridx:string = String(customerid);
  const customernamex:string = String(customername);
  const tradex:string = String(trade);
  const orderedatx:string = String(orderedat);
   
  const query = useGetNotesDrawerContentQuery({ variables: { orderId: id,requestId:requestid,customerId: customeridx ,customerName:customernamex,trade:tradex,orderedAt:orderedatx} ,notifyOnNetworkStatusChange: true});
  const { loading, error, data } = query;
  console.log(data)
  let items: any = getLineItems(data);
console.log(items)
  const suspiciousorderx:string = String(data?.order?.suspiciousorder);
  const suspiciousorderxval:string = suspiciousorderx == 'true' ? 'suspicious':'clearflag'
  let customerview = '0';
  if (window.location.href.indexOf("customers") > -1) {
      customerview = '1';
  }
 const [state, setState] = React.useState({
    suspiciousval: suspiciousorderxval
  })
  const[valuefile,setvaluefile]= React.useState('');
  const [sort] = useQueryParam('sort', CommaArrayParam);
  const [page] = useQueryParam<number>('page', NumberParam);
  const [limit] = useQueryParam<number>('limit', NumberParam);
  const [dateRange] = useQueryParams({ start: StringParam, end: StringParam });
  const [q] = useQueryParam<string>('q', StringParam);
  
  React.useEffect(function persistForm() {
    if( suspiciousorderxval !== undefined ){
      setState({
    suspiciousval: suspiciousorderxval
  });
      }
	  
  },[suspiciousorderxval]);

  const formRef:any = useRef();

   
  const handleChange = async (evt: React.FormEvent<HTMLInputElement>) => {

  if (formRef.current && evt.currentTarget.value !== 'clearflag') {
     if(formRef.current.values.clearflag && formRef.current.values.message.length > 0 && formRef.current.values.message.join("") !== ""){
	    alert('Clearing of Flags in Progress, press SUBMIT to Save or clear your entries before changing to Suspicious Flag Type.');
        return false;
	 }
      if(formRef.current.values.clearflag && formRef.current.values.clearflag.length > 0 && formRef.current.values.clearflag.join("") !== ""){
        alert('Clearing of Flags in Progress, press SUBMIT to Save or clear your entries before changing to Suspicious Flag Type.');
        return false;
      }
	  if(formRef.current.values.clearflag && formRef.current.values.ClearAllComment !== ""){
	    alert('Clearing of Flags in Progress, press SUBMIT to Save or clear your entries before changing to Suspicious Flag Type.');
        return false;
	 }
	 if(formRef.current.values.clearflag && formRef.current.values.checkAllflagorder !== false){
	    alert('Clearing of Flags in Progress, press SUBMIT to Save or clear your entries before changing to Suspicious Flag Type.');
        return false;
	 }
	 if(formRef.current.values.clearflag && formRef.current.values.checkflagorder !== false){
	    alert('Clearing of Flags in Progress, press SUBMIT to Save or clear your entries before changing to Suspicious Flag Type.');
        return false;
	 }
  }
  let suspiciousorder1 = evt.currentTarget.value;
  const value =evt.currentTarget.value;
  setState({
    ...state,
    [evt.currentTarget.name]: value
  });
  
  let suspiciousorder1x = (value == 'suspicious') ? true:false;
  
  try {
   
  	 await updateSuspiciousOrder({
        variables: {
          input: {
           orderId: id,requestId:requestid,customerId: customeridx ,customerName:customernamex,trade:tradex,orderedAt:orderedatx,suspiciousorder:suspiciousorder1x,
          },
        },
        
      });
	  query.refetch();
     
	  
 } catch (err) {
   console.error(err);
 }
};
 
  const query1 = useListOrdersQuery({
    variables: {
      q,
      limit,
      page,
      sort,
      ...dateRange,
    },
    fetchPolicy: 'no-cache'
  });
  
  const idx:string = String(data?.order?.customerId);
  
  const query2 = useListCustomerOrdersQuery({
    variables: {
      id: idx,
      q,
      limit,
      page,
      sort,
      ...dateRange,
    },
  });
  
  // Get Line items from the response data
 
  // Loading state
  if (loading) {
    return <CircularProgress />;
  }

  // Error State
  if (error) {
    return <div>{error.message}</div>;
  }
  
  const uploadAndsubmitbtn = () => {
    
    const flaglen = data?.order?.flags?.length ?? 0
    console.log(data?.order)
    console.log(items.algorithmExist ,"algorithmExist")
    if( flaglen === 0 && !items.algorithmExist){
      return false;
    }
     console.log(data?.order?.clearFlag ,"clearFlag")
    if ((!data?.order?.clearFlag)) {
      return true;
    }
    if( items.algorithmExist){
      return true;
    }
   
    
    return false;
  };
  const documentsbtn = () => {
    
    const flaglen = data?.order?.documents?.length ?? 0
    if( flaglen === 0 ){
      return true;
    }
    
    
    return false;
  };
  const ndcandorderExist = () =>{
    const flaglen = data?.order?.flags?.length ?? 0
    const flagsndclen = data?.order?.flagsndc?.length ?? 0
   
    if( flaglen === 0 && flagsndclen === 0 && items.lineItems.length === 0){
      return true;
    }
    
    return false;
  };
  console.log(new Array(items.lineItems.length).fill(''))
  // Get Initial Values
  const initialValues =  {
    orderId: id,
    requestId:data?.order?.requestId ?? '',
    customerId:data?.order?.customerId?? '',
    customerName:data?.order?.customerName?? '',
    trade:data?.order?.trade?? '',
    orderedAt:data?.order?.orderedAt?? '',
    clearflag: [''],
    message: new Array(items.lineItems.length).fill(''),
    file: '',
    messageorder:'',
    commonmessage:'',
    checkflagorder:false,
    ClearAllComment:'',
    checkAllflagorder:false,

  }
  let commondocument = '';
  
  
  async function  addDocumentData(){
 
    const lsOutput:any = document.getElementById('file1');
     const commonmsg = lsOutput.value;
     if(commonmsg === ''){
      const commonmessageerror:any = document.getElementById('commondocumenterror');
      commonmessageerror.innerHTML = "Please attach a document";
       
    }else{
      
      const lsOutput:any = document.getElementById('btnclsdocument');
      lsOutput.setAttribute('disabled', true);
     // lsOutput.classList.add("btndisabled"); 
     try {
      await addDocuments({
        variables: {
          input: {
            orderId: id,
            requestId:data?.order?.requestId ?? '',
            customerId:data?.order?.customerId?? '',
                  customerName:data?.order?.customerName?? '',
                  trade:data?.order?.trade?? '',
                  orderedAt:data?.order?.orderedAt?? '',
            commondocument: valuefile,
          },
        },
        
      });
      const lsOutput8:any = document.getElementById('btnclsdocument');
      lsOutput8.removeAttribute('disabled', false);

     // lsOutput8.classList.remove("btndisabled");
      cancelDocumentData();

      const lsOutput2:any = document.getElementById('file1');
  lsOutput2.value ='';
  const commondocumenterror:any = document.getElementById('commondocumenterror');
  commondocumenterror.innerHTML  = '';
      query.refetch();
      query1.refetch();
    } catch (err) {
      console.error(err);
    }
  }
  }



  //console.log(data)
  return (
    
    <div id="clear-flag-form">
        {!ndcandorderExist() &&
		
          <button onClick={() => printform()} className="printcls">PRINT</button>
}
  {uploadAndsubmitbtn() &&
  		<div className="suspiciouscls">
		<label className="radiocls">
		  <span className="radio__input">
			<input
            type="radio"
            name="suspiciousval"
            value="clearflag"
            checked={state.suspiciousval === "clearflag"}
            onChange={handleChange}
          />
			<span className="radio__control"></span>
		  </span>
		  <span className="radio__label">Clear Flag</span>
		</label>
		<label className="radiocls">
		  <span className="radio__input">
			<input
            type="radio"
            name="suspiciousval"
            value="suspicious"
            checked={state.suspiciousval === "suspicious"}
            onChange={handleChange}
          />
			<span className="radio__control"></span>
		  </span>
		  <span className="radio__label">Suspicious Flag </span>
		</label>
		
       
		</div>
		}
		
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={async(values,{ resetForm }) => {
          const messageList: Algorithm[] = [];
          
          values?.message?.forEach((value: any, index: number) => {
            if(value!==''){
              messageList.push({
                clearflag: values.clearflag[index][0] ?? "",
                message: value ?? '',
              })
            }
          })
    
          console.log(messageList, "messagelist");
    
          try {
            
  const lsOutput:any = document.getElementById('submitclsdocument');
  lsOutput.setAttribute('disabled', true);
 // lsOutput.classList.add("btndisabled");  
            await createNote({
              variables: {
                input: {
                  orderId:id,
                  requestId:data?.order?.requestId?? '',
                  customerId:data?.order?.customerId?? '',
                  customerName:data?.order?.customerName?? '',
                  trade:data?.order?.trade?? '',
                  orderedAt:data?.order?.orderedAt?? '',
                  messageList: messageList,
                  files:values.file,
                  clearAllComment: values.ClearAllComment,
                  checkAllflagorder: values.checkAllflagorder,
                  checkflagorder: values.checkflagorder,
                  messageorder: values.messageorder
                }
              },
              update: (store, { data }) => {
                if (!data) {
                  return;
                }
                const lsOutput8:any = document.getElementById('submitclsdocument');
                lsOutput8.removeAttribute('disabled', false);

                //lsOutput8.classList.remove("btndisabled");
                resetForm();
                query.refetch();
               
               // const { loading, error, data1 } = query;
                query1.refetch();
                //const lsOutput:any = document.getElementById('file');
              // lsOutput.value ='';
              
            
                if( customerview === '1' ){
                    query2.refetch();
                }
               
              },
          });
    
            //formikHelpers.resetForm();
          } catch (err) {
            console.error(err);
          }
        }}
        validate={
          (values) => {
            const errors: any = {}
           
            // Clear All Box
            if(values.checkAllflagorder === true && values.ClearAllComment === ''){
			 if(data?.order?.suspiciousorder  === 'true'){
			 	errors.checkallcomment = "Please enter reason"
			 }else{
			 	errors.checkallcomment = "Please enter comment"
			 }
              
            }
           
            // Order Flag comment box
            if(values.checkflagorder === true && values.messageorder === ''){
				if(data?.order?.suspiciousorder  === 'true'){
              		errors.messageorder = "Please enter reason"
				}else{
					errors.messageorder = "Please enter comment"
				}
            }
            // Checks if message is empty when clearflag is checked
            // Validate only when clear all flag is false
            if(values.checkAllflagorder !== true){
              values.clearflag.forEach((_, index: number) => {

                if(!values.message[index] && values?.clearflag[index]?.length > 0){
					if(data?.order?.suspiciousorder  === 'true'){
                  		errors[`message${index}`] = "Please enter reason"
					}else{
						errors[`message${index}`] = "Please enter comment"
					}
                }
  
              })
            }
          
            return errors;
          }
        }
        render={({
          errors,
          setFieldValue
        }) => (

        <Form>
        	
          <NDCFlags items={items} orders={data?.order} errors={errors} />

          <OrderFlags flaggedorder = {data?.order?.flaggedorder} approvalcountOrders={data?.order?.approvalcountOrders} orderFlags={data?.order?.flagsorders} orders={data?.order} cleared={data?.order?.clearFlag } errors={errors} notes={data?.order?.notes ?? []}/>
          {ndcandorderExist() &&
            <div className={classes.filecls}>No ndc based and order based flags.</div>
        }
         
          {uploadAndsubmitbtn() && <>
          <div className={classes.filecls}>
            <label className={classes.filecls}>Document Upload</label>
            <input id="file" name="file" type="file" 
              onChange={(event: any) => {
                setFieldValue("file", event.currentTarget.files[0]);
              }} 
              className="form-control" />
          </div>
          <div className={classes.submitcls}>
          <button type="submit" id="submitclsdocument" disabled={loading} className={classes.btncls}>
            Submit
          </button>
          </div>
          </>
        }
        

      <Divider />
      <Divider />

      {documentsbtn() && 
        <List className={classes.list}>
        <ListSubheader className={classes.subheader}>Documents</ListSubheader>
        <button type="button" onClick={() => adddocuments()} className="notecls" id="documentcls">Add Document</button>
          <div id="documentdiv" className="documentclsdiv">
          
          <div className={classes.filecls}>
            <label className={classes.filecls}>Document Upload</label>
            <input id="file1" name="file1" type="file" 
              onChange={(event: any) => {
                setvaluefile(event.currentTarget.files[0]);
              }} className="form-control" />
               <div className={classes.errorclsmsg} id="commondocumenterror"></div>
          </div>
         
          <div className={classes.submitcls}>
            <button type="button"  className={classes.btnclssave} id="btnclsdocument" onClick={() => addDocumentData()}>
            Save Document
            </button>
            <button type="button"  className={classes.btnclscancel} onClick={() => cancelDocumentData()}>
              Cancel
            </button>
            </div>
            </div>  
        <ListItem> No documents found.</ListItem>
        </List>
    
      }
     {!documentsbtn() && 
        <List className={classes.list}>
        <ListSubheader className={classes.subheader}>Documents</ListSubheader>
        <button type="button" onClick={() => adddocuments()} className="notecls" id="documentcls">Add Document</button>
          <div id="documentdiv" className="documentclsdiv">
          
          <div className={classes.filecls}>
            <label className={classes.filecls}>Document Upload</label>
            <input id="file1" name="file1" type="file" 
            onChange={(event: any) => {
              setvaluefile(event.currentTarget.files[0]);
            }} className="form-control" />
             <div className={classes.errorclsmsg} id="commondocumenterror"></div>
          </div>
         
          <div className={classes.submitcls}>
            <button type="button"  className={classes.btnclssave} id="btnclsdocument" onClick={() => addDocumentData()}>
            Save Document
            </button>
            <button type="button"  className={classes.btnclscancel} onClick={() => cancelDocumentData()}>
              Cancel
            </button>
            </div>
            </div>  
          
            {
        data?.order?.documents?.map((note: INote) => (
          <ListItem
            key={note.noteId!}
            divider
          >
            <div
              className={classes.listText} >
              <ListItemText
                primary={
                  <>
                    <Typography
                      // variant="title"
                    >
                      {note.user!.name}
                    </Typography>
                    <Typography
                      variant="caption"
                    >
                      {DateTime.fromMillis(parseInt(note.createdAt!, 10)).toLocaleString(DateTime.DATETIME_MED)}
                      &nbsp;&nbsp;&nbsp;&nbsp;<b>{note.fileName !='' && note.fileName}</b>
                    </Typography>
                  </>
                }
                secondary={
                  <>
                    <Typography>{note.message}</Typography>
                    {
                      !!note.attachment && <ViewAttachmentButton noteId={note.noteId!} />
                    }
                  </>
                }
              />
            </div>
          </ListItem>
        ))
      }
        </List>
    
      }
  
      <Divider />
      <Divider />

      <NotesList notes={data?.order?.notes ?? []} errors={errors} orderId={id} requestId={data?.order?.requestId?? ''} customerId={data?.order?.customerId?? ''} customerName={data?.order?.customerName?? ''} trade={data?.order?.trade?? ''} orderedAt={data?.order?.orderedAt?? ''} />
      
      <Divider />
      </Form>
       )} />
    </div>
  );
};

export default NotesDrawerContent;
