import { Typography } from "@material-ui/core";
import { ApolloError } from 'apollo-client';
import * as RA from 'ramda-adjunct';
import * as React from 'react';

interface Props {
  error?: string | Error | ApolloError;
  children?: any
}

function ErrorMessage(props: Props) {
  const { error, children } = props;

  switch (true) {
    case error instanceof ApolloError:
      return (error as ApolloError).graphQLErrors.map((error, index) => (
        <Typography variant="body2" color="error" key={index}>
          {error.message}
        </Typography>
      ));
    case error instanceof Error:
      return (
        <Typography variant="body2" color="error">
          {(error as Error).message}
        </Typography>
      );
    case RA.isNotNil(children):
      return children;
    default:
      return null;
  }
}

export default ErrorMessage;
