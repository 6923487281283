import { Snackbar } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Maybe } from '../../generated/graphql';

export interface NotificationContext {
  setMessage(message?: string): void;
}

export const NotificationContext = React.createContext<Maybe<NotificationContext>>(null);

interface Props {
  children: any;

  autoHideDuration?: number | null;
}

export const NotificationProvider = (props: Props) => {
  const { children, autoHideDuration = 5000 } = props;
  const [message, setMessage] = useState<string | undefined>(undefined);

  return (
    <NotificationContext.Provider
      value={{
        setMessage,
      }}
    >
      {children}

      <aside>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!message}
          autoHideDuration={autoHideDuration}
          message={<span>{message}</span>}
          onClose={() => setMessage(undefined)}
        />
      </aside>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = React.useContext(NotificationContext);

  if (!context) {
    throw new Error('NotificationContext missing');
  }

  return context;
};
