import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import EditUserModal from './EditUserModal';

export interface IEditUserModalContext {
  open(id: string,title:string,name:string,email: string,role: string,enabled: boolean): void;
}

export const EditUserModalContext = React.createContext<Maybe<IEditUserModalContext>>(null);

interface Props {
  children: any;
}

export const EditUserModalProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);

  const [id, setId] = React.useState<Maybe<string>>();
  const [title, settitle] = React.useState<string>('');
  const [name, setname] = React.useState<string>('');
  const [email, setemail] = React.useState<string>('');
  const [role, setrole] = React.useState<string>('');
  const [enabled, setenabled] = React.useState(true);
  return (
    <EditUserModalContext.Provider
      value={{
        open: (id: string,title:string,name:string,email: string,role: string,enabled: boolean) => {
          setId(id);
          settitle(title);
          setname(name);
          setemail(email);
          setrole(role);
          setenabled(enabled);
          setOpen(true);
        },
      }}
    >
      {children}

      <EditUserModal
        open={open}
        id={id}
        title={title}
        name={name}
         email={email}
          role={role}
          enabled={enabled}
        onRequestClose={() => setOpen(false)}
      />
    </EditUserModalContext.Provider>
  );
};

export const useEditUserModal = () => {
  const context = React.useContext(EditUserModalContext);

  if (!context) {
    throw new Error('EditUserModalContext missing');
  }

  return context;
};
