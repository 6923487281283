import * as React from 'react';
import { Maybe } from '../../generated/graphql';
import FlagUserModal from './FlagUserModal';

interface params {
  userid: string,
  orderid: string,
  requestid: string,
  ndc:string
}
export interface IFlagUserModalContext {
  open(data:params): void;
}

export const FlagUserModalContext = React.createContext<Maybe<IFlagUserModalContext>>(null);

interface Props {
  children: any;
}

export const FlagUserModalProvider = (props: Props) => {
  const { children } = props;
  const [value, setValue] = React.useState({open:false,data:{
    userid: '',
    orderid: '',
    requestid: '',
    ndc:''
  }});
 
  return (
    <FlagUserModalContext.Provider
      value={{
        open: (params: params) => setValue(Object.assign({},value,{open:true,data:params}))
      }}
    >
      {children}

      <FlagUserModal
        open={value.open}
        data={value.data}
        onRequestClose={() => setValue(Object.assign({},value,{open:false,data:{}}))}
      />
    </FlagUserModalContext.Provider>
  );
};

export const useFlagUserModal = () => {
  const context = React.useContext(FlagUserModalContext);

  if (!context) {
    throw new Error('FlagUserModalContext missing');
  }

  return context;
};
