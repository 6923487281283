import { createStyles, Divider, Drawer, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as React from 'react';
import { Maybe, useGetNotesDrawerContentQuery } from '../../generated/graphql';
import NotesDrawerContent from './components/NotesDrawerContent';

import { DateTime } from "luxon";
const drawerWidth = 1045;

const useStyles = makeStyles(() => createStyles({
  drawerPaper: {
    width: drawerWidth,
    display: 'block',
  },
  input: {
    // padding: theme.spacing.unit,
    alignSelf: 'end',
  },
  form: {
    display: 'flex',
  },
  checkbox: {
    // marginLeft: theme.spacing.unit,
  },
 
}));

interface Props {
  id?: Maybe<string>;
  requestid: string;
  customerid: string;
   customername: string;
    trade: string;
    orderedat: string;
   open?: boolean

  onRequestClose?(): void
}

function NotesDrawer(props: Props) {
  console.log(props)
  const { id, open, onRequestClose,requestid,customerid,customername,trade,orderedat } = props;
  const classes = useStyles();
  const idx:string = String(id);
  const requestidx:string = String(requestid);
  const customeridx:string = String(customerid);
  const customernamex:string = String(customername);
  const tradex:string = String(trade);
  const orderedatx:string = String(orderedat);
  const { data } = useGetNotesDrawerContentQuery({ variables: { orderId: idx ,requestId:requestidx,customerId: customeridx ,customerName:customernamex,trade:tradex,orderedAt:orderedatx} });
  
  let customernameval='';
  let detailstitle = '';
  let orderdate='';
  if( data?.order?.customerId ){
     detailstitle = ' | Customer ID: '+data?.order?.customerId;
  }
  if(data?.order?.customerName && data?.order?.customerName !=null){
     customernameval = ' | '+data?.order?.customerName
     detailstitle = detailstitle+ customernameval
  }
 if(data?.order?.orderedAt && data?.order?.orderedAt !=null){
  orderdate= ' on Order Date: '+DateTime.fromISO(data?.order?.orderedAt!.substring(0, 10)).toFormat('MMMM dd, yyyy')
  detailstitle = detailstitle+ orderdate
 }
 function formatDate(date:any) {
  let d = new Date(date);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if(day.length < 2){
    day = '0' + day;
  } 
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
  return monthNames[d.getMonth()]+' '+day+', '+ year;
}
  return (
   
    <Drawer
      open={open}
      id="drawer-notes"
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onRequestClose}
    >
      <Toolbar>
        <IconButton onClick={onRequestClose}>
          <ChevronRightIcon />
        </IconButton>

        <Typography>
        Details for Order ID : {id} {detailstitle}
         
        </Typography>
      </Toolbar>

      <Divider />

      {open && id && <NotesDrawerContent id={id} requestid={requestid} customerid={customerid} customername={customername} trade={trade} orderedat={orderedat} />}
    </Drawer>
  );
}

export default NotesDrawer;
