import { List, Table, TableBody, ListSubheader, TableRow, TableCell ,TableHead} from "@material-ui/core"
import * as React from "react"
import { renderRowCell,renderFlagCell } from "./NotesDrawerContent.style"
import CommentBox from "./CommentBox"
import ClearAllbox from "./ClearAllBox"
import { getStyles } from './NotesDrawerContent.style';


const NDCFlags = (props: any) => {
  console.log(props.orders.tenantDataForHeader,"lekshmi")
 const classes = getStyles();
  function createMarkup(desc: any) {
    return {__html: desc};
}
const ndcclearedFlag = (orderItem: any) => { 
  if(orderItem.issuspicious ==='true' && orderItem.cleared ==='true'){
    return (
      (<><br/><b>(Flag Cleared)</b></>)
    )
  }
  if(orderItem.issuspicious ==='true' ){
    return (
	
      (<><br/><b className={classes.markassuscls}>(Marked as Suspicious)</b></>)
    )
  }
  if(orderItem.cleared ==='true'){
      return (
        (<><br/><b>(Flag Cleared)</b></>)
      )
    }
};

    return (
        <List dense style={{ flex: 0 }}>
          {(props.orders.tenantDataForHeader && props.orders.tenantDataForHeader[0].zipcode!='' )&& 
          <ListSubheader style={{ lineHeight: '25px' }}><b>Shipped to State :</b> { props.orders.tenantDataForHeader[0].tenantState}<b><br/>Shipped to Zip Code :</b> { props.orders.tenantDataForHeader[0].zipcode}<br/> <b>NDC Based Flags</b>: { props.items.algorithmCount}</ListSubheader>
          }
          {(props.orders.tenantDataForHeader && props.orders.tenantDataForHeader[0].zipcode=='') && 
          <ListSubheader style={{ lineHeight: '25px' }}><b>Shipped to State :</b> { props.orders.tenantDataForHeader[0].tenantState}<b>NDC Based Flags</b>: { props.items.algorithmCount}</ListSubheader>
          }     
          {!props.orders.tenantDataForHeader &&   
          <ListSubheader><b>NDC Based Flags</b>: { props.items.algorithmCount}</ListSubheader>
          }
          <Table>
          <TableHead>
            <TableRow>
              <TableCell>NDC</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>ME</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
              {
                props.items.lineItems.map((orderItem :any, index: number) => (
                  <React.Fragment key={`orderItem.ndc_${index}`} >
                    <TableRow className={renderFlagCell(orderItem,props.orders)}>
                      <TableCell>{orderItem.ndc}{ndcclearedFlag(orderItem)}</TableCell>
                      <TableCell>{orderItem.lineQuantity}</TableCell>
                      <TableCell>{orderItem.lineTotal}</TableCell>
                      <TableCell>{orderItem.me}</TableCell>
                      <TableCell>{orderItem.productName}</TableCell>
                      <TableCell><div dangerouslySetInnerHTML={createMarkup(orderItem.algorithm)}/></TableCell>
                    </TableRow>
                   <CommentBox orderItem={orderItem} orders={props.orders} index={index} errors={props.errors} />
                  
                  </React.Fragment>
                ))
              }
                
                { (props.orders.suspiciousorder == 'false' ) &&  props.items.algorithmExist && props.items.algorithmCount > 1 && <ClearAllbox errors={props.errors} orders={props.orders} /> }
            
            </TableBody>
          </Table>
        </List>
    )
}

export default NDCFlags;