import { Tab, Tabs } from '@material-ui/core';
import * as R from 'ramda';
import * as React from 'react';

interface Props {
  index?: number
  tabs: Array<{ name: string }>

  onChange?(event: React.ChangeEvent<any>, value: any): void
}

function Header(props: Props) {
  const { index = 0, tabs, onChange } = props;
  return (
    <Tabs
      value={index}
      onChange={onChange}
    >
      {
        R.map((tab: { name: string }) => (
          <Tab
            key={tab.name}
            label={tab.name}
          />
        ))(tabs)
      }
    </Tabs>
  )
}

export default Header;
