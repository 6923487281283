import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useState } from 'react';
import { useImportOrdersMutation } from '../../generated/graphql';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import IconButton from '../IconButton';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';



interface Props {
  open: boolean;

  onRequestClose(): void;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function ImportModal(props: Props) {
  const { open, onRequestClose } = props;
  const [files, setFiles] = useState<Array<File>>([]);
  const [importOrders, { loading, error }] = useImportOrdersMutation();



  const classes = useStyles();
  const [openx, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!openx);
  };




  const handleRemoveFile = (index: number) => () => {
    setFiles(R.remove(index, 1, files));
  };

  const handleUpload = async () => {
    try {
      handleToggle();
      const vresponse = await importOrders({ variables: { file: files[0] } });
      console.log(await vresponse);


      
      setTimeout(function(){ 
        window.location.reload();
        onRequestClose();
        handleClose();
      }, 5000);

      
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
    >
      <Backdrop className={classes.backdrop} open={openx} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle>Import Orders</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography>
            Please make sure the following requirements are met:
          </Typography>
          <List>
            <ListItem>
              <Typography>&bull; Must be a CSV file</Typography>
            </ListItem>
            <ListItem>
              <Typography>
                &bull; CSV file must include these headers: Order ID, Order
                Date, Customer ID, Customer Name, Zip Code, Trade, NDC, Quantity, Line Total
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                &bull; Make sure headers don't have any extra spaces
              </Typography>
            </ListItem>
          </List>
        </DialogContentText>

        <Button
          variant="outlined"
          component="label"
          startIcon={<AttachFileIcon />}
        >
          Select File
          <input
            type="file"
            accept="text/csv"
            onChange={({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
              !!files && files.length && setFiles(Array.from(files));
            }}
            hidden
          />
        </Button>

        <List>
          {
            RA.mapIndexed((file: File, index: number) => (
              <ListItem key={file.name}>
                <div style={{ flex: 1 }}>
                  <ListItemText primary={(file as any).name} />
                </div>
                {
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={handleRemoveFile(index)}
                      disabled={loading}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                }
              </ListItem>
            ))(files)
          }
        </List>

        <ErrorMessage error={error} />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() => onRequestClose()}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={loading || !files.length}
          loading={loading}
          onClick={handleUpload}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportModal;
