import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  GraphQLDateTime: any;
  Upload: any;
};

export type IAddDocumentsInput = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['String']>;
  commondocument?: Maybe<Scalars['Upload']>;
};

export type IAddNoteInput = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['String']>;
  commonmessage?: Maybe<Scalars['String']>;
};

export type IAlgorithmAnalytic = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flagCount?: Maybe<Scalars['Int']>;
  orderCount?: Maybe<Scalars['Int']>;
  ndcFlagCount?: Maybe<Scalars['Int']>;
  algtype?: Maybe<Scalars['String']>;
};

export type IAlgorithmdisableexport = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type IAlgorithmexport = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type IAlgorithms = {
  clearflag?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type IAnalytics = {
  id?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  stats?: Maybe<IStats>;
  date?: Maybe<IDate>;
};

export type IApprovalcount = {
  total?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
};

export type IApprovalcountOrders = {
  total?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

export type IApprovaluser = {
  userId?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

export type IBuildVersion = {
  buildversion?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['ID']>;
};

export type IControlledFlaggedAnalytic = {
  ndc?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type IControlledNdcFlaggedAnalytic = {
  ndc?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ICreateEmailInput = {
  orderId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ICreateNoteInput = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
  files?: Maybe<Scalars['Upload']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['String']>;
  messageList?: Maybe<Array<Maybe<IAlgorithms>>>;
  clearAllComment?: Maybe<Scalars['String']>;
  checkAllflagorder?: Maybe<Scalars['Boolean']>;
  checkflagorder?: Maybe<Scalars['Boolean']>;
  messageorder?: Maybe<Scalars['String']>;
};

export type ICreateUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  title: Scalars['String'];
  role: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ICreateWebhookInput = {
  url: Scalars['String'];
};

export type ICreateWhatsNewHistoryInput = {
  description?: Maybe<Scalars['String']>;
};

export type ICustomer = {
  id: Scalars['ID'];
  customerId: Scalars['ID'];
  customerName?: Maybe<Scalars['String']>;
  lastOrderedAt?: Maybe<Scalars['GraphQLDateTime']>;
  orders?: Maybe<IPaginatedOrders>;
};


export type ICustomerOrdersArgs = {
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  filtersettings?: Maybe<Scalars['String']>;
};

export type IDashboardSetting = {
  name?: Maybe<Scalars['String']>;
  defaultfiltersettings?: Maybe<Scalars['String']>;
};

export type IDate = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
};

export type IDeleteWebhookInput = {
  webhookId: Scalars['ID'];
};

export type IDosageFormAnalytics = {
  dosageForm?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type IDrugFamilyAnalytics = {
  drugFamilyNumber?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type IDrugFamilyNdcAnalytics = {
  drugFamilyNumber?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type IDrugFormAnalytics = {
  dosageForm?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  packageSum?: Maybe<Scalars['String']>;
  qtyCase?: Maybe<Scalars['Float']>;
  packageDescription?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type IFetchWhatsNewData = {
  whatsnewId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['String']>;
};

export type IFile = {
  id: Scalars['ID'];
  tenantId?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['ID']>;
  type?: Maybe<IFileType>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type IFileType = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['GraphQLDateTime']>;
  updatedAt?: Maybe<Scalars['GraphQLDateTime']>;
};

export type IFilterSetting = {
  defaultfiltersettings?: Maybe<Scalars['String']>;
};

export type IFlaggedClassOfTrade = {
  customerId?: Maybe<Scalars['ID']>;
  orderId?: Maybe<Scalars['ID']>;
  trade?: Maybe<Scalars['String']>;
  orderYear?: Maybe<Scalars['Int']>;
  orderMonth?: Maybe<Scalars['Int']>;
  orderQuantity?: Maybe<Scalars['Int']>;
  tradeAverageQuantity?: Maybe<Scalars['Float']>;
};

export type IFlagsndc = {
  total?: Maybe<Scalars['String']>;
  algorithm?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['String']>;
  cleared?: Maybe<Scalars['String']>;
  threshold?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  startdate?: Maybe<Scalars['String']>;
  enddate?: Maybe<Scalars['String']>;
  familynumber?: Maybe<Scalars['String']>;
  orderIds?: Maybe<Scalars['String']>;
};

export type IFlagsndcnew = {
  ndc?: Maybe<Scalars['String']>;
  suspicious?: Maybe<Scalars['Boolean']>;
};

export type IFlagsordernew = {
  orderId?: Maybe<Scalars['String']>;
  suspicious?: Maybe<Scalars['Boolean']>;
};

export type IFlagsorders = {
  total?: Maybe<Scalars['String']>;
  algorithm?: Maybe<Scalars['String']>;
  cleared?: Maybe<Scalars['String']>;
  threshold?: Maybe<Scalars['Int']>;
};

export type IGetbuildversion = {
  buildversion?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['ID']>;
};

export type IGettabview = {
  showdashboardtab?: Maybe<Scalars['String']>;
};


export type IMonthlyCustomerOrderAnalytics = {
  orderYear?: Maybe<Scalars['Int']>;
  orderMonth?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
  totalOrderTotal?: Maybe<Scalars['Float']>;
  totalOrderMe?: Maybe<Scalars['Float']>;
  averageOrderQuantity?: Maybe<Scalars['Float']>;
  averageOrderTotal?: Maybe<Scalars['Float']>;
  averageOrderMe?: Maybe<Scalars['Float']>;
};

export type IMonthlyOrderAnalytics = {
  orderYear?: Maybe<Scalars['Int']>;
  orderMonth?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  totalOrderQuantity?: Maybe<Scalars['Float']>;
  totalOrderTotal?: Maybe<Scalars['Float']>;
  totalOrderMe?: Maybe<Scalars['Float']>;
  averageOrderQuantity?: Maybe<Scalars['Float']>;
  averageOrderTotal?: Maybe<Scalars['Float']>;
  averageOrderMe?: Maybe<Scalars['Float']>;
};

export type IMonthlyTradeAnalytics = {
  trade?: Maybe<Scalars['String']>;
  stats?: Maybe<IStats>;
  date?: Maybe<IDate>;
};

export type IMutation = {
  createWhatsNewHistory?: Maybe<IWhatsNewData>;
  createOAuth2Client?: Maybe<IOAuth2Client>;
  updateSettings?: Maybe<IUpdatesettingsresponse>;
  updatePageSettings?: Maybe<IUpdatesettingsresponse>;
  updatePassphraseSettings?: Maybe<IUpdatesettingsresponse>;
  updateUserSettings?: Maybe<IUpdatesettingsresponse>;
  updateUserClearedSettings?: Maybe<IUpdatesettingsresponse>;
  uploadFile?: Maybe<IFile>;
  getFileSignedUrl?: Maybe<Scalars['String']>;
  deleteFlags: Scalars['Boolean'];
  runAlgorithms: Scalars['Boolean'];
  importOrders?: Maybe<Scalars['Boolean']>;
  createNote?: Maybe<IUpdatenoteresponse>;
  createEmail?: Maybe<IUpdatenoteresponse>;
  addNotes?: Maybe<IUpdatenoteresponse>;
  addDocuments?: Maybe<IUpdatenoteresponse>;
  updateSuspiciousOrder?: Maybe<IUpdatenoteresponse>;
  deleteRequest?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<IUser>;
  updateUser?: Maybe<IUser>;
  updateUserRole?: Maybe<IUser>;
  createWebhook?: Maybe<IWebhook>;
  updateWebhook?: Maybe<IWebhook>;
  deleteWebhook?: Maybe<Scalars['Boolean']>;
};


export type IMutationCreateWhatsNewHistoryArgs = {
  input?: Maybe<ICreateWhatsNewHistoryInput>;
};


export type IMutationUpdateSettingsArgs = {
  input: IUpdateSettingsInput;
};


export type IMutationUpdatePageSettingsArgs = {
  input: IUpdatePageSettingsInput;
};


export type IMutationUpdatePassphraseSettingsArgs = {
  input: IUpdatePassphraseSettingsInput;
};


export type IMutationUpdateUserSettingsArgs = {
  input: IUpdateUserSettingsInput;
};


export type IMutationUpdateUserClearedSettingsArgs = {
  input: IUpdateUserClearedSettingsInput;
};


export type IMutationUploadFileArgs = {
  input: IUploadFileInput;
};


export type IMutationGetFileSignedUrlArgs = {
  id: Scalars['ID'];
};


export type IMutationDeleteFlagsArgs = {
  requestId: Scalars['ID'];
};


export type IMutationRunAlgorithmsArgs = {
  requestId: Scalars['ID'];
};


export type IMutationImportOrdersArgs = {
  file: Scalars['Upload'];
};


export type IMutationCreateNoteArgs = {
  input: ICreateNoteInput;
};


export type IMutationCreateEmailArgs = {
  input: ICreateEmailInput;
};


export type IMutationAddNotesArgs = {
  input: IAddNoteInput;
};


export type IMutationAddDocumentsArgs = {
  input: IAddDocumentsInput;
};


export type IMutationUpdateSuspiciousOrderArgs = {
  input: IUpdateSuspiciousOrderInput;
};


export type IMutationDeleteRequestArgs = {
  requestId?: Maybe<Scalars['ID']>;
};


export type IMutationCreateUserArgs = {
  input: ICreateUserInput;
};


export type IMutationUpdateUserArgs = {
  input: IUpdateUserInput;
};


export type IMutationUpdateUserRoleArgs = {
  input: IUpdateUserRoleInput;
};


export type IMutationCreateWebhookArgs = {
  input: ICreateWebhookInput;
};


export type IMutationUpdateWebhookArgs = {
  input: IUpdateWebhookInput;
};


export type IMutationDeleteWebhookArgs = {
  input: IDeleteWebhookInput;
};

export type INdc = {
  ndc?: Maybe<Scalars['ID']>;
  size?: Maybe<Scalars['Float']>;
  meCoefficient?: Maybe<Scalars['Float']>;
  packageSum?: Maybe<Scalars['String']>;
  packageDescription?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  dosageForm?: Maybe<Scalars['String']>;
  qtyCase?: Maybe<Scalars['Float']>;
  rxOtc?: Maybe<Scalars['String']>;
  me?: Maybe<Scalars['Float']>;
  controlled?: Maybe<Scalars['Boolean']>;
  deaFamilyNumber?: Maybe<Scalars['String']>;
  labeler?: Maybe<Scalars['String']>;
};

export type INdcAnalytics = {
  ndc?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  averageLineQuantity?: Maybe<Scalars['Float']>;
  averageLineTotal?: Maybe<Scalars['Float']>;
};

export type INote = {
  user?: Maybe<IUser>;
  order?: Maybe<IOrder>;
  noteId?: Maybe<Scalars['ID']>;
  orderId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  attachment?: Maybe<Scalars['String']>;
  noteType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  clearFlag?: Maybe<Scalars['Boolean']>;
  ndc?: Maybe<Scalars['String']>;
  issuspicious?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type IOAuth2Client = {
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type IOrder = {
  lineItems?: Maybe<Array<IOrderLineItem>>;
  flagged?: Maybe<Scalars['Boolean']>;
  flags?: Maybe<Array<Scalars['String']>>;
  flagsndc?: Maybe<Array<IFlagsndc>>;
  flagsorders?: Maybe<Array<IFlagsorders>>;
  notes?: Maybe<Array<INote>>;
  flaggedndc?: Maybe<Array<IFlagsndcnew>>;
  tenantDataForHeader?: Maybe<Array<ITenantDataForHeader>>;
  approvalcount?: Maybe<Array<IApprovalcount>>;
  approvaluser?: Maybe<Array<IApprovaluser>>;
  approvalcountOrders?: Maybe<Array<IApprovalcountOrders>>;
  flaggedorder?: Maybe<Array<IFlagsordernew>>;
  documents?: Maybe<Array<INote>>;
  clearFlag?: Maybe<Scalars['Boolean']>;
  suspiciousorder?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
  orderUuid?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  customerName?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['ID']>;
  requestId?: Maybe<Scalars['ID']>;
  orderTotal?: Maybe<Scalars['Float']>;
  orderQuantity?: Maybe<Scalars['Int']>;
  orderMe?: Maybe<Scalars['Float']>;
  ndcflagged?: Maybe<Scalars['String']>;
  ndccleared?: Maybe<Scalars['String']>;
  flaggedexist?: Maybe<Scalars['Boolean']>;
  issuspiciousorder?: Maybe<Scalars['Boolean']>;
};

export type IOrderLineItem = {
  orderId?: Maybe<Scalars['ID']>;
  requestId?: Maybe<Scalars['String']>;
  orderUuid?: Maybe<Scalars['ID']>;
  trade?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['String']>;
  ndc?: Maybe<Scalars['ID']>;
  lineQuantity?: Maybe<Scalars['Int']>;
  lineTotal?: Maybe<Scalars['Float']>;
  me?: Maybe<Scalars['Float']>;
  deaFamilyNumber?: Maybe<Scalars['String']>;
  meCoefficient?: Maybe<Scalars['Float']>;
  productName?: Maybe<Scalars['String']>;
  controlled?: Maybe<Scalars['String']>;
  dosageForm?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  packageSum?: Maybe<Scalars['String']>;
  qtyCase?: Maybe<Scalars['Float']>;
  packageDescription?: Maybe<Scalars['String']>;
  labeler?: Maybe<Scalars['String']>;
  rxOtc?: Maybe<Scalars['String']>;
  algorithm?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type IPackageAnalytics = {
  packageDescription?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type IPaginatedCustomer = {
  pagination?: Maybe<IPagination>;
  data?: Maybe<Array<ICustomer>>;
};

export type IPaginatedNdCs = {
  pagination?: Maybe<IPagination>;
  data?: Maybe<Array<INdc>>;
};

export type IPaginatedOrders = {
  pagination?: Maybe<IPagination>;
  data?: Maybe<Array<IOrder>>;
};

export type IPaginatedRequest = {
  pagination?: Maybe<IPagination>;
  data?: Maybe<Array<IRequest>>;
};

export type IPagination = {
  total?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type IQuery = {
  algorithmAnalytics?: Maybe<Array<IAlgorithmAnalytic>>;
  algorithmexport?: Maybe<Array<IAlgorithmexport>>;
  algorithmdisableexport?: Maybe<Array<IAlgorithmdisableexport>>;
  controlledFlaggedAnalytics?: Maybe<Array<IControlledFlaggedAnalytic>>;
  drugFamilyAnalytics?: Maybe<Array<IDrugFamilyAnalytics>>;
  ndcAnalytics?: Maybe<Array<INdcAnalytics>>;
  dosageFormAnalytics?: Maybe<Array<IDosageFormAnalytics>>;
  packageAnalytics?: Maybe<Array<IPackageAnalytics>>;
  drugFormAnalytics?: Maybe<Array<IDrugFormAnalytics>>;
  monthlyOrderAnalytics?: Maybe<Array<IMonthlyOrderAnalytics>>;
  monthlyTradeAnalytics?: Maybe<Array<IMonthlyTradeAnalytics>>;
  tradeAnalytics?: Maybe<Array<ITradeAnalytics>>;
  analytics?: Maybe<Array<IAnalytics>>;
  monthlyCustomerOrderAnalytics?: Maybe<Array<IMonthlyCustomerOrderAnalytics>>;
  dashboardSettings?: Maybe<Array<IDashboardSetting>>;
  whatsNewData?: Maybe<Array<IWhatsNewData>>;
  fetchWhatsNewData?: Maybe<Array<IWhatsNewData>>;
  getbuildversion?: Maybe<Array<IBuildVersion>>;
  gettabview?: Maybe<Array<IShowDashboardtab>>;
  controlledNDCFlaggedAnalytics?: Maybe<Array<IControlledNdcFlaggedAnalytic>>;
  drugFamilyNDCAnalytics?: Maybe<Array<IDrugFamilyNdcAnalytics>>;
  tenantConfigurations?: Maybe<Array<ITenantConfiguration>>;
  tenantFrequencyConfigurations?: Maybe<Array<ITenantFrequencyConfigurations>>;
  oAuth2Client?: Maybe<IOAuth2Client>;
  filterSettings?: Maybe<Array<IFilterSetting>>;
  customers?: Maybe<IPaginatedCustomer>;
  customer?: Maybe<ICustomer>;
  files?: Maybe<Array<IFile>>;
  fileTypes?: Maybe<Array<IFileType>>;
  orderFlagged: Scalars['Boolean'];
  flaggedClassOfTrade?: Maybe<Array<IFlaggedClassOfTrade>>;
  flags?: Maybe<Array<Scalars['String']>>;
  flagsndc?: Maybe<Array<IFlagsndc>>;
  flaggedndc?: Maybe<Array<IFlagsndcnew>>;
  approvalcount?: Maybe<Array<IApprovalcount>>;
  approvaluser?: Maybe<Array<IApprovaluser>>;
  approvalcountOrders?: Maybe<Array<IApprovalcountOrders>>;
  flaggedorder?: Maybe<Array<IFlagsordernew>>;
  flagsorders?: Maybe<Array<IFlagsorders>>;
  tenantDataForHeader?: Maybe<Array<ITenantDataForHeader>>;
  ndcs?: Maybe<IPaginatedNdCs>;
  ndc?: Maybe<INdc>;
  notes?: Maybe<Array<INote>>;
  documents?: Maybe<Array<INote>>;
  note?: Maybe<INote>;
  noteAttachment: Scalars['String'];
  helpAttachment: Scalars['String'];
  clearFlag?: Maybe<Scalars['Boolean']>;
  orders?: Maybe<IPaginatedOrders>;
  order?: Maybe<IOrder>;
  lineItems?: Maybe<Array<Maybe<IOrderLineItem>>>;
  suspiciousorder?: Maybe<Scalars['String']>;
  requests?: Maybe<IPaginatedRequest>;
  requestStatuses?: Maybe<Array<IRequestStatus>>;
  requestAttachment: Scalars['String'];
  users?: Maybe<Array<IUser>>;
  user?: Maybe<IUser>;
  userSettings?: Maybe<IUserSettings>;
  webhooks?: Maybe<Array<IWebhook>>;
  webhook?: Maybe<IWebhook>;
};


export type IQueryAlgorithmAnalyticsArgs = {
  algorithms?: Maybe<Array<Maybe<Scalars['Int']>>>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryAlgorithmexportArgs = {
  algorithms?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type IQueryAlgorithmdisableexportArgs = {
  algorithms?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type IQueryControlledFlaggedAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryDrugFamilyAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryNdcAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryDosageFormAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryPackageAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryDrugFormAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryMonthlyOrderAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryMonthlyTradeAnalyticsArgs = {
  trades?: Maybe<Array<Maybe<ITrades>>>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryTradeAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryMonthlyCustomerOrderAnalyticsArgs = {
  customerId: Scalars['ID'];
};


export type IQueryControlledNdcFlaggedAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryDrugFamilyNdcAnalyticsArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryCustomersArgs = {
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryCustomerArgs = {
  id: Scalars['ID'];
};


export type IQueryFilesArgs = {
  customerId: Scalars['ID'];
};


export type IQueryOrderFlaggedArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryFlaggedClassOfTradeArgs = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IQueryFlagsArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryFlagsndcArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryFlaggedndcArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryApprovalcountArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryApprovaluserArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryApprovalcountOrdersArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryFlaggedorderArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryFlagsordersArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryTenantDataForHeaderArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
};


export type IQueryNdcsArgs = {
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type IQueryNdcArgs = {
  ndc: Scalars['ID'];
};


export type IQueryNotesArgs = {
  orderId?: Maybe<Scalars['ID']>;
  requestId?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['String']>;
};


export type IQueryDocumentsArgs = {
  orderId?: Maybe<Scalars['ID']>;
  requestId?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['String']>;
};


export type IQueryNoteArgs = {
  noteId: Scalars['ID'];
};


export type IQueryNoteAttachmentArgs = {
  noteId: Scalars['ID'];
};


export type IQueryClearFlagArgs = {
  orderId?: Maybe<Scalars['ID']>;
  requestId?: Maybe<Scalars['ID']>;
};


export type IQueryOrdersArgs = {
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  algorithm?: Maybe<Scalars['String']>;
  filtersettings?: Maybe<Scalars['String']>;
};


export type IQueryOrderArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
  customerId: Scalars['String'];
  customerName?: Maybe<Scalars['String']>;
  trade: Scalars['String'];
  orderedAt: Scalars['String'];
};


export type IQueryLineItemsArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
  trade: Scalars['String'];
  orderDate: Scalars['String'];
};


export type IQuerySuspiciousorderArgs = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
  customerId: Scalars['String'];
  customerName?: Maybe<Scalars['String']>;
  trade: Scalars['String'];
  orderedAt: Scalars['String'];
};


export type IQueryRequestsArgs = {
  page?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type IQueryRequestStatusesArgs = {
  requestId?: Maybe<Scalars['ID']>;
};


export type IQueryRequestAttachmentArgs = {
  requestId?: Maybe<Scalars['ID']>;
};


export type IQueryUserArgs = {
  userId: Scalars['ID'];
};


export type IQueryWebhookArgs = {
  webhookId: Scalars['ID'];
};

export type IRequest = {
  requestId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<IRequestStatus>>;
  user?: Maybe<IUser>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type IRequestStatus = {
  requestId?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type IShowDashboardtab = {
  showdashboardtab?: Maybe<Scalars['String']>;
};

export type IStat = {
  average?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type IStats = {
  count?: Maybe<Scalars['Int']>;
  total?: Maybe<IStat>;
  quantity?: Maybe<IStat>;
  me?: Maybe<IStat>;
};

export type ITenantConfiguration = {
  orderPie?: Maybe<Scalars['Boolean']>;
  ndcPie?: Maybe<Scalars['Boolean']>;
  tenantpagelimit?: Maybe<Scalars['Float']>;
  passphrase?: Maybe<Scalars['Int']>;
};

export type ITenantDataForHeader = {
  tenantState?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type ITenantFrequencyConfigurations = {
  timefrequency?: Maybe<Scalars['Int']>;
};

export type ITradeAnalytic = {
  flagCount?: Maybe<Scalars['Int']>;
  orderCount?: Maybe<Scalars['Int']>;
};

export type ITradeAnalytics = {
  trade?: Maybe<Scalars['String']>;
  flagCount?: Maybe<Scalars['Int']>;
  orderCount?: Maybe<Scalars['Int']>;
};

export enum ITrades {
  Spc = 'SPC',
  Vet = 'VET',
  Ret = 'RET',
  Ltc = 'LTC',
  Den = 'DEN',
  Hos = 'HOS',
  Doc = 'DOC',
  Wdd = 'WDD'
}

export type IUpdatenoteresponse = {
  message?: Maybe<Scalars['String']>;
};

export type IUpdatePageSettingsInput = {
  pagesettings?: Maybe<Scalars['String']>;
};

export type IUpdatePassphraseSettingsInput = {
  Passphrasesettings?: Maybe<Scalars['String']>;
};

export type IUpdateresponse = {
  message?: Maybe<Scalars['String']>;
};

export type IUpdateSettingsInput = {
  defaultfiltersettings?: Maybe<Scalars['String']>;
};

export type IUpdatesettingsresponse = {
  message?: Maybe<Scalars['String']>;
};

export type IUpdateSuspiciousOrderInput = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['String']>;
  suspiciousorder?: Maybe<Scalars['Boolean']>;
};

export type IUpdateUserClearedSettingsInput = {
  notificationsclearedEnabled?: Maybe<Scalars['Boolean']>;
};

export type IUpdateUserInput = {
  userId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};

export type IUpdateUserRoleInput = {
  userId: Scalars['ID'];
  title: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
};

export type IUpdateUserSettingsInput = {
  notificationsEnabled?: Maybe<Scalars['Boolean']>;
};

export type IUpdateWebhookInput = {
  webhookId: Scalars['ID'];
  url: Scalars['String'];
  active: Scalars['Boolean'];
};


export type IUploadFileInput = {
  file: Scalars['Upload'];
  customerId: Scalars['ID'];
  typeId: Scalars['ID'];
};

export type IUser = {
  userId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  defaultfiltersettings?: Maybe<Scalars['String']>;
  notificationsEnabled?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type IUserSettings = {
  notificationsEnabled?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  notificationsclearedEnabled?: Maybe<Scalars['Boolean']>;
  timefrequency?: Maybe<Scalars['String']>;
};

export type IWebhook = {
  webhookId?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type IWhatsNewData = {
  whatsnewId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['String']>;
};

export type ICreateUserMutationVariables = {
  input: ICreateUserInput;
};


export type ICreateUserMutation = { createUser?: Maybe<Pick<IUser, 'userId' | 'name' | 'email' | 'title' | 'role' | 'enabled' | 'createdAt'>> };

export type ICreateWebhookMutationVariables = {
  input: ICreateWebhookInput;
};


export type ICreateWebhookMutation = { createWebhook?: Maybe<Pick<IWebhook, 'webhookId' | 'url' | 'secret' | 'active' | 'createdAt' | 'updatedAt'>> };

export type IUpdateUserRoleMutationVariables = {
  input: IUpdateUserRoleInput;
};


export type IUpdateUserRoleMutation = { updateUserRole?: Maybe<Pick<IUser, 'userId' | 'name' | 'email' | 'title' | 'role' | 'enabled' | 'notificationsEnabled' | 'createdAt'>> };

export type ICreateEmailMutationVariables = {
  input: ICreateEmailInput;
};


export type ICreateEmailMutation = { createEmail?: Maybe<Pick<IUpdatenoteresponse, 'message'>> };

export type IImportOrdersMutationVariables = {
  file: Scalars['Upload'];
};


export type IImportOrdersMutation = Pick<IMutation, 'importOrders'>;

export type ICreateNoteMutationVariables = {
  input: ICreateNoteInput;
};


export type ICreateNoteMutation = { createNote?: Maybe<Pick<IUpdatenoteresponse, 'message'>> };

export type IGetNotesDrawerContentQueryVariables = {
  orderId: Scalars['ID'];
  requestId: Scalars['ID'];
  customerId: Scalars['String'];
  customerName: Scalars['String'];
  trade: Scalars['String'];
  orderedAt: Scalars['String'];
};


export type IGetNotesDrawerContentQuery = { order?: Maybe<(
    Pick<IOrder, 'orderId' | 'orderUuid' | 'trade' | 'requestId' | 'orderedAt' | 'clearFlag' | 'customerId' | 'customerName' | 'flags' | 'suspiciousorder' | 'issuspiciousorder'>
    & { flaggedndc?: Maybe<Array<Pick<IFlagsndcnew, 'ndc' | 'suspicious'>>>, flaggedorder?: Maybe<Array<Pick<IFlagsordernew, 'orderId' | 'suspicious'>>>, flagsorders?: Maybe<Array<Pick<IFlagsorders, 'total' | 'algorithm' | 'cleared' | 'threshold'>>>, flagsndc?: Maybe<Array<Pick<IFlagsndc, 'total' | 'ndc' | 'algorithm' | 'cleared' | 'threshold' | 'frequency' | 'startdate' | 'enddate' | 'familynumber' | 'orderIds'>>>, tenantDataForHeader?: Maybe<Array<Pick<ITenantDataForHeader, 'tenantState' | 'zipcode'>>>, approvalcount?: Maybe<Array<Pick<IApprovalcount, 'total' | 'ndc'>>>, approvaluser?: Maybe<Array<Pick<IApprovaluser, 'userId' | 'ndc' | 'orderId'>>>, approvalcountOrders?: Maybe<Array<Pick<IApprovalcountOrders, 'total' | 'orderId'>>>, notes?: Maybe<Array<(
      Pick<INote, 'noteId' | 'noteType' | 'attachment' | 'message' | 'issuspicious' | 'ndc' | 'createdAt'>
      & { user?: Maybe<Pick<IUser, 'name'>> }
    )>>, documents?: Maybe<Array<(
      Pick<INote, 'noteId' | 'noteType' | 'fileName' | 'attachment' | 'ndc' | 'issuspicious' | 'message' | 'createdAt'>
      & { user?: Maybe<Pick<IUser, 'name'>> }
    )>>, lineItems?: Maybe<Array<Pick<IOrderLineItem, 'orderId' | 'orderUuid' | 'trade' | 'orderedAt' | 'ndc' | 'lineQuantity' | 'lineTotal' | 'me' | 'deaFamilyNumber' | 'meCoefficient' | 'productName' | 'controlled' | 'dosageForm' | 'size' | 'packageSum' | 'qtyCase' | 'packageDescription' | 'labeler' | 'rxOtc'>>> }
  )> };

export type IAddNotesMutationVariables = {
  input: IAddNoteInput;
};


export type IAddNotesMutation = { addNotes?: Maybe<Pick<IUpdatenoteresponse, 'message'>> };

export type IAddDocumentsMutationVariables = {
  input: IAddDocumentsInput;
};


export type IAddDocumentsMutation = { addDocuments?: Maybe<Pick<IUpdatenoteresponse, 'message'>> };

export type IUpdateSuspiciousOrderMutationVariables = {
  input: IUpdateSuspiciousOrderInput;
};


export type IUpdateSuspiciousOrderMutation = { updateSuspiciousOrder?: Maybe<Pick<IUpdatenoteresponse, 'message'>> };

export type IUploadFileMutationVariables = {
  input: IUploadFileInput;
};


export type IUploadFileMutation = { uploadFile?: Maybe<(
    Pick<IFile, 'id' | 'fileName' | 'createdAt'>
    & { type?: Maybe<Pick<IFileType, 'id' | 'name'>> }
  )> };

export type IListFileTypesQueryVariables = {};


export type IListFileTypesQuery = { fileTypes?: Maybe<Array<Pick<IFileType, 'id' | 'name'>>> };

export type ICreateWhatsNewHistoryMutationVariables = {
  input?: Maybe<ICreateWhatsNewHistoryInput>;
};


export type ICreateWhatsNewHistoryMutation = { createWhatsNewHistory?: Maybe<Pick<IWhatsNewData, 'description'>> };

export type IGetClassOfTradeAnalyticsQueryVariables = {
  trades?: Maybe<Array<Maybe<ITrades>>>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IGetClassOfTradeAnalyticsQuery = { monthlyTradeAnalytics?: Maybe<Array<(
    Pick<IMonthlyTradeAnalytics, 'trade'>
    & { stats?: Maybe<(
      Pick<IStats, 'count'>
      & { total?: Maybe<Pick<IStat, 'average' | 'total'>>, quantity?: Maybe<Pick<IStat, 'average' | 'total'>>, me?: Maybe<Pick<IStat, 'average' | 'total'>> }
    )>, date?: Maybe<Pick<IDate, 'year' | 'month' | 'day'>> }
  )>>, tradeAnalytics?: Maybe<Array<Pick<ITradeAnalytics, 'trade' | 'flagCount' | 'orderCount'>>> };

export type IGetFlaggedClassOfTradeAnalyticsQueryVariables = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IGetFlaggedClassOfTradeAnalyticsQuery = { flaggedClassOfTrade?: Maybe<Array<Pick<IFlaggedClassOfTrade, 'customerId' | 'orderId' | 'trade' | 'orderYear' | 'orderMonth' | 'orderQuantity' | 'tradeAverageQuantity'>>> };

export type IGetNdcAnalyticsQueryVariables = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IGetNdcAnalyticsQuery = { monthlyOrderAnalytics?: Maybe<Array<Pick<IMonthlyOrderAnalytics, 'orderYear' | 'orderMonth' | 'count' | 'totalOrderQuantity' | 'totalOrderTotal' | 'totalOrderMe' | 'averageOrderQuantity' | 'averageOrderTotal' | 'averageOrderMe'>>>, ndcAnalytics?: Maybe<Array<Pick<INdcAnalytics, 'ndc' | 'productName' | 'count' | 'averageLineQuantity' | 'averageLineTotal'>>>, dosageFormAnalytics?: Maybe<Array<Pick<IDosageFormAnalytics, 'dosageForm' | 'count'>>>, packageAnalytics?: Maybe<Array<Pick<IPackageAnalytics, 'packageDescription' | 'count'>>>, drugFormAnalytics?: Maybe<Array<Pick<IDrugFormAnalytics, 'dosageForm' | 'size' | 'packageSum' | 'qtyCase' | 'packageDescription' | 'count'>>>, analytics?: Maybe<Array<(
    Pick<IAnalytics, 'id' | 'customerId'>
    & { stats?: Maybe<(
      Pick<IStats, 'count'>
      & { total?: Maybe<Pick<IStat, 'average' | 'total'>>, quantity?: Maybe<Pick<IStat, 'average' | 'total'>>, me?: Maybe<Pick<IStat, 'average' | 'total'>> }
    )>, date?: Maybe<Pick<IDate, 'year' | 'month' | 'day'>> }
  )>> };

export type IGetOrderAveragesAnalyticsQueryVariables = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IGetOrderAveragesAnalyticsQuery = { algorithmAnalytics?: Maybe<Array<Pick<IAlgorithmAnalytic, 'name' | 'description' | 'flagCount' | 'orderCount'>>> };

export type IGetOrderByFamilyAnalyticsQueryVariables = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IGetOrderByFamilyAnalyticsQuery = { algorithmAnalytics?: Maybe<Array<Pick<IAlgorithmAnalytic, 'name' | 'description' | 'flagCount' | 'orderCount'>>> };

export type IGetOrderByTradeAnalyticsQueryVariables = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IGetOrderByTradeAnalyticsQuery = { algorithmAnalytics?: Maybe<Array<Pick<IAlgorithmAnalytic, 'name' | 'description' | 'flagCount' | 'orderCount'>>> };

export type IGetOverviewAnalyticsQueryVariables = {
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IGetOverviewAnalyticsQuery = { algorithmAnalytics?: Maybe<Array<Pick<IAlgorithmAnalytic, 'name' | 'description' | 'flagCount' | 'orderCount' | 'ndcFlagCount' | 'algtype'>>>, controlledFlaggedAnalytics?: Maybe<Array<Pick<IControlledFlaggedAnalytic, 'ndc' | 'productName' | 'count'>>>, drugFamilyAnalytics?: Maybe<Array<Pick<IDrugFamilyAnalytics, 'drugFamilyNumber' | 'count'>>>, getbuildversion?: Maybe<Array<Pick<IBuildVersion, 'buildversion' | 'tenantId'>>>, gettabview?: Maybe<Array<Pick<IShowDashboardtab, 'showdashboardtab'>>>, whatsNewData?: Maybe<Array<Pick<IWhatsNewData, 'whatsnewId' | 'description' | 'releaseDate'>>>, controlledNDCFlaggedAnalytics?: Maybe<Array<Pick<IControlledNdcFlaggedAnalytic, 'ndc' | 'productName' | 'count'>>>, drugFamilyNDCAnalytics?: Maybe<Array<Pick<IDrugFamilyNdcAnalytics, 'drugFamilyNumber' | 'count'>>> };

export type IGetDashboardSettingsQueryVariables = {};


export type IGetDashboardSettingsQuery = { dashboardSettings?: Maybe<Array<Pick<IDashboardSetting, 'name' | 'defaultfiltersettings'>>>, fetchWhatsNewData?: Maybe<Array<Pick<IWhatsNewData, 'whatsnewId' | 'description' | 'releaseDate'>>> };

export type IGetTenantConfigurationsQueryVariables = {};


export type IGetTenantConfigurationsQuery = { tenantConfigurations?: Maybe<Array<Pick<ITenantConfiguration, 'orderPie' | 'ndcPie' | 'tenantpagelimit' | 'passphrase'>>> };

export type IGetMonthlyCustomerOrderAnalyticsQueryVariables = {
  customerId: Scalars['ID'];
};


export type IGetMonthlyCustomerOrderAnalyticsQuery = { monthlyCustomerOrderAnalytics?: Maybe<Array<Pick<IMonthlyCustomerOrderAnalytics, 'orderYear' | 'orderMonth' | 'count' | 'totalOrderQuantity' | 'totalOrderTotal' | 'totalOrderMe' | 'averageOrderQuantity' | 'averageOrderTotal' | 'averageOrderMe'>>> };

export type IListCustomerFilesQueryVariables = {
  customerId: Scalars['ID'];
};


export type IListCustomerFilesQuery = { files?: Maybe<Array<(
    Pick<IFile, 'id' | 'fileName' | 'createdAt'>
    & { type?: Maybe<Pick<IFileType, 'id' | 'name'>> }
  )>>, customer?: Maybe<Pick<ICustomer, 'id' | 'customerName'>> };

export type IGetCustomerFileSignedUrlMutationVariables = {
  id: Scalars['ID'];
};


export type IGetCustomerFileSignedUrlMutation = Pick<IMutation, 'getFileSignedUrl'>;

export type IListCustomerOrdersQueryVariables = {
  id: Scalars['ID'];
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  filtersettings?: Maybe<Scalars['String']>;
};


export type IListCustomerOrdersQuery = { customer?: Maybe<(
    Pick<ICustomer, 'id' | 'customerName'>
    & { orders?: Maybe<{ pagination?: Maybe<Pick<IPagination, 'total' | 'limit' | 'page'>>, data?: Maybe<Array<(
        Pick<IOrder, 'orderId' | 'customerId' | 'customerName' | 'trade' | 'orderDate' | 'orderedAt' | 'importId' | 'requestId' | 'orderTotal' | 'orderQuantity' | 'orderMe' | 'flagged' | 'clearFlag' | 'ndcflagged' | 'ndccleared' | 'flaggedexist' | 'suspiciousorder' | 'issuspiciousorder'>
        & { lineItems?: Maybe<Array<Pick<IOrderLineItem, 'orderId' | 'orderUuid' | 'trade' | 'orderedAt' | 'ndc' | 'lineQuantity' | 'lineTotal' | 'me' | 'deaFamilyNumber' | 'meCoefficient' | 'productName' | 'controlled' | 'dosageForm' | 'size' | 'packageSum' | 'qtyCase' | 'packageDescription' | 'labeler' | 'rxOtc'>>> }
      )>> }> }
  )> };

export type IListCustomersQueryVariables = {
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};


export type IListCustomersQuery = { customers?: Maybe<{ pagination?: Maybe<Pick<IPagination, 'total' | 'limit' | 'page'>>, data?: Maybe<Array<Pick<ICustomer, 'id' | 'customerId' | 'customerName' | 'lastOrderedAt'>>> }> };

export type IListOrdersQueryVariables = {
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  algorithm?: Maybe<Scalars['String']>;
  filtersettings?: Maybe<Scalars['String']>;
};


export type IListOrdersQuery = { orders?: Maybe<{ pagination?: Maybe<Pick<IPagination, 'total' | 'limit' | 'page'>>, data?: Maybe<Array<(
      Pick<IOrder, 'orderId' | 'orderUuid' | 'customerId' | 'customerName' | 'trade' | 'orderDate' | 'orderedAt' | 'importId' | 'requestId' | 'orderTotal' | 'orderQuantity' | 'orderMe' | 'flagged' | 'clearFlag' | 'ndcflagged' | 'ndccleared' | 'flaggedexist' | 'suspiciousorder' | 'issuspiciousorder'>
      & { lineItems?: Maybe<Array<Pick<IOrderLineItem, 'orderId' | 'orderUuid' | 'trade' | 'orderedAt' | 'ndc' | 'lineQuantity' | 'lineTotal' | 'me' | 'deaFamilyNumber' | 'meCoefficient' | 'productName' | 'controlled' | 'dosageForm' | 'size' | 'packageSum' | 'qtyCase' | 'packageDescription' | 'labeler' | 'rxOtc'>>> }
    )>> }> };

export type IGetNoteAttachmentQueryVariables = {
  noteId: Scalars['ID'];
};


export type IGetNoteAttachmentQuery = Pick<IQuery, 'noteAttachment'>;

export type IGetHelpAttachmentQueryVariables = {};


export type IGetHelpAttachmentQuery = Pick<IQuery, 'helpAttachment'>;

export type IGetOrdersReportsQueryVariables = {};


export type IGetOrdersReportsQuery = { algorithmexport?: Maybe<Array<Pick<IAlgorithmexport, 'name' | 'description'>>>, algorithmdisableexport?: Maybe<Array<Pick<IAlgorithmdisableexport, 'name' | 'description'>>> };

export type IGetSettingsQueryVariables = {};


export type IGetSettingsQuery = { oAuth2Client?: Maybe<Pick<IOAuth2Client, 'clientId' | 'clientSecret'>>, webhooks?: Maybe<Array<Pick<IWebhook, 'webhookId' | 'url' | 'secret' | 'active' | 'createdAt' | 'updatedAt'>>>, filterSettings?: Maybe<Array<Pick<IFilterSetting, 'defaultfiltersettings'>>>, userSettings?: Maybe<Pick<IUserSettings, 'notificationsEnabled' | 'role' | 'notificationsclearedEnabled'>>, tenantFrequencyConfigurations?: Maybe<Array<Pick<ITenantFrequencyConfigurations, 'timefrequency'>>> };

export type IUpdateSettingsMutationVariables = {
  input: IUpdateSettingsInput;
};


export type IUpdateSettingsMutation = { updateSettings?: Maybe<Pick<IUpdatesettingsresponse, 'message'>> };

export type IUpdateUserSettingsMutationVariables = {
  input: IUpdateUserSettingsInput;
};


export type IUpdateUserSettingsMutation = { updateUserSettings?: Maybe<Pick<IUpdatesettingsresponse, 'message'>> };

export type IUpdateUserClearedSettingsMutationVariables = {
  input: IUpdateUserClearedSettingsInput;
};


export type IUpdateUserClearedSettingsMutation = { updateUserClearedSettings?: Maybe<Pick<IUpdatesettingsresponse, 'message'>> };

export type IUpdatePageSettingsMutationVariables = {
  input: IUpdatePageSettingsInput;
};


export type IUpdatePageSettingsMutation = { updatePageSettings?: Maybe<Pick<IUpdatesettingsresponse, 'message'>> };

export type IUpdatePassphraseSettingsMutationVariables = {
  input: IUpdatePassphraseSettingsInput;
};


export type IUpdatePassphraseSettingsMutation = { updatePassphraseSettings?: Maybe<Pick<IUpdatesettingsresponse, 'message'>> };

export type IDeleteWebhookMutationVariables = {
  input: IDeleteWebhookInput;
};


export type IDeleteWebhookMutation = Pick<IMutation, 'deleteWebhook'>;

export type ICreateOAuth2ClientMutationVariables = {};


export type ICreateOAuth2ClientMutation = { createOAuth2Client?: Maybe<Pick<IOAuth2Client, 'clientId' | 'clientSecret'>> };

export type IUpdateWebhookMutationVariables = {
  input: IUpdateWebhookInput;
};


export type IUpdateWebhookMutation = { updateWebhook?: Maybe<Pick<IWebhook, 'webhookId' | 'url' | 'secret' | 'active' | 'createdAt' | 'updatedAt'>> };

export type IListRequestsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type IListRequestsQuery = { requests?: Maybe<{ pagination?: Maybe<Pick<IPagination, 'total' | 'limit' | 'page'>>, data?: Maybe<Array<(
      Pick<IRequest, 'requestId' | 'userId' | 'type' | 'attachment' | 'createdAt' | 'updatedAt'>
      & { user?: Maybe<Pick<IUser, 'name'>>, statuses?: Maybe<Array<Pick<IRequestStatus, 'requestId' | 'status' | 'type' | 'message' | 'createdAt' | 'updatedAt'>>> }
    )>> }> };

export type IGetRequestAttachmentQueryVariables = {
  requestId: Scalars['ID'];
};


export type IGetRequestAttachmentQuery = Pick<IQuery, 'requestAttachment'>;

export type IListUsersQueryVariables = {};


export type IListUsersQuery = { users?: Maybe<Array<Pick<IUser, 'userId' | 'name' | 'email' | 'title' | 'role' | 'enabled' | 'notificationsEnabled' | 'createdAt'>>> };

export type IUpdateUserMutationVariables = {
  input: IUpdateUserInput;
};


export type IUpdateUserMutation = { updateUser?: Maybe<Pick<IUser, 'userId' | 'name' | 'email' | 'title' | 'role' | 'enabled' | 'notificationsEnabled' | 'createdAt'>> };


export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    userId
    name
    email
    title
    role
    enabled
    createdAt
  }
}
    `;
export type ICreateUserMutationFn = ApolloReactCommon.MutationFunction<ICreateUserMutation, ICreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateUserMutation, ICreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateUserMutation, ICreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<ICreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateUserMutation, ICreateUserMutationVariables>;
export const CreateWebhookDocument = gql`
    mutation CreateWebhook($input: CreateWebhookInput!) {
  createWebhook(input: $input) {
    webhookId
    url
    secret
    active
    createdAt
    updatedAt
  }
}
    `;
export type ICreateWebhookMutationFn = ApolloReactCommon.MutationFunction<ICreateWebhookMutation, ICreateWebhookMutationVariables>;

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateWebhookMutation, ICreateWebhookMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateWebhookMutation, ICreateWebhookMutationVariables>(CreateWebhookDocument, baseOptions);
      }
export type CreateWebhookMutationHookResult = ReturnType<typeof useCreateWebhookMutation>;
export type CreateWebhookMutationResult = ApolloReactCommon.MutationResult<ICreateWebhookMutation>;
export type CreateWebhookMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateWebhookMutation, ICreateWebhookMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($input: UpdateUserRoleInput!) {
  updateUserRole(input: $input) {
    userId
    name
    email
    title
    role
    enabled
    notificationsEnabled
    createdAt
  }
}
    `;
export type IUpdateUserRoleMutationFn = ApolloReactCommon.MutationFunction<IUpdateUserRoleMutation, IUpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateUserRoleMutation, IUpdateUserRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateUserRoleMutation, IUpdateUserRoleMutationVariables>(UpdateUserRoleDocument, baseOptions);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = ApolloReactCommon.MutationResult<IUpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateUserRoleMutation, IUpdateUserRoleMutationVariables>;
export const CreateEmailDocument = gql`
    mutation createEmail($input: CreateEmailInput!) {
  createEmail(input: $input) {
    message
  }
}
    `;
export type ICreateEmailMutationFn = ApolloReactCommon.MutationFunction<ICreateEmailMutation, ICreateEmailMutationVariables>;

/**
 * __useCreateEmailMutation__
 *
 * To run a mutation, you first call `useCreateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailMutation, { data, loading, error }] = useCreateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateEmailMutation, ICreateEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateEmailMutation, ICreateEmailMutationVariables>(CreateEmailDocument, baseOptions);
      }
export type CreateEmailMutationHookResult = ReturnType<typeof useCreateEmailMutation>;
export type CreateEmailMutationResult = ApolloReactCommon.MutationResult<ICreateEmailMutation>;
export type CreateEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateEmailMutation, ICreateEmailMutationVariables>;
export const ImportOrdersDocument = gql`
    mutation ImportOrders($file: Upload!) {
  importOrders(file: $file)
}
    `;
export type IImportOrdersMutationFn = ApolloReactCommon.MutationFunction<IImportOrdersMutation, IImportOrdersMutationVariables>;

/**
 * __useImportOrdersMutation__
 *
 * To run a mutation, you first call `useImportOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importOrdersMutation, { data, loading, error }] = useImportOrdersMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportOrdersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IImportOrdersMutation, IImportOrdersMutationVariables>) {
        return ApolloReactHooks.useMutation<IImportOrdersMutation, IImportOrdersMutationVariables>(ImportOrdersDocument, baseOptions);
      }
export type ImportOrdersMutationHookResult = ReturnType<typeof useImportOrdersMutation>;
export type ImportOrdersMutationResult = ApolloReactCommon.MutationResult<IImportOrdersMutation>;
export type ImportOrdersMutationOptions = ApolloReactCommon.BaseMutationOptions<IImportOrdersMutation, IImportOrdersMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    message
  }
}
    `;
export type ICreateNoteMutationFn = ApolloReactCommon.MutationFunction<ICreateNoteMutation, ICreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateNoteMutation, ICreateNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateNoteMutation, ICreateNoteMutationVariables>(CreateNoteDocument, baseOptions);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = ApolloReactCommon.MutationResult<ICreateNoteMutation>;
export type CreateNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateNoteMutation, ICreateNoteMutationVariables>;
export const GetNotesDrawerContentDocument = gql`
    query GetNotesDrawerContent($orderId: ID!, $requestId: ID!, $customerId: String!, $customerName: String!, $trade: String!, $orderedAt: String!) {
  order(orderId: $orderId, requestId: $requestId, customerId: $customerId, customerName: $customerName, trade: $trade, orderedAt: $orderedAt) {
    orderId
    orderUuid
    trade
    requestId
    orderedAt
    clearFlag
    customerId
    customerName
    flags
    suspiciousorder
    issuspiciousorder
    flaggedndc {
      ndc
      suspicious
    }
    flaggedorder {
      orderId
      suspicious
    }
    flagsorders {
      total
      algorithm
      cleared
      threshold
    }
    flagsndc {
      total
      ndc
      algorithm
      cleared
      threshold
      frequency
      startdate
      enddate
      familynumber
      orderIds
    }
    tenantDataForHeader {
      tenantState
      zipcode
    }
    approvalcount {
      total
      ndc
    }
    approvaluser {
      userId
      ndc
      orderId
    }
    approvalcountOrders {
      total
      orderId
    }
    notes {
      noteId
      user {
        name
      }
      noteType
      attachment
      message
      issuspicious
      ndc
      createdAt
    }
    documents {
      noteId
      user {
        name
      }
      noteType
      fileName
      attachment
      ndc
      issuspicious
      message
      createdAt
    }
    lineItems {
      orderId
      orderUuid
      trade
      orderedAt
      ndc
      lineQuantity
      lineTotal
      me
      deaFamilyNumber
      meCoefficient
      productName
      controlled
      dosageForm
      size
      packageSum
      qtyCase
      packageDescription
      labeler
      rxOtc
    }
  }
}
    `;

/**
 * __useGetNotesDrawerContentQuery__
 *
 * To run a query within a React component, call `useGetNotesDrawerContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesDrawerContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesDrawerContentQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      requestId: // value for 'requestId'
 *      customerId: // value for 'customerId'
 *      customerName: // value for 'customerName'
 *      trade: // value for 'trade'
 *      orderedAt: // value for 'orderedAt'
 *   },
 * });
 */
export function useGetNotesDrawerContentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNotesDrawerContentQuery, IGetNotesDrawerContentQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNotesDrawerContentQuery, IGetNotesDrawerContentQueryVariables>(GetNotesDrawerContentDocument, baseOptions);
      }
export function useGetNotesDrawerContentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNotesDrawerContentQuery, IGetNotesDrawerContentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNotesDrawerContentQuery, IGetNotesDrawerContentQueryVariables>(GetNotesDrawerContentDocument, baseOptions);
        }
export type GetNotesDrawerContentQueryHookResult = ReturnType<typeof useGetNotesDrawerContentQuery>;
export type GetNotesDrawerContentLazyQueryHookResult = ReturnType<typeof useGetNotesDrawerContentLazyQuery>;
export type GetNotesDrawerContentQueryResult = ApolloReactCommon.QueryResult<IGetNotesDrawerContentQuery, IGetNotesDrawerContentQueryVariables>;
export const AddNotesDocument = gql`
    mutation AddNotes($input: AddNoteInput!) {
  addNotes(input: $input) {
    message
  }
}
    `;
export type IAddNotesMutationFn = ApolloReactCommon.MutationFunction<IAddNotesMutation, IAddNotesMutationVariables>;

/**
 * __useAddNotesMutation__
 *
 * To run a mutation, you first call `useAddNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNotesMutation, { data, loading, error }] = useAddNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNotesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IAddNotesMutation, IAddNotesMutationVariables>) {
        return ApolloReactHooks.useMutation<IAddNotesMutation, IAddNotesMutationVariables>(AddNotesDocument, baseOptions);
      }
export type AddNotesMutationHookResult = ReturnType<typeof useAddNotesMutation>;
export type AddNotesMutationResult = ApolloReactCommon.MutationResult<IAddNotesMutation>;
export type AddNotesMutationOptions = ApolloReactCommon.BaseMutationOptions<IAddNotesMutation, IAddNotesMutationVariables>;
export const AddDocumentsDocument = gql`
    mutation AddDocuments($input: AddDocumentsInput!) {
  addDocuments(input: $input) {
    message
  }
}
    `;
export type IAddDocumentsMutationFn = ApolloReactCommon.MutationFunction<IAddDocumentsMutation, IAddDocumentsMutationVariables>;

/**
 * __useAddDocumentsMutation__
 *
 * To run a mutation, you first call `useAddDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentsMutation, { data, loading, error }] = useAddDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IAddDocumentsMutation, IAddDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<IAddDocumentsMutation, IAddDocumentsMutationVariables>(AddDocumentsDocument, baseOptions);
      }
export type AddDocumentsMutationHookResult = ReturnType<typeof useAddDocumentsMutation>;
export type AddDocumentsMutationResult = ApolloReactCommon.MutationResult<IAddDocumentsMutation>;
export type AddDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<IAddDocumentsMutation, IAddDocumentsMutationVariables>;
export const UpdateSuspiciousOrderDocument = gql`
    mutation UpdateSuspiciousOrder($input: UpdateSuspiciousOrderInput!) {
  updateSuspiciousOrder(input: $input) {
    message
  }
}
    `;
export type IUpdateSuspiciousOrderMutationFn = ApolloReactCommon.MutationFunction<IUpdateSuspiciousOrderMutation, IUpdateSuspiciousOrderMutationVariables>;

/**
 * __useUpdateSuspiciousOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSuspiciousOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuspiciousOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuspiciousOrderMutation, { data, loading, error }] = useUpdateSuspiciousOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSuspiciousOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateSuspiciousOrderMutation, IUpdateSuspiciousOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateSuspiciousOrderMutation, IUpdateSuspiciousOrderMutationVariables>(UpdateSuspiciousOrderDocument, baseOptions);
      }
export type UpdateSuspiciousOrderMutationHookResult = ReturnType<typeof useUpdateSuspiciousOrderMutation>;
export type UpdateSuspiciousOrderMutationResult = ApolloReactCommon.MutationResult<IUpdateSuspiciousOrderMutation>;
export type UpdateSuspiciousOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateSuspiciousOrderMutation, IUpdateSuspiciousOrderMutationVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($input: UploadFileInput!) {
  uploadFile(input: $input) {
    id
    fileName
    type {
      id
      name
    }
    createdAt
  }
}
    `;
export type IUploadFileMutationFn = ApolloReactCommon.MutationFunction<IUploadFileMutation, IUploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUploadFileMutation, IUploadFileMutationVariables>) {
        return ApolloReactHooks.useMutation<IUploadFileMutation, IUploadFileMutationVariables>(UploadFileDocument, baseOptions);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<IUploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<IUploadFileMutation, IUploadFileMutationVariables>;
export const ListFileTypesDocument = gql`
    query ListFileTypes {
  fileTypes {
    id
    name
  }
}
    `;

/**
 * __useListFileTypesQuery__
 *
 * To run a query within a React component, call `useListFileTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFileTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFileTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFileTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListFileTypesQuery, IListFileTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<IListFileTypesQuery, IListFileTypesQueryVariables>(ListFileTypesDocument, baseOptions);
      }
export function useListFileTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListFileTypesQuery, IListFileTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListFileTypesQuery, IListFileTypesQueryVariables>(ListFileTypesDocument, baseOptions);
        }
export type ListFileTypesQueryHookResult = ReturnType<typeof useListFileTypesQuery>;
export type ListFileTypesLazyQueryHookResult = ReturnType<typeof useListFileTypesLazyQuery>;
export type ListFileTypesQueryResult = ApolloReactCommon.QueryResult<IListFileTypesQuery, IListFileTypesQueryVariables>;
export const CreateWhatsNewHistoryDocument = gql`
    mutation CreateWhatsNewHistory($input: CreateWhatsNewHistoryInput) {
  createWhatsNewHistory(input: $input) {
    description
  }
}
    `;
export type ICreateWhatsNewHistoryMutationFn = ApolloReactCommon.MutationFunction<ICreateWhatsNewHistoryMutation, ICreateWhatsNewHistoryMutationVariables>;

/**
 * __useCreateWhatsNewHistoryMutation__
 *
 * To run a mutation, you first call `useCreateWhatsNewHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWhatsNewHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWhatsNewHistoryMutation, { data, loading, error }] = useCreateWhatsNewHistoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWhatsNewHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateWhatsNewHistoryMutation, ICreateWhatsNewHistoryMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateWhatsNewHistoryMutation, ICreateWhatsNewHistoryMutationVariables>(CreateWhatsNewHistoryDocument, baseOptions);
      }
export type CreateWhatsNewHistoryMutationHookResult = ReturnType<typeof useCreateWhatsNewHistoryMutation>;
export type CreateWhatsNewHistoryMutationResult = ApolloReactCommon.MutationResult<ICreateWhatsNewHistoryMutation>;
export type CreateWhatsNewHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateWhatsNewHistoryMutation, ICreateWhatsNewHistoryMutationVariables>;
export const GetClassOfTradeAnalyticsDocument = gql`
    query GetClassOfTradeAnalytics($trades: [Trades], $start: String, $end: String) {
  monthlyTradeAnalytics(trades: $trades, start: $start, end: $end) {
    trade
    stats {
      count
      total {
        average
        total
      }
      quantity {
        average
        total
      }
      me {
        average
        total
      }
    }
    date {
      year
      month
      day
    }
  }
  tradeAnalytics(start: $start, end: $end) {
    trade
    flagCount
    orderCount
  }
}
    `;

/**
 * __useGetClassOfTradeAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetClassOfTradeAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassOfTradeAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassOfTradeAnalyticsQuery({
 *   variables: {
 *      trades: // value for 'trades'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetClassOfTradeAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetClassOfTradeAnalyticsQuery, IGetClassOfTradeAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetClassOfTradeAnalyticsQuery, IGetClassOfTradeAnalyticsQueryVariables>(GetClassOfTradeAnalyticsDocument, baseOptions);
      }
export function useGetClassOfTradeAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetClassOfTradeAnalyticsQuery, IGetClassOfTradeAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetClassOfTradeAnalyticsQuery, IGetClassOfTradeAnalyticsQueryVariables>(GetClassOfTradeAnalyticsDocument, baseOptions);
        }
export type GetClassOfTradeAnalyticsQueryHookResult = ReturnType<typeof useGetClassOfTradeAnalyticsQuery>;
export type GetClassOfTradeAnalyticsLazyQueryHookResult = ReturnType<typeof useGetClassOfTradeAnalyticsLazyQuery>;
export type GetClassOfTradeAnalyticsQueryResult = ApolloReactCommon.QueryResult<IGetClassOfTradeAnalyticsQuery, IGetClassOfTradeAnalyticsQueryVariables>;
export const GetFlaggedClassOfTradeAnalyticsDocument = gql`
    query GetFlaggedClassOfTradeAnalytics($start: String, $end: String) {
  flaggedClassOfTrade(start: $start, end: $end) {
    customerId
    orderId
    trade
    orderYear
    orderMonth
    orderQuantity
    tradeAverageQuantity
  }
}
    `;

/**
 * __useGetFlaggedClassOfTradeAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetFlaggedClassOfTradeAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlaggedClassOfTradeAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlaggedClassOfTradeAnalyticsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetFlaggedClassOfTradeAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetFlaggedClassOfTradeAnalyticsQuery, IGetFlaggedClassOfTradeAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetFlaggedClassOfTradeAnalyticsQuery, IGetFlaggedClassOfTradeAnalyticsQueryVariables>(GetFlaggedClassOfTradeAnalyticsDocument, baseOptions);
      }
export function useGetFlaggedClassOfTradeAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetFlaggedClassOfTradeAnalyticsQuery, IGetFlaggedClassOfTradeAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetFlaggedClassOfTradeAnalyticsQuery, IGetFlaggedClassOfTradeAnalyticsQueryVariables>(GetFlaggedClassOfTradeAnalyticsDocument, baseOptions);
        }
export type GetFlaggedClassOfTradeAnalyticsQueryHookResult = ReturnType<typeof useGetFlaggedClassOfTradeAnalyticsQuery>;
export type GetFlaggedClassOfTradeAnalyticsLazyQueryHookResult = ReturnType<typeof useGetFlaggedClassOfTradeAnalyticsLazyQuery>;
export type GetFlaggedClassOfTradeAnalyticsQueryResult = ApolloReactCommon.QueryResult<IGetFlaggedClassOfTradeAnalyticsQuery, IGetFlaggedClassOfTradeAnalyticsQueryVariables>;
export const GetNdcAnalyticsDocument = gql`
    query GetNDCAnalytics($start: String, $end: String) {
  monthlyOrderAnalytics(start: $start, end: $end) {
    orderYear
    orderMonth
    count
    totalOrderQuantity
    totalOrderTotal
    totalOrderMe
    averageOrderQuantity
    averageOrderTotal
    averageOrderMe
  }
  ndcAnalytics(start: $start, end: $end) {
    ndc
    productName
    count
    averageLineQuantity
    averageLineTotal
  }
  dosageFormAnalytics(start: $start, end: $end) {
    dosageForm
    count
  }
  packageAnalytics(start: $start, end: $end) {
    packageDescription
    count
  }
  drugFormAnalytics(start: $start, end: $end) {
    dosageForm
    size
    packageSum
    qtyCase
    packageDescription
    count
  }
  analytics(start: $start, end: $end) {
    id
    customerId
    stats {
      count
      total {
        average
        total
      }
      quantity {
        average
        total
      }
      me {
        average
        total
      }
    }
    date {
      year
      month
      day
    }
  }
}
    `;

/**
 * __useGetNdcAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetNdcAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNdcAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNdcAnalyticsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetNdcAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNdcAnalyticsQuery, IGetNdcAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNdcAnalyticsQuery, IGetNdcAnalyticsQueryVariables>(GetNdcAnalyticsDocument, baseOptions);
      }
export function useGetNdcAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNdcAnalyticsQuery, IGetNdcAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNdcAnalyticsQuery, IGetNdcAnalyticsQueryVariables>(GetNdcAnalyticsDocument, baseOptions);
        }
export type GetNdcAnalyticsQueryHookResult = ReturnType<typeof useGetNdcAnalyticsQuery>;
export type GetNdcAnalyticsLazyQueryHookResult = ReturnType<typeof useGetNdcAnalyticsLazyQuery>;
export type GetNdcAnalyticsQueryResult = ApolloReactCommon.QueryResult<IGetNdcAnalyticsQuery, IGetNdcAnalyticsQueryVariables>;
export const GetOrderAveragesAnalyticsDocument = gql`
    query GetOrderAveragesAnalytics($start: String, $end: String) {
  algorithmAnalytics(algorithms: [1, 2], start: $start, end: $end) {
    name
    description
    flagCount
    orderCount
  }
}
    `;

/**
 * __useGetOrderAveragesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetOrderAveragesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderAveragesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderAveragesAnalyticsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetOrderAveragesAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetOrderAveragesAnalyticsQuery, IGetOrderAveragesAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetOrderAveragesAnalyticsQuery, IGetOrderAveragesAnalyticsQueryVariables>(GetOrderAveragesAnalyticsDocument, baseOptions);
      }
export function useGetOrderAveragesAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetOrderAveragesAnalyticsQuery, IGetOrderAveragesAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetOrderAveragesAnalyticsQuery, IGetOrderAveragesAnalyticsQueryVariables>(GetOrderAveragesAnalyticsDocument, baseOptions);
        }
export type GetOrderAveragesAnalyticsQueryHookResult = ReturnType<typeof useGetOrderAveragesAnalyticsQuery>;
export type GetOrderAveragesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetOrderAveragesAnalyticsLazyQuery>;
export type GetOrderAveragesAnalyticsQueryResult = ApolloReactCommon.QueryResult<IGetOrderAveragesAnalyticsQuery, IGetOrderAveragesAnalyticsQueryVariables>;
export const GetOrderByFamilyAnalyticsDocument = gql`
    query GetOrderByFamilyAnalytics($start: String, $end: String) {
  algorithmAnalytics(algorithms: [4], start: $start, end: $end) {
    name
    description
    flagCount
    orderCount
  }
}
    `;

/**
 * __useGetOrderByFamilyAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetOrderByFamilyAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByFamilyAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByFamilyAnalyticsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetOrderByFamilyAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetOrderByFamilyAnalyticsQuery, IGetOrderByFamilyAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetOrderByFamilyAnalyticsQuery, IGetOrderByFamilyAnalyticsQueryVariables>(GetOrderByFamilyAnalyticsDocument, baseOptions);
      }
export function useGetOrderByFamilyAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetOrderByFamilyAnalyticsQuery, IGetOrderByFamilyAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetOrderByFamilyAnalyticsQuery, IGetOrderByFamilyAnalyticsQueryVariables>(GetOrderByFamilyAnalyticsDocument, baseOptions);
        }
export type GetOrderByFamilyAnalyticsQueryHookResult = ReturnType<typeof useGetOrderByFamilyAnalyticsQuery>;
export type GetOrderByFamilyAnalyticsLazyQueryHookResult = ReturnType<typeof useGetOrderByFamilyAnalyticsLazyQuery>;
export type GetOrderByFamilyAnalyticsQueryResult = ApolloReactCommon.QueryResult<IGetOrderByFamilyAnalyticsQuery, IGetOrderByFamilyAnalyticsQueryVariables>;
export const GetOrderByTradeAnalyticsDocument = gql`
    query GetOrderByTradeAnalytics($start: String, $end: String) {
  algorithmAnalytics(algorithms: [6], start: $start, end: $end) {
    name
    description
    flagCount
    orderCount
  }
}
    `;

/**
 * __useGetOrderByTradeAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetOrderByTradeAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByTradeAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByTradeAnalyticsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetOrderByTradeAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetOrderByTradeAnalyticsQuery, IGetOrderByTradeAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetOrderByTradeAnalyticsQuery, IGetOrderByTradeAnalyticsQueryVariables>(GetOrderByTradeAnalyticsDocument, baseOptions);
      }
export function useGetOrderByTradeAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetOrderByTradeAnalyticsQuery, IGetOrderByTradeAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetOrderByTradeAnalyticsQuery, IGetOrderByTradeAnalyticsQueryVariables>(GetOrderByTradeAnalyticsDocument, baseOptions);
        }
export type GetOrderByTradeAnalyticsQueryHookResult = ReturnType<typeof useGetOrderByTradeAnalyticsQuery>;
export type GetOrderByTradeAnalyticsLazyQueryHookResult = ReturnType<typeof useGetOrderByTradeAnalyticsLazyQuery>;
export type GetOrderByTradeAnalyticsQueryResult = ApolloReactCommon.QueryResult<IGetOrderByTradeAnalyticsQuery, IGetOrderByTradeAnalyticsQueryVariables>;
export const GetOverviewAnalyticsDocument = gql`
    query GetOverviewAnalytics($start: String, $end: String) {
  algorithmAnalytics(algorithms: [1, 9, 2, 10, 3, 4, 11, 5, 12, 6, 13, 8, 14], start: $start, end: $end) {
    name
    description
    flagCount
    orderCount
    ndcFlagCount
    algtype
  }
  controlledFlaggedAnalytics(start: $start, end: $end) {
    ndc
    productName
    count
  }
  drugFamilyAnalytics(start: $start, end: $end) {
    drugFamilyNumber
    count
  }
  getbuildversion {
    buildversion
    tenantId
  }
  gettabview {
    showdashboardtab
  }
  whatsNewData {
    whatsnewId
    description
    releaseDate
  }
  controlledNDCFlaggedAnalytics(start: $start, end: $end) {
    ndc
    productName
    count
  }
  drugFamilyNDCAnalytics(start: $start, end: $end) {
    drugFamilyNumber
    count
  }
}
    `;

/**
 * __useGetOverviewAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetOverviewAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewAnalyticsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetOverviewAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetOverviewAnalyticsQuery, IGetOverviewAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetOverviewAnalyticsQuery, IGetOverviewAnalyticsQueryVariables>(GetOverviewAnalyticsDocument, baseOptions);
      }
export function useGetOverviewAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetOverviewAnalyticsQuery, IGetOverviewAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetOverviewAnalyticsQuery, IGetOverviewAnalyticsQueryVariables>(GetOverviewAnalyticsDocument, baseOptions);
        }
export type GetOverviewAnalyticsQueryHookResult = ReturnType<typeof useGetOverviewAnalyticsQuery>;
export type GetOverviewAnalyticsLazyQueryHookResult = ReturnType<typeof useGetOverviewAnalyticsLazyQuery>;
export type GetOverviewAnalyticsQueryResult = ApolloReactCommon.QueryResult<IGetOverviewAnalyticsQuery, IGetOverviewAnalyticsQueryVariables>;
export const GetDashboardSettingsDocument = gql`
    query GetDashboardSettings {
  dashboardSettings {
    name
    defaultfiltersettings
  }
  fetchWhatsNewData {
    whatsnewId
    description
    releaseDate
  }
}
    `;

/**
 * __useGetDashboardSettingsQuery__
 *
 * To run a query within a React component, call `useGetDashboardSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetDashboardSettingsQuery, IGetDashboardSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetDashboardSettingsQuery, IGetDashboardSettingsQueryVariables>(GetDashboardSettingsDocument, baseOptions);
      }
export function useGetDashboardSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetDashboardSettingsQuery, IGetDashboardSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetDashboardSettingsQuery, IGetDashboardSettingsQueryVariables>(GetDashboardSettingsDocument, baseOptions);
        }
export type GetDashboardSettingsQueryHookResult = ReturnType<typeof useGetDashboardSettingsQuery>;
export type GetDashboardSettingsLazyQueryHookResult = ReturnType<typeof useGetDashboardSettingsLazyQuery>;
export type GetDashboardSettingsQueryResult = ApolloReactCommon.QueryResult<IGetDashboardSettingsQuery, IGetDashboardSettingsQueryVariables>;
export const GetTenantConfigurationsDocument = gql`
    query GetTenantConfigurations {
  tenantConfigurations {
    orderPie
    ndcPie
    tenantpagelimit
    passphrase
  }
}
    `;

/**
 * __useGetTenantConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetTenantConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantConfigurationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetTenantConfigurationsQuery, IGetTenantConfigurationsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetTenantConfigurationsQuery, IGetTenantConfigurationsQueryVariables>(GetTenantConfigurationsDocument, baseOptions);
      }
export function useGetTenantConfigurationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetTenantConfigurationsQuery, IGetTenantConfigurationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetTenantConfigurationsQuery, IGetTenantConfigurationsQueryVariables>(GetTenantConfigurationsDocument, baseOptions);
        }
export type GetTenantConfigurationsQueryHookResult = ReturnType<typeof useGetTenantConfigurationsQuery>;
export type GetTenantConfigurationsLazyQueryHookResult = ReturnType<typeof useGetTenantConfigurationsLazyQuery>;
export type GetTenantConfigurationsQueryResult = ApolloReactCommon.QueryResult<IGetTenantConfigurationsQuery, IGetTenantConfigurationsQueryVariables>;
export const GetMonthlyCustomerOrderAnalyticsDocument = gql`
    query GetMonthlyCustomerOrderAnalytics($customerId: ID!) {
  monthlyCustomerOrderAnalytics(customerId: $customerId) {
    orderYear
    orderMonth
    count
    totalOrderQuantity
    totalOrderTotal
    totalOrderMe
    averageOrderQuantity
    averageOrderTotal
    averageOrderMe
  }
}
    `;

/**
 * __useGetMonthlyCustomerOrderAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetMonthlyCustomerOrderAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyCustomerOrderAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyCustomerOrderAnalyticsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetMonthlyCustomerOrderAnalyticsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetMonthlyCustomerOrderAnalyticsQuery, IGetMonthlyCustomerOrderAnalyticsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetMonthlyCustomerOrderAnalyticsQuery, IGetMonthlyCustomerOrderAnalyticsQueryVariables>(GetMonthlyCustomerOrderAnalyticsDocument, baseOptions);
      }
export function useGetMonthlyCustomerOrderAnalyticsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetMonthlyCustomerOrderAnalyticsQuery, IGetMonthlyCustomerOrderAnalyticsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetMonthlyCustomerOrderAnalyticsQuery, IGetMonthlyCustomerOrderAnalyticsQueryVariables>(GetMonthlyCustomerOrderAnalyticsDocument, baseOptions);
        }
export type GetMonthlyCustomerOrderAnalyticsQueryHookResult = ReturnType<typeof useGetMonthlyCustomerOrderAnalyticsQuery>;
export type GetMonthlyCustomerOrderAnalyticsLazyQueryHookResult = ReturnType<typeof useGetMonthlyCustomerOrderAnalyticsLazyQuery>;
export type GetMonthlyCustomerOrderAnalyticsQueryResult = ApolloReactCommon.QueryResult<IGetMonthlyCustomerOrderAnalyticsQuery, IGetMonthlyCustomerOrderAnalyticsQueryVariables>;
export const ListCustomerFilesDocument = gql`
    query ListCustomerFiles($customerId: ID!) {
  files(customerId: $customerId) {
    id
    fileName
    type {
      id
      name
    }
    createdAt
  }
  customer(id: $customerId) {
    id
    customerName
  }
}
    `;

/**
 * __useListCustomerFilesQuery__
 *
 * To run a query within a React component, call `useListCustomerFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerFilesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useListCustomerFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListCustomerFilesQuery, IListCustomerFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<IListCustomerFilesQuery, IListCustomerFilesQueryVariables>(ListCustomerFilesDocument, baseOptions);
      }
export function useListCustomerFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListCustomerFilesQuery, IListCustomerFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListCustomerFilesQuery, IListCustomerFilesQueryVariables>(ListCustomerFilesDocument, baseOptions);
        }
export type ListCustomerFilesQueryHookResult = ReturnType<typeof useListCustomerFilesQuery>;
export type ListCustomerFilesLazyQueryHookResult = ReturnType<typeof useListCustomerFilesLazyQuery>;
export type ListCustomerFilesQueryResult = ApolloReactCommon.QueryResult<IListCustomerFilesQuery, IListCustomerFilesQueryVariables>;
export const GetCustomerFileSignedUrlDocument = gql`
    mutation GetCustomerFileSignedUrl($id: ID!) {
  getFileSignedUrl(id: $id)
}
    `;
export type IGetCustomerFileSignedUrlMutationFn = ApolloReactCommon.MutationFunction<IGetCustomerFileSignedUrlMutation, IGetCustomerFileSignedUrlMutationVariables>;

/**
 * __useGetCustomerFileSignedUrlMutation__
 *
 * To run a mutation, you first call `useGetCustomerFileSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFileSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCustomerFileSignedUrlMutation, { data, loading, error }] = useGetCustomerFileSignedUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerFileSignedUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IGetCustomerFileSignedUrlMutation, IGetCustomerFileSignedUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<IGetCustomerFileSignedUrlMutation, IGetCustomerFileSignedUrlMutationVariables>(GetCustomerFileSignedUrlDocument, baseOptions);
      }
export type GetCustomerFileSignedUrlMutationHookResult = ReturnType<typeof useGetCustomerFileSignedUrlMutation>;
export type GetCustomerFileSignedUrlMutationResult = ApolloReactCommon.MutationResult<IGetCustomerFileSignedUrlMutation>;
export type GetCustomerFileSignedUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<IGetCustomerFileSignedUrlMutation, IGetCustomerFileSignedUrlMutationVariables>;
export const ListCustomerOrdersDocument = gql`
    query ListCustomerOrders($id: ID!, $q: String, $sort: [String], $limit: Int, $page: Int, $start: String, $end: String, $filtersettings: String) {
  customer(id: $id) {
    id
    customerName
    orders(q: $q, sort: $sort, limit: $limit, page: $page, start: $start, end: $end, filtersettings: $filtersettings) {
      pagination {
        total
        limit
        page
      }
      data {
        orderId
        customerId
        customerName
        trade
        orderDate
        orderedAt
        importId
        requestId
        orderTotal
        orderQuantity
        orderMe
        flagged
        clearFlag
        ndcflagged
        ndccleared
        ndccleared
        flaggedexist
        suspiciousorder
        issuspiciousorder
        lineItems {
          orderId
          orderUuid
          trade
          orderedAt
          ndc
          lineQuantity
          lineTotal
          me
          deaFamilyNumber
          meCoefficient
          productName
          controlled
          dosageForm
          size
          packageSum
          qtyCase
          packageDescription
          labeler
          rxOtc
        }
      }
    }
  }
}
    `;

/**
 * __useListCustomerOrdersQuery__
 *
 * To run a query within a React component, call `useListCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerOrdersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      q: // value for 'q'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      filtersettings: // value for 'filtersettings'
 *   },
 * });
 */
export function useListCustomerOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListCustomerOrdersQuery, IListCustomerOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<IListCustomerOrdersQuery, IListCustomerOrdersQueryVariables>(ListCustomerOrdersDocument, baseOptions);
      }
export function useListCustomerOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListCustomerOrdersQuery, IListCustomerOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListCustomerOrdersQuery, IListCustomerOrdersQueryVariables>(ListCustomerOrdersDocument, baseOptions);
        }
export type ListCustomerOrdersQueryHookResult = ReturnType<typeof useListCustomerOrdersQuery>;
export type ListCustomerOrdersLazyQueryHookResult = ReturnType<typeof useListCustomerOrdersLazyQuery>;
export type ListCustomerOrdersQueryResult = ApolloReactCommon.QueryResult<IListCustomerOrdersQuery, IListCustomerOrdersQueryVariables>;
export const ListCustomersDocument = gql`
    query ListCustomers($q: String, $sort: [String], $limit: Int, $page: Int, $start: String, $end: String) {
  customers(q: $q, sort: $sort, limit: $limit, page: $page, start: $start, end: $end) {
    pagination {
      total
      limit
      page
    }
    data {
      id
      customerId
      customerName
      lastOrderedAt
    }
  }
}
    `;

/**
 * __useListCustomersQuery__
 *
 * To run a query within a React component, call `useListCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomersQuery({
 *   variables: {
 *      q: // value for 'q'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useListCustomersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListCustomersQuery, IListCustomersQueryVariables>) {
        return ApolloReactHooks.useQuery<IListCustomersQuery, IListCustomersQueryVariables>(ListCustomersDocument, baseOptions);
      }
export function useListCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListCustomersQuery, IListCustomersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListCustomersQuery, IListCustomersQueryVariables>(ListCustomersDocument, baseOptions);
        }
export type ListCustomersQueryHookResult = ReturnType<typeof useListCustomersQuery>;
export type ListCustomersLazyQueryHookResult = ReturnType<typeof useListCustomersLazyQuery>;
export type ListCustomersQueryResult = ApolloReactCommon.QueryResult<IListCustomersQuery, IListCustomersQueryVariables>;
export const ListOrdersDocument = gql`
    query ListOrders($q: String, $sort: [String], $limit: Int, $page: Int, $start: String, $end: String, $algorithm: String, $filtersettings: String) {
  orders(q: $q, sort: $sort, limit: $limit, page: $page, start: $start, end: $end, algorithm: $algorithm, filtersettings: $filtersettings) {
    pagination {
      total
      limit
      page
    }
    data {
      orderId
      orderUuid
      customerId
      customerName
      trade
      orderDate
      orderedAt
      importId
      requestId
      orderTotal
      orderQuantity
      orderMe
      flagged
      clearFlag
      ndcflagged
      ndccleared
      flaggedexist
      suspiciousorder
      issuspiciousorder
      lineItems {
        orderId
        orderUuid
        trade
        orderedAt
        ndc
        lineQuantity
        lineTotal
        me
        deaFamilyNumber
        meCoefficient
        productName
        controlled
        dosageForm
        size
        packageSum
        qtyCase
        packageDescription
        labeler
        rxOtc
      }
    }
  }
}
    `;

/**
 * __useListOrdersQuery__
 *
 * To run a query within a React component, call `useListOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrdersQuery({
 *   variables: {
 *      q: // value for 'q'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      algorithm: // value for 'algorithm'
 *      filtersettings: // value for 'filtersettings'
 *   },
 * });
 */
export function useListOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListOrdersQuery, IListOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<IListOrdersQuery, IListOrdersQueryVariables>(ListOrdersDocument, baseOptions);
      }
export function useListOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListOrdersQuery, IListOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListOrdersQuery, IListOrdersQueryVariables>(ListOrdersDocument, baseOptions);
        }
export type ListOrdersQueryHookResult = ReturnType<typeof useListOrdersQuery>;
export type ListOrdersLazyQueryHookResult = ReturnType<typeof useListOrdersLazyQuery>;
export type ListOrdersQueryResult = ApolloReactCommon.QueryResult<IListOrdersQuery, IListOrdersQueryVariables>;
export const GetNoteAttachmentDocument = gql`
    query GetNoteAttachment($noteId: ID!) {
  noteAttachment(noteId: $noteId)
}
    `;

/**
 * __useGetNoteAttachmentQuery__
 *
 * To run a query within a React component, call `useGetNoteAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteAttachmentQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useGetNoteAttachmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetNoteAttachmentQuery, IGetNoteAttachmentQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetNoteAttachmentQuery, IGetNoteAttachmentQueryVariables>(GetNoteAttachmentDocument, baseOptions);
      }
export function useGetNoteAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetNoteAttachmentQuery, IGetNoteAttachmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetNoteAttachmentQuery, IGetNoteAttachmentQueryVariables>(GetNoteAttachmentDocument, baseOptions);
        }
export type GetNoteAttachmentQueryHookResult = ReturnType<typeof useGetNoteAttachmentQuery>;
export type GetNoteAttachmentLazyQueryHookResult = ReturnType<typeof useGetNoteAttachmentLazyQuery>;
export type GetNoteAttachmentQueryResult = ApolloReactCommon.QueryResult<IGetNoteAttachmentQuery, IGetNoteAttachmentQueryVariables>;
export const GetHelpAttachmentDocument = gql`
    query GetHelpAttachment {
  helpAttachment
}
    `;

/**
 * __useGetHelpAttachmentQuery__
 *
 * To run a query within a React component, call `useGetHelpAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpAttachmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHelpAttachmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetHelpAttachmentQuery, IGetHelpAttachmentQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetHelpAttachmentQuery, IGetHelpAttachmentQueryVariables>(GetHelpAttachmentDocument, baseOptions);
      }
export function useGetHelpAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetHelpAttachmentQuery, IGetHelpAttachmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetHelpAttachmentQuery, IGetHelpAttachmentQueryVariables>(GetHelpAttachmentDocument, baseOptions);
        }
export type GetHelpAttachmentQueryHookResult = ReturnType<typeof useGetHelpAttachmentQuery>;
export type GetHelpAttachmentLazyQueryHookResult = ReturnType<typeof useGetHelpAttachmentLazyQuery>;
export type GetHelpAttachmentQueryResult = ApolloReactCommon.QueryResult<IGetHelpAttachmentQuery, IGetHelpAttachmentQueryVariables>;
export const GetOrdersReportsDocument = gql`
    query GetOrdersReports {
  algorithmexport(algorithms: [1, 9, 2, 10, 3, 4, 11, 5, 12, 6, 13, 8]) {
    name
    description
  }
  algorithmdisableexport(algorithms: [1, 9, 2, 10, 3, 4, 11, 5, 12, 6, 13, 8]) {
    name
    description
  }
}
    `;

/**
 * __useGetOrdersReportsQuery__
 *
 * To run a query within a React component, call `useGetOrdersReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetOrdersReportsQuery, IGetOrdersReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetOrdersReportsQuery, IGetOrdersReportsQueryVariables>(GetOrdersReportsDocument, baseOptions);
      }
export function useGetOrdersReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetOrdersReportsQuery, IGetOrdersReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetOrdersReportsQuery, IGetOrdersReportsQueryVariables>(GetOrdersReportsDocument, baseOptions);
        }
export type GetOrdersReportsQueryHookResult = ReturnType<typeof useGetOrdersReportsQuery>;
export type GetOrdersReportsLazyQueryHookResult = ReturnType<typeof useGetOrdersReportsLazyQuery>;
export type GetOrdersReportsQueryResult = ApolloReactCommon.QueryResult<IGetOrdersReportsQuery, IGetOrdersReportsQueryVariables>;
export const GetSettingsDocument = gql`
    query GetSettings {
  oAuth2Client {
    clientId
    clientSecret
  }
  webhooks {
    webhookId
    url
    secret
    active
    createdAt
    updatedAt
  }
  filterSettings {
    defaultfiltersettings
  }
  userSettings {
    notificationsEnabled
    role
    notificationsclearedEnabled
  }
  tenantFrequencyConfigurations {
    timefrequency
  }
}
    `;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetSettingsQuery, IGetSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetSettingsQuery, IGetSettingsQueryVariables>(GetSettingsDocument, baseOptions);
      }
export function useGetSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetSettingsQuery, IGetSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetSettingsQuery, IGetSettingsQueryVariables>(GetSettingsDocument, baseOptions);
        }
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsQueryResult = ApolloReactCommon.QueryResult<IGetSettingsQuery, IGetSettingsQueryVariables>;
export const UpdateSettingsDocument = gql`
    mutation UpdateSettings($input: UpdateSettingsInput!) {
  updateSettings(input: $input) {
    message
  }
}
    `;
export type IUpdateSettingsMutationFn = ApolloReactCommon.MutationFunction<IUpdateSettingsMutation, IUpdateSettingsMutationVariables>;

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateSettingsMutation, IUpdateSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateSettingsMutation, IUpdateSettingsMutationVariables>(UpdateSettingsDocument, baseOptions);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = ApolloReactCommon.MutationResult<IUpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateSettingsMutation, IUpdateSettingsMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($input: updateUserSettingsInput!) {
  updateUserSettings(input: $input) {
    message
  }
}
    `;
export type IUpdateUserSettingsMutationFn = ApolloReactCommon.MutationFunction<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, baseOptions);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = ApolloReactCommon.MutationResult<IUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateUserSettingsMutation, IUpdateUserSettingsMutationVariables>;
export const UpdateUserClearedSettingsDocument = gql`
    mutation UpdateUserClearedSettings($input: updateUserClearedSettingsInput!) {
  updateUserClearedSettings(input: $input) {
    message
  }
}
    `;
export type IUpdateUserClearedSettingsMutationFn = ApolloReactCommon.MutationFunction<IUpdateUserClearedSettingsMutation, IUpdateUserClearedSettingsMutationVariables>;

/**
 * __useUpdateUserClearedSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserClearedSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserClearedSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserClearedSettingsMutation, { data, loading, error }] = useUpdateUserClearedSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserClearedSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateUserClearedSettingsMutation, IUpdateUserClearedSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateUserClearedSettingsMutation, IUpdateUserClearedSettingsMutationVariables>(UpdateUserClearedSettingsDocument, baseOptions);
      }
export type UpdateUserClearedSettingsMutationHookResult = ReturnType<typeof useUpdateUserClearedSettingsMutation>;
export type UpdateUserClearedSettingsMutationResult = ApolloReactCommon.MutationResult<IUpdateUserClearedSettingsMutation>;
export type UpdateUserClearedSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateUserClearedSettingsMutation, IUpdateUserClearedSettingsMutationVariables>;
export const UpdatePageSettingsDocument = gql`
    mutation updatePageSettings($input: updatePageSettingsInput!) {
  updatePageSettings(input: $input) {
    message
  }
}
    `;
export type IUpdatePageSettingsMutationFn = ApolloReactCommon.MutationFunction<IUpdatePageSettingsMutation, IUpdatePageSettingsMutationVariables>;

/**
 * __useUpdatePageSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePageSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageSettingsMutation, { data, loading, error }] = useUpdatePageSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePageSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdatePageSettingsMutation, IUpdatePageSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdatePageSettingsMutation, IUpdatePageSettingsMutationVariables>(UpdatePageSettingsDocument, baseOptions);
      }
export type UpdatePageSettingsMutationHookResult = ReturnType<typeof useUpdatePageSettingsMutation>;
export type UpdatePageSettingsMutationResult = ApolloReactCommon.MutationResult<IUpdatePageSettingsMutation>;
export type UpdatePageSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdatePageSettingsMutation, IUpdatePageSettingsMutationVariables>;
export const UpdatePassphraseSettingsDocument = gql`
    mutation updatePassphraseSettings($input: updatePassphraseSettingsInput!) {
  updatePassphraseSettings(input: $input) {
    message
  }
}
    `;
export type IUpdatePassphraseSettingsMutationFn = ApolloReactCommon.MutationFunction<IUpdatePassphraseSettingsMutation, IUpdatePassphraseSettingsMutationVariables>;

/**
 * __useUpdatePassphraseSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePassphraseSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassphraseSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassphraseSettingsMutation, { data, loading, error }] = useUpdatePassphraseSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePassphraseSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdatePassphraseSettingsMutation, IUpdatePassphraseSettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdatePassphraseSettingsMutation, IUpdatePassphraseSettingsMutationVariables>(UpdatePassphraseSettingsDocument, baseOptions);
      }
export type UpdatePassphraseSettingsMutationHookResult = ReturnType<typeof useUpdatePassphraseSettingsMutation>;
export type UpdatePassphraseSettingsMutationResult = ApolloReactCommon.MutationResult<IUpdatePassphraseSettingsMutation>;
export type UpdatePassphraseSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdatePassphraseSettingsMutation, IUpdatePassphraseSettingsMutationVariables>;
export const DeleteWebhookDocument = gql`
    mutation DeleteWebhook($input: DeleteWebhookInput!) {
  deleteWebhook(input: $input)
}
    `;
export type IDeleteWebhookMutationFn = ApolloReactCommon.MutationFunction<IDeleteWebhookMutation, IDeleteWebhookMutationVariables>;

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWebhookMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IDeleteWebhookMutation, IDeleteWebhookMutationVariables>) {
        return ApolloReactHooks.useMutation<IDeleteWebhookMutation, IDeleteWebhookMutationVariables>(DeleteWebhookDocument, baseOptions);
      }
export type DeleteWebhookMutationHookResult = ReturnType<typeof useDeleteWebhookMutation>;
export type DeleteWebhookMutationResult = ApolloReactCommon.MutationResult<IDeleteWebhookMutation>;
export type DeleteWebhookMutationOptions = ApolloReactCommon.BaseMutationOptions<IDeleteWebhookMutation, IDeleteWebhookMutationVariables>;
export const CreateOAuth2ClientDocument = gql`
    mutation CreateOAuth2Client {
  createOAuth2Client {
    clientId
    clientSecret
  }
}
    `;
export type ICreateOAuth2ClientMutationFn = ApolloReactCommon.MutationFunction<ICreateOAuth2ClientMutation, ICreateOAuth2ClientMutationVariables>;

/**
 * __useCreateOAuth2ClientMutation__
 *
 * To run a mutation, you first call `useCreateOAuth2ClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOAuth2ClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOAuth2ClientMutation, { data, loading, error }] = useCreateOAuth2ClientMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateOAuth2ClientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateOAuth2ClientMutation, ICreateOAuth2ClientMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateOAuth2ClientMutation, ICreateOAuth2ClientMutationVariables>(CreateOAuth2ClientDocument, baseOptions);
      }
export type CreateOAuth2ClientMutationHookResult = ReturnType<typeof useCreateOAuth2ClientMutation>;
export type CreateOAuth2ClientMutationResult = ApolloReactCommon.MutationResult<ICreateOAuth2ClientMutation>;
export type CreateOAuth2ClientMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateOAuth2ClientMutation, ICreateOAuth2ClientMutationVariables>;
export const UpdateWebhookDocument = gql`
    mutation UpdateWebhook($input: UpdateWebhookInput!) {
  updateWebhook(input: $input) {
    webhookId
    url
    secret
    active
    createdAt
    updatedAt
  }
}
    `;
export type IUpdateWebhookMutationFn = ApolloReactCommon.MutationFunction<IUpdateWebhookMutation, IUpdateWebhookMutationVariables>;

/**
 * __useUpdateWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookMutation, { data, loading, error }] = useUpdateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateWebhookMutation, IUpdateWebhookMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateWebhookMutation, IUpdateWebhookMutationVariables>(UpdateWebhookDocument, baseOptions);
      }
export type UpdateWebhookMutationHookResult = ReturnType<typeof useUpdateWebhookMutation>;
export type UpdateWebhookMutationResult = ApolloReactCommon.MutationResult<IUpdateWebhookMutation>;
export type UpdateWebhookMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateWebhookMutation, IUpdateWebhookMutationVariables>;
export const ListRequestsDocument = gql`
    query ListRequests($limit: Int, $page: Int) {
  requests(limit: $limit, page: $page) {
    pagination {
      total
      limit
      page
    }
    data {
      requestId
      userId
      type
      attachment
      user {
        name
      }
      statuses {
        requestId
        status
        type
        message
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useListRequestsQuery__
 *
 * To run a query within a React component, call `useListRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListRequestsQuery, IListRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<IListRequestsQuery, IListRequestsQueryVariables>(ListRequestsDocument, baseOptions);
      }
export function useListRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListRequestsQuery, IListRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListRequestsQuery, IListRequestsQueryVariables>(ListRequestsDocument, baseOptions);
        }
export type ListRequestsQueryHookResult = ReturnType<typeof useListRequestsQuery>;
export type ListRequestsLazyQueryHookResult = ReturnType<typeof useListRequestsLazyQuery>;
export type ListRequestsQueryResult = ApolloReactCommon.QueryResult<IListRequestsQuery, IListRequestsQueryVariables>;
export const GetRequestAttachmentDocument = gql`
    query GetRequestAttachment($requestId: ID!) {
  requestAttachment(requestId: $requestId)
}
    `;

/**
 * __useGetRequestAttachmentQuery__
 *
 * To run a query within a React component, call `useGetRequestAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestAttachmentQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetRequestAttachmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetRequestAttachmentQuery, IGetRequestAttachmentQueryVariables>) {
        return ApolloReactHooks.useQuery<IGetRequestAttachmentQuery, IGetRequestAttachmentQueryVariables>(GetRequestAttachmentDocument, baseOptions);
      }
export function useGetRequestAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetRequestAttachmentQuery, IGetRequestAttachmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IGetRequestAttachmentQuery, IGetRequestAttachmentQueryVariables>(GetRequestAttachmentDocument, baseOptions);
        }
export type GetRequestAttachmentQueryHookResult = ReturnType<typeof useGetRequestAttachmentQuery>;
export type GetRequestAttachmentLazyQueryHookResult = ReturnType<typeof useGetRequestAttachmentLazyQuery>;
export type GetRequestAttachmentQueryResult = ApolloReactCommon.QueryResult<IGetRequestAttachmentQuery, IGetRequestAttachmentQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers {
  users {
    userId
    name
    email
    title
    role
    enabled
    notificationsEnabled
    createdAt
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListUsersQuery, IListUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<IListUsersQuery, IListUsersQueryVariables>(ListUsersDocument, baseOptions);
      }
export function useListUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListUsersQuery, IListUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListUsersQuery, IListUsersQueryVariables>(ListUsersDocument, baseOptions);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = ApolloReactCommon.QueryResult<IListUsersQuery, IListUsersQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    userId
    name
    email
    title
    role
    enabled
    notificationsEnabled
    createdAt
  }
}
    `;
export type IUpdateUserMutationFn = ApolloReactCommon.MutationFunction<IUpdateUserMutation, IUpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateUserMutation, IUpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateUserMutation, IUpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<IUpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateUserMutation, IUpdateUserMutationVariables>;