import * as React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Field } from 'formik';
import { getStyles, renderRowCell } from './NotesDrawerContent.style';


const CommonCommentBox = (props: any) => {
    const classes = getStyles();

   
    return (
  
       
        <div> 
          <Field type="text" name="commonmessage" id="commonmessage" placeholder="Note" className={classes.commonmsgcls} />
          
          <div className={classes.errorclsmsg} id="commonmessageerror"></div>
        </div>
       
        
    
    )
}

export default CommonCommentBox;