import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  Typography
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import * as L from 'partial.lenses';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import {
  IFileType,
  ListCustomerFilesDocument,
  useListFileTypesQuery,
  useUploadFileMutation
} from '../../generated/graphql';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import SwitchLoadingErrorChildren from '../SwitchLoadingErrorChildren';

interface Values {
  files: FileList;
  typeId: string;
}

interface Props {
  open?: boolean;
  customerId: string;

  onRequestClose(): void;
}

function UploadFileModal(props: Props) {
  const { open = false, customerId, onRequestClose } = props;
  const { handleSubmit, register, formState, errors, watch, setValue } = useForm<Values>({ defaultValues: { files: [] } });
  const [uploadFile, { error }] = useUploadFileMutation();
  const listFileTypesQuery = useListFileTypesQuery();

  const handleUpload = async (values: Values) => {
    try {
      await uploadFile({
        variables: {
          input: {
            file: values.files[0],
            customerId,
            typeId: values.typeId,
          },
        },
        update: (store, result) => {
          const data = store.readQuery({
            query: ListCustomerFilesDocument,
            variables: {
              customerId
            },
          });

          store.writeQuery({
            query: ListCustomerFilesDocument,
            variables: {
              customerId
            },
            data: L.set(['files', L.appendTo], result.data?.uploadFile, data),
          });

          onRequestClose?.();
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const files = watch('files');

  const fileTypes = listFileTypesQuery.data?.fileTypes ?? [];

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
    >
      <form onSubmit={handleSubmit(handleUpload)}>
        <DialogTitle>Upload File</DialogTitle>

        <DialogContent>
          <SwitchLoadingErrorChildren
            loading={listFileTypesQuery.loading}
            error={listFileTypesQuery.error}
          >
            <DialogContentText>
              <Typography>
                Please select a file to upload:
              </Typography>

              <RHFInput
                as={
                  <Select native>
                    <option value="" />
                    {
                      fileTypes.map((fileType: IFileType) => <option value={fileType.id}>{fileType.name}</option>)
                    }
                  </Select>
                }
                rules={{ required: true }}
                name="typeId"
                register={register}
                setValue={setValue}
              />
            </DialogContentText>

            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
            >
              Select File
              <input
                name="files"
                type="file"
                ref={register({ required: true })}
                hidden
              />
            </Button>

            <Typography variant="subtitle1">
              {
                files[0]?.name
              }
            </Typography>

            {
              errors.files && <ErrorMessage error="Required" />
            }

            <ErrorMessage error={error} />
          </SwitchLoadingErrorChildren>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => onRequestClose()}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
            type="submit"
          >
            Upload
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UploadFileModal;
