import { IconButton, Menu } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import * as React from 'react';
import { useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { IDateRangePickerSelectionRange } from '../../types';

interface Props {
  selectionRange: IDateRangePickerSelectionRange;
  forwardedRef?: any;

  onClose?(selectionRange: IDateRangePickerSelectionRange): void;
}

function DateRangeFilter(props: Props) {
  const { selectionRange, onClose, forwardedRef } = props;
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState(selectionRange);
  const ref = useRef(null);

  return (
    <section ref={forwardedRef}>
      <IconButton
        ref={ref}
        onClick={() => setOpen(true)}
      >
        <DateRangeIcon />
      </IconButton>

      <Menu
        anchorEl={ref.current}
        open={open}
        onClose={() => {
          onClose?.(dates);
          setOpen(false);
        }}
      >
        <DateRangePicker
          ranges={[{ ...dates, key: 'selection' }]}
          months={2}
          direction="horizontal"
          onChange={({ selection }: { selection: IDateRangePickerSelectionRange }) => {
            setDates(selection);
          }}
        />
      </Menu>
    </section>
  );
}

export default React.forwardRef<Props, any>((props, ref) => <DateRangeFilter {...props} forwardedRef={ref} />);
