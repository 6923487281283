import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router";

interface Props extends RouteProps {
  isAuthenticated?: boolean
  redirectTo: string
}

function PublicRoute({ isAuthenticated, redirectTo, component: Component, render, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          return <Redirect to={redirectTo} />;
        }

        if (Component) {
          return <Component {...props} />;
        }

        if (render) {
          return render(props);
        }

        return null;
      }}
    />
  );
}

export default PublicRoute;
