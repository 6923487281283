import { Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import * as React from 'react';
import { useEffect } from 'react';
import { useGetNoteAttachmentLazyQuery } from '../../../generated/graphql';

interface Props {
  noteId: string
}

const ViewAttachmentButton = (props: Props) => {
  const { noteId } = props;
  const [getNoteAttachment, { loading, error, data }] = useGetNoteAttachmentLazyQuery();

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (data) {
      window.open(data.noteAttachment, '_blank', 'rel="noopener noreferrer"');
    }
  }, [data]);

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={() => getNoteAttachment({ variables: { noteId } })}
      disabled={loading}
      startIcon={<OpenInNewIcon />}
    >
      View Attachment
    </Button>
  );
};

export default ViewAttachmentButton;
