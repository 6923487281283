import { SvgIcon } from "@material-ui/core";
import * as React from 'react';

const ClearFlag = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 20 21">
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h24v24H-2z" />
      <path
        fill="#757575"
        fillRule="nonzero"
        d="M13.06 14L3 3.94V2.767L14.232 14h-1.171zm-4.828-2H5v7H3V6.768L8.232 12zm8.829 2l-12-12H12l.4 2H18v10h-.94z"
      />
      <path
        fill="#757575"
        d="M1.361.3L-.053 1.715l18.385 18.384 1.414-1.414z"
      />
    </g>
  </SvgIcon>
);

export default ClearFlag;
