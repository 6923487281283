import * as React from 'react';
import { IOrderLineItem, Maybe, useGetNotesDrawerContentQuery } from '../../generated/graphql';
import OrderItemsTableModal from './OrderItemsTableModal';

export interface IOrderItemsTableModalContext {
  open(items: Array<IOrderLineItem>,orderId: String,requestId: String,customerId: String,customerName: String,trade: String,orderedAt: String): void;
}

export const OrderItemsTableModalContext = React.createContext<Maybe<IOrderItemsTableModalContext>>(null);

interface Props {
  children: any;
}

export const OrderItemsTableModalProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const [items, setItems] = React.useState<Array<IOrderLineItem>>([]);
  const [orderId, setId] = React.useState<String>('');
  const [requestId, setrequestIdId] = React.useState<string>('');
  const [customerId, setcustomerId] = React.useState<string>('');
  const [customerName, setcustomerName] = React.useState<string>('');
  const [trade, settrade] = React.useState<string>('');
  const [orderedAt, setorderedAt] = React.useState<string>('');
  
  const id:string = String(orderId);
  const { data } = useGetNotesDrawerContentQuery({ variables: { orderId: id ,requestId:requestId,customerId:customerId,customerName:customerName,trade:trade,orderedAt:orderedAt} });
  
  const doubled = data?.order?.flagsndc?.map((ds) => ds!) ?? [];
  
  let algorithms = '';
  Object.keys(items).map(function(key:any, index) {
  //myObject[key] *= 2;
  algorithms = '';
  Object.keys(doubled).map(function(xkey:any, xindex) {
  	if( doubled[xkey]['ndc'] === items[key]['ndc'] ){
        algorithms = algorithms.concat("</br> "+ doubled[xkey]['algorithm']);
        algorithms = algorithms.replace(/^,/, '');
        
        //console.log(algorithms);
        items[key]['algorithm'] = algorithms;
    }
    
    
  });
});

  return (
    <OrderItemsTableModalContext.Provider
      value={{
        open: (items: Array<IOrderLineItem>,orderId: String,requestId:string) => {
          setId(orderId);
          setrequestIdId(requestId);
          setItems(items);
          setOpen(true);
        },
      }}
    >
      {children}

      <OrderItemsTableModal
        open={open}
        orderItems={items}
        orderId={id}
        requestId={requestId}
        onRequestClose={() => setOpen(false)}
      />
    </OrderItemsTableModalContext.Provider>
  );
};

export const useOrderItemsTableModal = () => {
  const context = React.useContext(OrderItemsTableModalContext);

  if (!context) {
    throw new Error('OrderItemsTableModalContext missing');
  }

  return context;
};
