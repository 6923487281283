import DeleteIcon from '@material-ui/icons/Delete';
import * as L from 'partial.lenses';
import * as R from 'ramda';
import * as React from 'react';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import IconButton from '../../../components/IconButton';
import { GetSettingsDocument, useDeleteWebhookMutation } from '../../../generated/graphql';

interface Props {
  id: string;
  forwardedRef?: any;

  onDeleted?(id: string): void
}

const DeleteWebhookButton = (props: Props) => {
  const { id, onDeleted, forwardedRef } = props;
  const [deleteWebhook, { loading, error }] = useDeleteWebhookMutation();

  const handleOnClick = async () => {
    try {
      await deleteWebhook({
        variables: { input: { webhookId: id } },
        update: (store) => {
          const data = store.readQuery({
            query: GetSettingsDocument,
          });

          store.writeQuery({
            query: GetSettingsDocument,
            data: L.remove(['webhooks', L.find(R.whereEq({ webhookId: id }))], data),
          });
        }
      });
      if (onDeleted) {
        onDeleted(id);
      }
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div ref={forwardedRef}>
      <IconButton
        onClick={handleOnClick}
        loading={loading}
        disabled={loading}
      >
        <DeleteIcon />
      </IconButton>

      <ErrorMessage error={error} />
    </div>
  );
};

export default React.forwardRef<Props, any>((props, ref) => <DeleteWebhookButton {...props} forwardedRef={ref} />);
